import Vue from 'vue'
import Rollbar from 'rollbar'

const createRollbarInstance = function (accessToken) {
  const config = {"enabled":true,"captureUncaught":true,"captureUnhandledRejections":true,"scrubFields":["password","new_password","current_password","Authorization"],"hostBlockList":["gstatic.com"],"ignoredMessages":[new RegExp("ResizeObserver", ""),new RegExp("\\.intercom", "")],"payload":{"environment":"production","server":{"root":"webpack:\u002F\u002F\u002F.\u002F"},"client":{"javascript":{"source_map_enabled":true,"code_version":"3e6903fec3faad2439923553198971453284afcf"}}}}
  config.accessToken = accessToken
  return new Rollbar(config)
}

const serverRollbar = createRollbarInstance('0ff8737c7d7848f4b31e6bb49dbc4837')

const clientRollbar = createRollbarInstance('ca47cded41474069bf5b9c2d90ed335a')

const noop = function () {}
const stubRollbar = Object.create(null)
Object.defineProperties(
  stubRollbar,
  Object.getOwnPropertyNames(Rollbar.prototype).reduce(function (acc, key) {
    acc[key] = { value: noop }
    return acc
  }, {}))

const RollbarAdapter = Object.create(null)
Object.defineProperty(RollbarAdapter, 'instance', {
  get() {
    if (process.server) {
      return serverRollbar
    }

    if (process.client) {
      return clientRollbar
    }

    return stubRollbar
  },
})

export default function (ctx, inject) {
  // Inject Rollbar to the context as $rollbar
  ctx.$rollbar = RollbarAdapter.instance
  inject('rollbar', RollbarAdapter.instance)
  Vue.rollbar = RollbarAdapter.instance
}
