<template>
  <div>
    <v-dialog
      v-for="(dialog, index) in stack"
      :key="index"
      :value="true"
      :max-width="dialog.maxWidth || 800"
      :persistent="Boolean(dialog.persistent)"
      :fullscreen="Boolean(dialog.fullscreen)"
      :content-class="dialog.fullHeight ? 'the-dialog--full-height' : ''"
      :scrollable="dialog.scrollable !== false"
      @input="$event ? undefined : closeDialog()"
    >
      <component
        :is="dialog.component"
        v-test-id="`dialog-component-${index}`"
        v-bind="dialog.attrs || {}"
        v-on="dialog.on || {}"
      />
    </v-dialog>
  </div>
</template>

<script>
import { kebabCase } from 'lodash';
import { mapMutations, mapState } from 'vuex';
import DialogLoading from '../dialog-loading';

const asyncComponent = (name) => {
  const componentFolderName = kebabCase(name);

  return {
    // Keep extension to avoid build test files
    component: import(`~/components/dialogs/${componentFolderName}/index.vue`),
    loading: DialogLoading,
  };
};

const generateAsyncComponents = names => names.reduce((accumulator, name) => {
  accumulator[name] = () => asyncComponent(name);
  return accumulator;
}, {});

export const availableDialogs = [
  'DialogAddRowInViewWithFiltersWarning',
  'DialogAppInstallWizard',
  'DialogConfirmAction',
  'DialogConfirmPopupIos',
  'DialogCreateLinkFieldInfoComponent',
  'DialogCreateOrEditCategory',
  'DialogCreateOrEditField',
  'DialogCreateOrEditGlobalParameter',
  'DialogCreateOrEditInfoComponent',
  'DialogCreateOrEditToken',
  'DialogCreateSymmetricLink',
  'DialogCreateSyncFormSubmitWorkflow',
  'DialogDependenciesList',
  'DialogEditCategories',
  'DialogEditChecklistItems',
  'DialogEditMarkdownContent',
  'DialogEditSelectedField',
  'DialogEditViewFields',
  'DialogEditViewFilters',
  'DialogEditViewPermissions',
  'DialogEditViewRecordTemplate',
  'DialogEditViewShareOptions',
  'DialogEditViewSortBy',
  'DialogExpandHistory',
  'DialogFeedback',
  'DialogIntegrationMatches',
  'DialogManageViewWorkflows',
  'DialogNewGroup',
  'DialogNewSheet',
  'DialogNewView',
  'DialogNewWorkflow',
  'DialogNextStepsNewView',
  'DialogRecord',
  'DialogRename',
  'DialogRuntimeOverride',
  'DialogSchemaItemTextInput',
  'DialogSelectField',
  'DialogUploadFile',
  'DialogUserManagement',
  'DialogWorkflowResource',
];

export default {
  name: 'TheDialog',
  components: {
    DialogLoading,
    ...generateAsyncComponents(availableDialogs),
  },
  computed: {
    ...mapState('dialog', ['stack']),
  },
  methods: {
    ...mapMutations('dialog', ['closeDialog']),
  },
};
</script>

<style lang="scss">
.the-dialog--full-height {
  min-height: 90%;
}
</style>
