
export class Module {
  constructor(nuxt) {
    this.localePath = nuxt.app.localePath;
    this.router = nuxt.app.router;
  }

  onRedirect(to, from) {
    const fromRoute = this.router.match(from);
    window.localStorage.setItem('redirectTo', from);
    let redirectDestination = to;

    if (redirectDestination === '/login') {
      const { tenantSlug } = fromRoute.params;
      redirectDestination = tenantSlug ? `/t/${tenantSlug}/login` : 'login';
    }

    return this.localePath({
      path: redirectDestination,
      query: { redirectTo: from },
    });
  }
}

export const OnRedirectSetup = (nuxt) => {
  const module = new Module(nuxt);

  nuxt.$auth.onRedirect((...args) => module.onRedirect(...args));
};
