<i18n lang="yaml">
pt:
  recent: 'Aberto recentemente'
  noRecent: 'Nenhum item aberto recentemente'
en:
  recent: 'Recently opened'
  noRecent: 'No recent items'
</i18n>

<template>
  <div>
    <v-subheader class="search-recent__subheader">
      {{ $t('recent') }}
    </v-subheader>
    <SearchableItemsList
      v-for="(items, type) in recentItems"
      :key="type"
      :items="items"
      :truncate-at="3"
      @item-clicked="$emit('item-clicked')"
    />
    <v-list v-if="emptyRecent" dense>
      <v-subheader>
        {{ $t('noRecent') }}
      </v-subheader>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getRecentNavigation, SearchableItemBuilder, ViewItemBuilder, SheetItemBuilder, WorkflowItemBuilder } from '~/assets/javascript/utils';
import SearchableItemsList from '../_searchable-items-list';

const RECENT_ITEM_TYPES = Object.freeze(['views', 'workflows', 'sheets']);

export default {
  name: 'TheTopbarSearchRecent',
  components: {
    SearchableItemsList,
  },
  computed: {
    ...mapState('environment', ['currentEnvironment']),
    ...mapState('builderView', { builderViews: 'views' }),
    ...mapState('viewsMenu', { viewsMenu: 'views' }),
    ...mapState('viewOptions', { sheets: 'sheetsCache' }),
    ...mapState('workflows', ['workflows']),
    searchableResourcesBuilders() {
      const builderParams = {
        localeRoute: this.localeRoute,
        $route: this.$route,
        currentEnvironment: this.currentEnvironment,
        $i18n: this.$i18n,
      };
      return {
        view: new ViewItemBuilder(builderParams),
        sheet: new SheetItemBuilder(builderParams),
        workflow: new WorkflowItemBuilder(builderParams),
      };
    },
    searchableItemBuilder() {
      return Object.keys(this.searchableResourcesBuilders).reduce((acc, key) => {
        acc[key] = new SearchableItemBuilder(this.searchableResourcesBuilders[key]);
        return acc;
      }, {});
    },
    views() {
      return this.currentEnvironment === 'studio' ? this.builderViews : this.viewsMenu;
    },
    emptyRecent() {
      return this._.isEmpty(this.recentNavigation) || Object.values(this.recentNavigation).every(this._.isEmpty);
    },
    recentNavigation() {
      const recentNavigation = {};

      RECENT_ITEM_TYPES.forEach((key) => {
        recentNavigation[key] = getRecentNavigation(this.currentEnvironment, key) || [];
      });

      return recentNavigation;
    },
    recentItems() {
      return RECENT_ITEM_TYPES.reduce((accumulator, key) => {
        accumulator[key] = this.searchableItemBuilder[key.replace(/s$/, '')].buildItems(this.getItems(key));

        return accumulator;
      }, {});
    },
  },
  methods: {
    getItems(type) {
      return this[type].filter(({ id }) => this.recentNavigation[type].some(route => [route.params.viewId, route.params.id].includes(id)));
    },
  },
};
</script>

<style lang="scss" scoped>
.search-recent__subheader {
  &.v-subheader {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    height: 20px;
    margin-left: 8px;
    margin-top: 8px;
    padding: 0;
  }
}
</style>
