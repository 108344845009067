import { createDeconstructedPromise } from '~/assets/javascript/utils/promise';
import generateUUID from './generate-uuid';
import AsyncDataManagerSaveState from './save-state';
import AsyncDataManagerLoadState from './load-state';

const DEFAULT_DEBOUNCE_TIME = 1000;

export default class AsyncDataManager {
  #saveStateMapping = {};

  // Used to listen when the flow is finished completely
  // not just by each call of save
  // since debounce can "kill" some call and it will not be resolved
  #savePromiseMapping = {};

  #loadStateMapping = {};

  #loadStateLastSetupIdMapping = {};

  // This is used to listen when the flow is finished completely
  // not just by each call of load
  // since the load can be called multiple times and the last one is the one that matters
  #loadPromiseMapping = {};

  #loadSaveMapping = {};

  #clearState(loadId) {
    this.#loadSaveMapping[loadId]?.forEach((saveId) => {
      delete this.#saveStateMapping[saveId];
      delete this.#savePromiseMapping[saveId];
    });

    delete this.#loadSaveMapping[loadId];
    delete this.#loadStateMapping[loadId];
    delete this.#loadStateLastSetupIdMapping[loadId];
    delete this.#loadPromiseMapping[loadId];
  }

  hasPendingSave(loadId) {
    if (!this.#loadSaveMapping[loadId]) return false;

    return this.#loadSaveMapping[loadId].some(saveId => this.#saveStateMapping[saveId].hasPendingSave());
  }

  #setupSaveState({
    saveId,
    debounceTime,
  }) {
    this.#populateSavePromiseMapping(saveId);

    if (this.#saveStateMapping[saveId]) return;

    // use only one save state for each saveId to use the debounce
    this.#saveStateMapping[saveId] = new AsyncDataManagerSaveState({
      debounceTime,
    });
  }

  #callSaveState({
    saveId,
    loadId,
    saveMethod,
    onSave,
    onSaveError,
  }) {
    this.#saveStateMapping[saveId].save({
      saveMethod,
      onSaveError: (error) => {
        // always call the onSaveError before the reject
        // because outside can use that callback to resolve a self handled promise
        // and just the first reject call will be considered
        if (onSaveError) onSaveError(error);

        // resolve because it will be handled by the onSaveError
        // and it is only a generic promise to the callers know that the flow is finished
        this.#savePromiseMapping[saveId].resolve({ error });
        // once it is resolved, it finished the flow, so we can remove it
        delete this.#savePromiseMapping[saveId];
      },
      onSave: (data) => {
        // always call the onSave before the resolve
        // because outside can use that callback to resolve a self handled promise
        // and just the first resolve call will be considered
        if (onSave) onSave(data);

        this.#savePromiseMapping[saveId].resolve(data);
        // once it is resolved, it finished the flow, so we can remove it
        delete this.#savePromiseMapping[saveId];

        // only call the load if the latest save was successful
        if (this.hasPendingSave(loadId)) return;
        this.#loadStateMapping[loadId].load();
      },
    });
  }

  #populateSavePromiseMapping(saveId) {
    if (this.#savePromiseMapping[saveId]) return;

    this.#savePromiseMapping[saveId] = createDeconstructedPromise();
  }

  #populateLoadPromiseMapping(loadId) {
    if (this.#loadPromiseMapping[loadId]) return;

    this.#loadPromiseMapping[loadId] = createDeconstructedPromise();
  }

  #setupLoadState({
    loadId,
    loadMethod,
    onLoad,
    onLoadError,
  }) {
    // There are some cases when a load request was made and was not resolved yet
    // and another load request was made and resolved before the first one
    // In this case, we need to ignore the first one, to avoid to load an old data
    const currentSetupId = generateUUID();
    this.#loadStateLastSetupIdMapping[loadId] = currentSetupId;

    this.#loadStateMapping[loadId] = new AsyncDataManagerLoadState({
      loadMethod,
      onLoad: (data) => {
        if (this.#loadStateLastSetupIdMapping[loadId] !== currentSetupId) return;
        // if it has a pending save, we need to ignore the onLoad because it will be outdated
        if (this.hasPendingSave(loadId)) return;
        // always call the onLoad before the resolve
        // because outside can use that callback to resolve a self handled promise
        // and just the first resolve call will be considered
        if (onLoad) onLoad(data);

        // it is only available when the load is called directly
        this.#loadPromiseMapping[loadId]?.resolve(data);

        // remove all the data related to this loadId because it already finished the entire flow
        this.#clearState(loadId);
      },
      onLoadError: (error) => {
        if (this.#loadStateLastSetupIdMapping[loadId] !== currentSetupId) return;
        // if it has a pending save, we need to ignore the error because the next save can fix it
        if (this.hasPendingSave(loadId)) return;
        // always call the onLoadError before the reject
        // because outside can use that callback to resolve a self handled promise
        // and just the first reject call will be considered
        if (onLoadError) onLoadError(error);

        // it is only available when the load is called directly
        // resolve because it will be handled by the onLoadError
        // and it is only a generic promise to the callers know that the flow is finished
        this.#loadPromiseMapping[loadId]?.resolve({ error });

        // remove all the data related to this loadId because it already finished the entire flow
        this.#clearState(loadId);
      },
    });
  }

  #fillLoadSaveMapping(loadId, saveId) {
    this.#loadSaveMapping[loadId] ||= [];

    if (this.#loadSaveMapping[loadId].includes(saveId)) return;

    this.#loadSaveMapping[loadId].push(saveId);
  }

  save({
    saveId,
    loadId,
    saveMethod,
    loadMethod,
    onSave,
    onSaveError,
    onLoad,
    onLoadError,
    debounceTime = DEFAULT_DEBOUNCE_TIME,
  } = {}) {
    this.#fillLoadSaveMapping(loadId, saveId);

    // aways setup the load state first
    this.#setupLoadState({
      loadId,
      loadMethod,
      onLoad,
      onLoadError,
    });

    this.#setupSaveState({
      saveId,
      debounceTime,
    });

    this.#callSaveState({
      saveId,
      loadId,
      saveMethod,
      onSave,
      onSaveError,
    });

    return this.#savePromiseMapping[saveId].promise;
  }

  load({
    loadId,
    loadMethod,
    onLoad,
    onLoadError,
  } = {}) {
    // register a promise to be returned when the load is called
    this.#populateLoadPromiseMapping(loadId);

    // register this call to ensure that the load will be resolved just for the last one
    this.#setupLoadState({
      loadId,
      loadMethod,
      onLoad,
      onLoadError,
    });

    // the save will call the load after it is finished
    if (this.hasPendingSave(loadId)) return this.#loadPromiseMapping[loadId].promise;

    this.#loadStateMapping[loadId].load();

    return this.#loadPromiseMapping[loadId].promise;
  }
}
