import { defaultOptions } from '~/plugins/vue-sanitize';

export const sanitizeHtml = (html, sanitizeMethod) => sanitizeMethod(html, {
  ...defaultOptions,
  allowedTags: [
    ...defaultOptions.allowedTags,
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'ol',
    'table',
    'thead',
    'tbody',
    'span',
    'tr',
    'th',
    'td',
    'img',
    'iframe',
    's',
    'code',
    'pre',
  ],
  allowedAttributes: {
    ...defaultOptions.allowedAttributes,
    div: ['style'],
    pre: ['spellcheck'],
    '*': ['data-*', 'class', 'id'],
    img: ['src', 'style', 'alt', 'width', 'height'],
    span: ['contenteditable'],
    iframe: ['frameborder', 'allowfullscreen', 'src'],
    ul: ['data-checked'],
  },
  transformTags: {},
});
