import orderBy from 'lodash/orderBy';
import FIELDS from './fields';
import TRIGGERS from './triggers';
import STEPS from './steps';
import VIEWS from './views';
import PAGE_STATE_TYPES from './pageStates';

function sortByPosition(obj) {
  return Object.fromEntries(orderBy(Object.entries(obj), ([_, value]) => value.position));
}

export const STEP_TYPES = sortByPosition(STEPS);
export const FIELD_TYPES = sortByPosition(FIELDS);
export const TRIGGER_TYPES = sortByPosition(TRIGGERS);
export const VIEW_TYPES = sortByPosition(VIEWS);

export const AVAILABLE_TYPES = Object.keys(FIELD_TYPES).filter(type => !['User'].includes(type));
export const CONFIDENTIAL_SHEET_AVAILABLE_TYPES = AVAILABLE_TYPES.filter(type => !['Formula'].includes(type));
export const AVAILABLE_TRIGGER_TYPES = Object.keys(TRIGGER_TYPES);
export const AVAILABLE_VIEW_TYPES = Object.keys(VIEW_TYPES);
export const AVAILABLE_PAGE_STATE_TYPES = Object.keys(PAGE_STATE_TYPES);

export const NON_SHEET_BASED_VIEW_TYPES = ['Content'];
export const SHEET_BASED_VIEW_TYPES = Object.keys(VIEW_TYPES).filter(type => !NON_SHEET_BASED_VIEW_TYPES.includes(type));
export const PUBLIC_VIEW_TYPES = AVAILABLE_VIEW_TYPES.filter(type => VIEW_TYPES[type].canBePublic);

const AVAILABLE_TRIGGERS_BY_VIEW_TYPE = {
  Cards: ['UserRequest', 'Schedule'],
  Table: ['UserRequest', 'Schedule'],
  Form: ['UserRequest', 'FormSubmit'],
  Timeline: ['UserRequest', 'FormSubmit'],
  Kanban: ['UserRequest'],
  Content: [],
  Matrix: ['UserRequest'],
  Calendar: ['UserRequest'],
  Report: [],
  Tree: ['UserRequest'],
};
const TRIGGER_CONFIG = {
  RecordCreated: {
    viewTypes: [],
    sheetBased: true,
    viewBased: false,
  },
  RecordUpdated: {
    viewTypes: [],
    sheetBased: true,
    viewBased: false,
  },
  RecordDeleted: {
    viewTypes: [],
    sheetBased: true,
    viewBased: false,
  },
  UserSignedUp: {
    viewTypes: [],
    sheetBased: true,
    viewBased: false,
  },
  UserRequest: {
    viewTypes: Object.keys(AVAILABLE_TRIGGERS_BY_VIEW_TYPE).filter(type => !['Content', 'Report'].includes(type)),
    sheetBased: false,
    viewBased: true,
  },
  FormSubmit: {
    viewTypes: ['Form', 'Timeline'],
    sheetBased: false,
    viewBased: true,
  },
  Schedule: {
    viewTypes: ['Cards', 'Table'],
    sheetBased: false,
    viewBased: true,
  },
};

export {
  AVAILABLE_TRIGGERS_BY_VIEW_TYPE,
  PAGE_STATE_TYPES,
  TRIGGER_CONFIG,
};
