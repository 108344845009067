// TODO: Review after migration
export const GENERIC_ARRAY_SUBTYPES = [
  'Text',
  'Number',
  'KeyValueMap',
];

export const KEY_VALUE_MAP_TYPES_ICONS = [
  { type: 'Text', icon: 'square-t', iconKind: 'regular' },
  { type: 'Number', icon: 'input-numeric', iconKind: 'solid' },
  { type: 'FieldFileBase64', icon: 'paperclip', iconKind: 'solid' },
  { type: 'KeyValueMap', icon: 'brackets-curly', iconKind: 'solid' },
  { type: 'GenericArray', icon: 'brackets-square', iconKind: 'solid' },
];
