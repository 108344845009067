import { LOCAL_STORAGE_KEY } from '~/plugins/vuex-persist';

export class Module {
  constructor($auth) {
    this.$auth = $auth;
  }

  logout() {
    this.$auth.logout();

    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    window.analytics?.reset?.();
  }
}

export const LogoutSetup = (nuxt) => {
  const module = new Module(nuxt.$auth);

  nuxt.$auth.logoutUser = (...args) => module.logout(...args);
};
