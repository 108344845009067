<i18n lang="yaml">
pt:
  investors: 'Investidores'
  cta: 'Agende uma demonstração'
  privacyPolicy: 'Política de Privacidade'
  TOS: 'Termos e Condições Gerais de Uso'
  safety: 'Segurança da Zazos'
en:
  investors: 'Investors'
  cta: 'Try it out'
  privacyPolicy: 'Privacy Policy'
  TOS: 'Terms of Service'
  safety: 'Zazos Safety'
</i18n>

<template>
  <div class="store-footer">
    <div class="store-footer__section store-footer__section--brand">
      <a
        href="https://zazos.com"
      >
        <img
          src="/zazos-text.svg"
          alt="zazos"
        >
      </a>
      <v-btn
        x-large
        color="#FD8C41"
        rounded
        elevation="0"
        :to="localePath({ name: 'sign-up', query: { utm_source: 'footer' } })"
        class="white--text text-capitalize"
      >
        {{ $t('cta') }}
      </v-btn>
    </div>

    <v-divider class="my-10" />

    <div class="store-footer__section">
      <p class="store-footer__item store-footer__item--text">
        {{ $t('investors') }}
      </p>
      <img class="store-footer__item store-footer__item--investors" src="/y-combinator-logo.svg" alt="Y Combinator" height="40px">
      <img class="store-footer__item store-footer__item--investors" src="/quiet-capital-logo.svg" alt="Quiet Capital" height="40px">
      <img class="store-footer__item store-footer__item--investors" src="/norte-ventures-logo.svg" alt="Norte Ventures" height="40px">
    </div>

    <v-divider class="my-10" />

    <div class="store-footer__section">
      <p class="store-footer__item store-footer__item--text">
        Copyright © 2022 zazos.com
      </p>
      <a
        href="https://www.zazos.com/legal/politica-de-privacidade"
        target="_blank"
        rel="noopener noreferrer"
        class="store-footer__item store-footer__item--link"
      >
        {{ $t('privacyPolicy') }}
      </a>
      <a
        href="https://www.zazos.com/legal/termos-e-condicoes-gerais-de-uso"
        target="_blank"
        rel="noopener noreferrer"
        class="store-footer__item store-footer__item--link"
      >
        {{ $t('TOS') }}
      </a>
      <a
        href="https://www.zazos.com/legal/programa-de-seguranca-da-zazos"
        target="_blank"
        rel="noopener noreferrer"
        class="store-footer__item store-footer__item--link"
      >
        {{ $t('safety') }}
      </a>
      <div class="store-footer__socials">
        <a
          href="mailto:hello@zazos.com?subject=Contato%20Landing%20Page"
          class="store-footer__item"
        >
          <v-icon>
            fa-regular fa-envelope
          </v-icon>
        </a>
        <a
          href="https://www.linkedin.com/company/zazos/about/"
          target="_blank"
          rel="noopener noreferrer"
          class="store-footer__item"
        >
          <v-icon>
            fa-brands fa-linkedin
          </v-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreFooter',
};
</script>

<style lang="scss">
.store-footer {
  padding-block: 100px;
  background-image: url("~/static/zazos-bolt.svg");
  background-size: 100px;
  background-position: calc(50% + 16px) 24px;
}

.store-footer__section {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: space-around;
  align-items: center;
}

.store-footer__section--brand {
  @media (min-width: 840px) {
    justify-content: space-between;
  }
}

.store-footer__socials {
  display: flex;
  gap: 48px;
}

.store-footer__item {
  font-size: 14px;
}

.store-footer__item--text {
  font-weight: 500;
  margin-bottom: 0 !important;
  width: 100%;
  text-align: center;

  @media (min-width: 840px) {
    margin-right: auto;
    width: unset;
    text-align: unset;
  }
}

.store-footer__item--link {
  color: #606060 !important; // a override
}
</style>
