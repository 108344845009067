import { buildBaseInfoComponent } from './_base';

export const buildActionButtonInfoComponent = ({
  action,
  columns = 1,
  id,
  label,
  params,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    action,
    columns,
    label,
    params,
    type: 'action-button',
  };

  if (id) component.id = id;

  return component;
};
