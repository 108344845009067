import { flattenDeep } from 'lodash';
import { getFieldDisplayName } from '~/assets/javascript/utils';

export default class RecordList {
  constructor(view, draftRecords, options, emptyLabel, filterByValue) {
    this.view = view;
    this.draftRecords = draftRecords;
    this.emptyLabel = emptyLabel;
    this.options = options;
    this.filterByValue = filterByValue;
  }

  findRecord(recordId) {
    if (this.hasToGroupRecords) {
      const group = this.#findRecordGroupByRecordId(recordId);
      return group?.records?.find(record => record.id === recordId);
    }

    return this.records.find(record => record.id === recordId);
  }

  recordIndex(recordId) {
    if (this.hasToGroupRecords) {
      const group = this.#findRecordGroupByRecordId(recordId);

      return group?.records?.findIndex(record => record.id === recordId);
    }

    return this.records.findIndex(record => record.id === recordId);
  }

  recordHasNext(recordId) {
    const recordIndex = this.recordIndex(recordId);

    if (this.hasToGroupRecords) {
      const group = this.#findRecordGroupByRecordId(recordId);
      if (!group) return false;
      return recordIndex < group.records.length - 1;
    }

    return recordIndex < this.records.length - 1;
  }

  nextRecordId(recordId) {
    const recordIndex = this.recordIndex(recordId);

    if (this.hasToGroupRecords) {
      const group = this.#findRecordGroupByRecordId(recordId);
      if (!group) return null;
      const nextRecord = group.records[recordIndex + 1];
      return nextRecord ? nextRecord.id : null;
    }

    const nextRecord = this.records[recordIndex + 1];
    return nextRecord ? nextRecord.id : null;
  }

  recordHasPrevious(recordId) {
    const recordIndex = this.recordIndex(recordId);
    return recordIndex > 0;
  }

  previousRecordId(recordId) {
    if (!this.recordHasPrevious(recordId)) return null;

    const recordIndex = this.recordIndex(recordId);

    if (this.hasToGroupRecords) {
      const group = this.#findRecordGroupByRecordId(recordId);
      if (!group) return null;
      const previousRecord = group.records[recordIndex - 1];
      return previousRecord ? previousRecord.id : null;
    }

    const previousRecord = this.records[recordIndex - 1];
    return previousRecord ? previousRecord.id : null;
  }

  /**
   * @returns {boolean}
   * @readonly
   * @description
   * Returns true if the view requires to group records by some field value.
   * Otherwise, returns false.
   */
  get hasToGroupRecords() {
    return this.options.hasGroup && this.view.page_type === 'Kanban';
  }

  get records() {
    const records = this.#filterRecords(this.draftRecords);

    return this.#groupRecords(records);
  }

  get groupField() {
    return this.options.fieldIdMapping[this.options.groupFieldId];
  }

  #filterRecords(items) {
    if (!this.options.hasFilterBy || !this.filterByValue) return items;

    const filterEmpty = this.filterByValue === this.emptyLabel;

    return items.filter((record) => {
      const fieldData = record[this.options.filterByFieldId];

      if (!fieldData) return filterEmpty;

      const displayName = getFieldDisplayName(
        fieldData,
        this.options.fieldIdMapping[this.options.filterByFieldId],
      );

      if (Array.isArray(displayName)) {
        if (displayName.length === 0) return filterEmpty;

        return flattenDeep(displayName).includes(this.filterByValue);
      }

      return displayName === this.filterByValue;
    });
  }

  #groupRecords(items) {
    if (!this.hasToGroupRecords) return items;

    const emptyGroup = { value: this.emptyLabel, records: [], recordCount: 0 };
    const optionsGroups = this.groupField?.options?.select_options?.map(option => ({
      ...option,
      records: [],
      recordCount: 0,
    })) || [];

    const groups = [emptyGroup, ...optionsGroups];

    items.forEach((record) => {
      const fieldData = record[this.options.groupFieldId];

      if (!fieldData) {
        groups[0].records.push(record);
        groups[0].recordCount += 1;
        return;
      }

      const displayName = getFieldDisplayName(
        fieldData,
        this.options.fieldIdMapping[this.options.groupFieldId],
      );

      if (Array.isArray(displayName) && displayName.length === 0) {
        groups[0].records.push(record);
        groups[0].recordCount += 1;
        return;
      }

      const group = groups.find(
        group => (Array.isArray(displayName) ? displayName.includes(group.value) : group.value === displayName),
      );

      if (!group) return;

      group.records.push(record);
      group.recordCount += 1;
    });

    return groups;
  }

  #findRecordGroupByRecordId(recordId) {
    return this.records.find(group => group.records.some(record => record.id === recordId));
  }
}
