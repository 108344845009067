import moment from 'moment-timezone';

export const generateFilledYearCalendarItem = (year, originItem, startKey, endKey = null) => {
  const startValue = originItem[startKey]?.value;
  const endValue = endKey ? originItem[endKey]?.value : null;

  const newStartValue = moment(startValue).year(year).format('YYYY-MM-DD');
  const newEndValue = endValue ? moment(endValue).year(year).format('YYYY-MM-DD') : null;

  const newItem = {
    ...originItem,
    [startKey]: {
      ...originItem[startKey],
      value: newStartValue,
    },
  };

  if (newEndValue) {
    newItem[endKey] = {
      ...originItem[endKey],
      value: newEndValue,
    };
  }

  return newItem;
};

export const generateFilledYearCalendarItems = (yearsToFill, originItem, startKey, endKey = null) => {
  const yearsArray = Array.from({ length: yearsToFill }, (_, i) => i + 1 + moment().year());

  return yearsArray.map(year => generateFilledYearCalendarItem(year, originItem, startKey, endKey));
};
