export const state = () => ({
  inStudio: false,
  contentExpanded: false,
});

export const mutations = {
  setInStudio(state) {
    state.inStudio = true;
  },
  setOutsideStudio(state) {
    state.inStudio = false;
  },
  setContentExpanded(state, value) {
    state.contentExpanded = value;
  },
};

export const actions = {
  toggleContentExpand({ commit, state }) {
    commit('setContentExpanded', !state.contentExpanded);
  },
};
