import { HEX_COLORS } from '~/assets/javascript/constants';
import { getHexPalette } from '~/assets/javascript/utils/color';

const headlineFontSize = 22;
const headlineFontWeight = 'normal';
const labelFont = 'Inter, sans-serif';
const labelFontSize = 12;
const labelFontWeight = 400;
const labelColor = '#656565';
const labelPadding = 8;
const titleFont = 'Inter, sans-serif';
const titleFontWeight = 400;
const titleFontSize = 13;
const titleColor = '#656565';
const gridColor = '#f3f3f5';
const domainColor = '#d7d7d7';
const cornerRadius = 4;
const padding = 8;
const tickSize = 0;

export const zazosTheme = (markColor = '#3F51B5') => ({
  background: '#ffffff',
  view: {
    stroke: false,
  },
  title: {
    anchor: 'start',
    color: '#000000',
    font: titleFont,
    fontSize: headlineFontSize,
    fontWeight: headlineFontWeight,
    subtitlePadding: padding,
  },

  arc: {
    fill: markColor,
    stroke: '#ffffff',
    strokeJoin: 'round',
    strokeWidth: 2,
    cornerRadius: 2 + cornerRadius,

  },
  area: { fill: markColor },
  bar: { fill: markColor },
  line: { stroke: markColor, strokeWidth: 2 },
  path: { stroke: markColor },
  rect: { fill: markColor },
  shape: { stroke: markColor },
  symbol: { fill: markColor, size: 30 },

  mark: {
    cornerRadius,
  },

  axis: {
    titleFont,
    titleFontSize,
    titleFontWeight,
    titleColor,
    titlePadding: padding,
  },

  axisX: {
    labelFont,
    labelFontSize,
    labelFontWeight,
    labelColor,
    labelPadding,
    gridColor,
    domainColor,
    labelAngle: 0,
    tickSize,
  },

  axisY: {
    labelFont,
    labelFontSize,
    labelFontWeight,
    labelColor,
    labelPadding,
    gridColor,
    domainColor,
    labelBaseline: 'middle',
    maxExtent: 45,
    minExtent: 45,
    tickSize,
    titleAlign: 'left',
    titleAngle: 0,
    titleX: -45,
    titleY: -11,
  },

  legend: {
    labelFont,
    labelFontSize,
    labelColor,
    symbolType: 'circle',
    titleFont,
    titleFontSize,
    titleFontWeight,
    strokeColor: domainColor,
    padding,
    cornerRadius,
  },

  range: {
    category: HEX_COLORS,
    diverging: ['#f44336', gridColor, '#2196f3'],
    heatmap: [gridColor, markColor],
    ordinal: getHexPalette(gridColor, markColor, 16),
    ramp: [gridColor, markColor],
  },
});

