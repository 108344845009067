import { appInstallations } from './appInstallations';
import { apps } from './apps';
import { categories } from './categories';
import { entityDependencies } from './entityDependencies';
import { globalParameters } from './globalParameters';
import { providerIntegrations } from './providerIntegrations';
import { schema } from './schema';
import { secrets } from './secrets';
import { tools } from './tools';
import { views } from './views';
import { workflowExecutions } from './workflowExecutions';
import { workflowSchemaParameters } from './workflowSchemaParameters';
import { workflowTriggerViews } from './workflowTriggerViews';
import { ssoProviders } from './ssoProviders';

export const builder = $axios => ({
  appInstallations: appInstallations($axios),
  apps: apps($axios),
  categories: categories($axios),
  entityDependencies: entityDependencies($axios),
  globalParameters: globalParameters($axios),
  providerIntegrations: providerIntegrations($axios),
  schema: schema($axios),
  secrets: secrets($axios),
  ssoProviders: ssoProviders($axios),
  tools: tools($axios),
  views: views($axios),
  workflowExecutions: workflowExecutions($axios),
  workflowSchemaParameters: workflowSchemaParameters($axios),
  workflowTriggerViews: workflowTriggerViews($axios),
});
