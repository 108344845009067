// ~/plugins/notifier.js
import { generateUuid } from '~/assets/javascript/utils';

export const notifier = store => ({
  showMessage({ content = '', color = '', timeout }) { // When not defining the timeout, it will rely on the deck-snackbar default timeout
    return store.dispatch('snackbar/showMessage', { message: content, color, timeout, id: generateUuid() });
  },
});

export default ({ store }, inject) => {
  inject('notifier', notifier(store));
};
