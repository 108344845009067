<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4 pa-8">
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'Dev',
};
</script>
