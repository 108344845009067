export const entityDependencies = $axios => ({
  listByTargetIdAndType: async (targetId, targetType) => $axios.$get(
    '/builder/entity_dependencies',
    { params: { target_id: targetId, target_type: targetType } },
  ),
  listBySourceIdAndType: async (sourceId, sourceType) => $axios.$get(
    '/builder/entity_dependencies',
    { params: { source_id: sourceId, source_type: sourceType } },
  ),
});
