<template>
  <span class="deck-icon">
    <v-icon
      ref="icon"
      v-bind="$attrs"
      :style="cssProps"
      class="deck-icon__icon"
    >
      {{ `fa-${computedKind} fa-${computedName}` }}
    </v-icon>

    <deck-tooltip
      v-if="shouldRenderTooltip"
      ref="tooltip"
      v-bind="computedTooltipProps"
      class="deck-icon__tooltip"
    >
      <!-- @slot tooltip Slot to render custom content in the inner chip tooltip -->
      <template v-if="$slots.tooltip" #content>
        <slot name="tooltip" />
      </template>
    </deck-tooltip>
  </span>
</template>

<script>
import { camelCase } from 'lodash';
import { COLORS } from '~/assets/javascript/constants';

/**
 * A wrapper around v-icon that uses Font-Awesome icons and allows for custom sizing and coloring.
 */
export default {
  name: 'DeckIcon',
  components: {
    // eslint-disable-next-line import/no-cycle
    DeckTooltip: () => import('~/deck/tooltip'),
  },
  props: {
    /**
     * The name of the icon from Font-Awesome. Will be prefixed with `fa-` automatically (eg: pencil, xmark, etc...). See https://fontawesome.com/search.
     * @type {string}
     */
    name: {
      type: String,
      required: true,
    },

    /**
     * The text to display in the tooltip when hovering the icon.
     * @type {string}
     * @default undefined
     */
    text: {
      type: String,
      default: undefined,
    },

    /**
     * Size of the icon. Can be a semantic size (small, medium, large), inherit the parent font size (font), or a CSS size (in any unit, eg: 10px, 2rem, 50%, etc...).
     * @type {'small' | 'medium' | 'large' | 'font' | string}
     * @default 'medium'
     */
    size: {
      type: String,
      default: 'medium',
    },

    /**
     * The kind of icon. Will default to 'regular', but will be 'solid' when `size='small'` or explicitly set value otherwise.
     * @type {'regular' | 'solid' | 'brands' | 'kit' | string}
     * @default 'regular'
     */
    kind: {
      type: String,
      default: undefined, // undefined will default to 'regular' in the computed property unless explicitly set
    },

    /**
     * The color of the icon. Choose from preset or any valid CSS color. Inherit parent font color by default.
     * @type { 'currentColor' | 'blue' | 'cyan' | 'teal' | 'green' | 'yellow' | 'orange' | 'red' | 'pink' | 'deepPurple' | 'grey' | string}
     * @default 'currentColor'
     */
    color: {
      type: String,
      default: 'currentColor',
    },

    /**
     * Whether the icon should have a fixed width for balanced alignment. See https://fontawesome.com/docs/web/style/fixed-width.
     * @type {boolean}
     * @default false
     */
    fixedWidth: {
      type: Boolean,
      default: false,
    },

    /**
     * Additional props to be passed to the `deck-tooltip` component if `icon`||`number` and `text` are set.
     * @type {Object}
     * @default {}
     */
    tooltipProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      iconRef: null,
    };
  },
  computed: {
    cssProps() {
      return {
        '--deck-icon-size': this.computedSize,
        '--deck-icon-color': this.computedColor,
        '--deck-icon-width': this.computedWidth,
      };
    },
    computedSize() {
      if (this.size === 'small') return '12px';
      if (this.size === 'medium') return '16px';
      if (this.size === 'large') return '20px';
      if (this.size === 'font') return '1em';

      return this.size;
    },
    computedWidth() {
      if (this.fixedWidth) {
        if (this.size === 'small') return '16px';
        if (this.size === 'medium') return '20px';
        if (this.size === 'large') return '24px';
        if (this.size === 'font') return '1.25em';
      }

      return 'auto';
    },
    computedKind() {
      if (this.legacyNameFallback?.kind) return this.legacyNameFallback.kind;

      if (this.kind === undefined && this.size === 'small') return 'solid';
      if (this.kind === undefined && this.size !== 'small') return 'regular';

      return this.kind; // override default values
    },
    computedName() {
      if (this.legacyNameFallback?.name) return this.legacyNameFallback.name;

      return this.name;
    },
    availableColors() {
      // in: { colorName: { base: { background: 'colorValue' } }, ... }
      // out: { colorName: 'colorValue', ... }
      const formattedColors = Object.fromEntries(
        Object.keys(COLORS)
          .map(colorName => [colorName, COLORS[colorName].base.background]),
      );

      return { ...formattedColors, ...this.$vuetify.theme.currentTheme };
    },
    computedColor() {
      return this.availableColors[camelCase(this.color)] || this.color;
    },
    legacyNameFallback() {
      /**
       * Today, naming is done with two props: `name` and `kind`. Legacy was a
       * single string composed of two namespaced words: `fa-${kind} fa-${name}`
       * or `fa-${name} fa-${kind}`.
       *
       * We need to detect if we tried to use the legacy naming convention, and
       * return a proper object with name and kind extracted that will be used
       * instead.
       *
       * TODO: This is a temporary solution until we can remove the legacy
       * naming convention safely (specially for cases where we defined icons
       * from the backend).
       *
      **/
      if (!this.name?.includes('fa-')) return null;

      const kinds = ['fa-regular', 'fa-solid', 'fa-brands', 'fa-kit'];
      const legacyNameParts = this.name.split(' ').filter(Boolean);

      const name = legacyNameParts.find(part => !kinds.includes(part));
      const kind = legacyNameParts.find(part => kinds.includes(part));

      if (!name || !name.includes('fa-')) {
        return {
          name: 'rectangle-xmark', // Renders an icon that represents a missing icon
          kind: 'regular',
        };
      }

      return {
        name: name.split('fa-')[1].trim(),
        kind: kind?.split('fa-')?.[1]?.trim(), // if kind is undefined, it will eventually inherit the default value in the computed property
      };
    },
    shouldRenderTooltip() {
      if (this.text === undefined && this.tooltipProps.text === undefined) return false;
      if (this.name === undefined) return false;

      return true;
    },
    computedTooltipProps() {
      return {
        ...this.tooltipProps,
        text: this.tooltipProps.text || this.text, // Auto populate tooltip with `text` prop
        activator: this.iconRef,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.iconRef = this.$refs?.icon?.$el;
    });
  },
};
</script>
<style lang="scss">
.deck-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.deck-icon__icon {
  font-size: var(--deck-icon-size) !important;
  color: var(--deck-icon-color) !important;
  width: var(--deck-icon-width);

  &.v-icon.fa::before {
    font-size: 1em; // TODO: remove this when deck-icon is used widely instead of v-icon and we remove this global override on `~/assets/styles/overrides/vuetify`
  }

  &.v-icon.v-icon::after {
    transform: unset !important; // No idea why vuetify applied a transform: scale(1.2) here. Can mess up overflowing parents and trigger scrollbars.
  }
}
</style>
