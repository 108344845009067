export const state = () => ({
  secrets: [],
});

export const mutations = {
  setSecrets(state, secrets) {
    state.secrets = secrets;
  },
};

export const actions = {
  async fetchSecrets({ commit }) {
    const data = await this.$apiClient.builder.secrets.list();
    commit('setSecrets', data);
  },
  async fetchSecret(_, secretId) {
    const data = await this.$apiClient.builder.secrets.get(secretId);
    return data;
  },
  async updateSecret({ dispatch }, { id: secretId, ...secretData }) {
    const data = await this.$apiClient.builder.secrets.update(secretId, secretData);
    await dispatch('fetchSecrets'); // that's not really performatic but it's ok for now
    return data;
  },
  async createSecret({ dispatch }, secretData) {
    const data = await this.$apiClient.builder.secrets.create(secretData);
    await dispatch('fetchSecrets'); // that's not really performatic but it's ok for now
    return data;
  },
  async deleteSecret({ dispatch }, secretId) {
    const data = await this.$apiClient.builder.secrets.delete(secretId);
    await dispatch('fetchSecrets'); // that's not really performatic but it's ok for now
    return data;
  },
};
