// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/zazos-bolt.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-paywall-bar{align-items:center;background-color:#003255;border-radius:8px;bottom:0;color:#fff;display:flex;gap:16px;height:56px;justify-content:center;left:0;margin:8px auto;max-width:1200px;padding-left:16px;padding-right:16px;position:fixed;right:0;transition:transform .3s ease-in-out;width:calc(100% - 16px);z-index:201}.the-paywall-bar--hide{transform:translateY(calc(100% + 32px))}.the-paywall-bar__main-content{align-items:center;display:flex}.the-paywall-bar__cta{color:#fff!important;margin-left:16px}.the-paywall-bar__cta :after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;bottom:8px;content:\"\";height:40px;left:calc(100% + 32px);pointer-events:none;position:absolute;width:96px}.the-paywall-bar__close-icon{color:#fff!important;position:absolute!important;right:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
