export const AVAILABLE_FILTER_FIELD_TYPES = Object.freeze([
  'Date',
  'DateTime',
  'Number',
  'String',
  'Markdown',
  'Select',
  'MultipleSelect',
  'Attachment',
  'CoverImage',
  'Link',
]);

export const LOGICAL_OPERATORS = Object.freeze([
  'And',
  'Or',
]);

export const RELATIONAL_OPERATORS = Object.freeze([
  'Greater',
  'GreaterOrEqual',
  'Less',
  'LessOrEqual',
]);

export const EXISTENTIAL_OPERATORS = Object.freeze([
  'Empty',
  'NotEmpty',
]);

export const EQUALITY_OPERATORS = Object.freeze([
  'Equal',
  'NotEqual',
]);

export const CONTAINS_OPERATORS = Object.freeze([
  'Contains',
  'DoesNotContain',
]);

export const CONTAINS_EXTENDED_OPERATORS = Object.freeze([
  'ContainsAny',
  'ContainsAll',
  'DoesNotContainAny',
  'DoesNotContainAll',
]);

export const UNARY_OPERATORS = Object.freeze([
  ...EXISTENTIAL_OPERATORS,
]);

export const OPERATORS_BY_FIELD_TYPE_MAPPING = Object.freeze({
  Date: Object.freeze([...EQUALITY_OPERATORS, ...RELATIONAL_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  DateTime: Object.freeze([...EQUALITY_OPERATORS, ...RELATIONAL_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  Number: Object.freeze([...EQUALITY_OPERATORS, ...RELATIONAL_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  String: Object.freeze([...EQUALITY_OPERATORS, ...CONTAINS_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  Select: Object.freeze([...EQUALITY_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  MultipleSelect: Object.freeze([...EQUALITY_OPERATORS, ...CONTAINS_EXTENDED_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  Markdown: Object.freeze([...CONTAINS_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  Link: Object.freeze([...CONTAINS_OPERATORS, ...EXISTENTIAL_OPERATORS]),
  Attachment: EXISTENTIAL_OPERATORS,
  CoverImage: EXISTENTIAL_OPERATORS,
});

export const DEFAULT_NEW_FILTER = Object.freeze({
  operator: 'Equal',
  options: {},
  operands: [{
    value: null,
    type: 'String',
    method: 'FieldValue',
  }, {
    value: null,
    type: 'String',
    method: 'Static',
  }],
});

export const DEFAULT_NEW_FILTER_GROUP = Object.freeze({
  operator: 'And',
  operands: [{
    operator: 'And',
    operands: [],
  }],
});
