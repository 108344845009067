<template>
  <v-list-item
    v-test-id="'list-item-activator-item'"
    :exact="exactPath"
    :exact-path="exactPath"
    class="list-item"
    :class="{
      'list-item--draggable': draggable,
      'list-item--active': active
    }"
    :aria-label="item.title"
    :to="item.to || null"
    :disabled="disabled"
    :value="item.id || null"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    v-on="$listeners"
  >
    <deck-icon
      v-if="draggable"
      v-test-class="'list-item-drag-icon'"
      name="grip-dots-vertical"
      class="list-item__drag-icon"
    />

    <slot
      name="item-content"
      :item="item"
      :index="index"
    />

    <v-list-item-content
      v-if="!itemContentSlot"
      class="list-item__content"
    >
      <deck-icon
        v-if="item.icon"
        class="list-item__content-icon"
        color="var(--z-color-label)"
        size="small"
        fixed-width
        :name="item.icon"
        :disabled="disabled"
      />
      <v-list-item-title
        :class="{
          'grey--text': item.inactive,
          [itemTitleTypography]: true
        }"
        :title="item.title || item.text"
      >
        {{ item.title || item.text }}
      </v-list-item-title>
    </v-list-item-content>

    <OptionsMenu
      v-if="!hideOptionsMenu && !itemActionsSlot && item.actions && item.actions.length > 0"
      :show-menu-activator="fixedOptionsMenu || hover"
      :item="item"
      :left="left"
      @menuActivatorClick="$emit('menuActivatorClick')"
    >
      <template #prependItems>
        <slot name="optionsMenuPrependItems" />
      </template>
    </OptionsMenu>

    <div
      v-if="activeActions.length > 0"
      class="d-flex align-center g-1 ml-1"
    >
      <template v-for="activeAction in activeActions">
        <deck-chip
          v-if="activeAction.activeIcon"
          :key="activeAction.activeIcon"
          :icon="activeAction.activeIcon"
          :text="activeAction.activeIconTooltip"
          :color="activeAction.activeIconColor"
          :tooltip-props="{
            disabled: !activeAction.activeIconTooltip,
            kind: activeAction.activeIconTooltipKind,
          }"
          class="flex-none"
          size="x-small"
        />
      </template>
    </div>

    <slot
      name="item-actions"
      :item="item"
      :index="index"
    />
  </v-list-item>
</template>
<script>
import DeckChip from '~/deck/chip';

export default {
  name: 'ListItem',
  components: {
    OptionsMenu: () => import('~/components/options-menu'),
    DeckIcon: () => import('~/deck/icon'),
    DeckChip,
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    itemTitleTypography: { type: String, default: 'text-body-2' },
    draggable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    exactPath: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    fixedOptionsMenu: { type: Boolean, default: false },
    hideOptionsMenu: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    itemContentSlot() {
      return this.$scopedSlots['item-content'];
    },
    itemActionsSlot() {
      return this.$scopedSlots['item-actions'];
    },
    activeActions() {
      return this.item?.actions?.filter(action => action.active) || [];
    },
  },
  methods: {
    subMenuItemClicked(item, subMenuItemCallback) {
      item.menuOpen = false;
      subMenuItemCallback();
    },
  },
};
</script>
<style lang="scss">
.list-item {
  --list-item-background-color: transparent;
  --list-item-background-color-highlight: var(--z-color-subtle-shade);
  --list-item-background-color-toggle: var(--z-color-accent-shade);

  min-height: 32px;
  padding: 0;

  > .list-item__content {
    align-items: center;
    padding: 0 var(--z-s3);
    transition: 200ms ease;

    > .list-item__content-icon {
      flex: 0 0 auto;
      margin-right: var(--z-s1);
      margin-bottom: 0;
      width: 20px;
    }

    > .v-list-item__title {
      line-height: var(--z-line-height-base);
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  &.sortable-chosen:hover {
    cursor: grabbing;

    > .list-group-activator__drag-icon {
      cursor: grabbing;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &::before {
    opacity: 1 !important;
    background-color: var(--list-item-background-color);
    transition: background-color 150ms ease;
  }

  &.v-list-item--active::before, &.list-item--active::before {
    background-color: var(--list-item-background-color-toggle);
  }

  &:hover::before, &:focus-within::before {
    background-color: var(--list-item-background-color-highlight);
  }
}

.list-item__drag-icon {
  opacity: 0;
  left: 0;
  position: absolute !important;
  height: 100%;
  min-width: 0 !important;
  width: 12px;
  border-radius: 4px;
  transition: 200ms ease-in-out !important;
  z-index: 1;

  &:hover {
    cursor: grab;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    cursor: grabbing;
  }
}

.list-item--draggable:hover .list-item__drag-icon {
  opacity: 1;
}
</style>
