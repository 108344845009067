<i18n lang="yaml">
pt:
  weWillContactYouSoon: 'Entraremos em contato em breve!'
en:
  weWillContactYouSoon: 'We will contact you soon!'
</i18n>

<template>
  <ThePaywallWall
    v-if="displayWall && !isZazosUser"
    @ctaClick="notifyContact"
  />
  <ThePaywallBar
    v-else-if="isAdmin && isTrial"
    @ctaClick="notifyContact"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ThePaywallBar from './_bar';
import ThePaywallWall from './_wall';

export default {
  name: 'ThePaywall',
  components: {
    ThePaywallBar,
    ThePaywallWall,
  },
  computed: {
    ...mapGetters('workspace', ['isTrial', 'isChurned']),
    ...mapState('workspace', ['workspace']),
    isZazosUser() {
      return Boolean(this.$auth.user?.username?.includes('@zazos.com'));
    },
    isTrialEnded() {
      return this.isTrial && this.workspace.trial_remaining_days < 0;
    },
    displayWall() {
      return this.isTrialEnded || this.isChurned;
    },
    isAdmin() {
      return this.$auth.user?.role === 'admin';
    },
  },
  methods: {
    notifyContact() {
      window.analytics.track('clickedPaywallContactUs');

      this.$notifier.showMessage({
        content: this.$t('weWillContactYouSoon'),
        color: 'success',
      });
    },
  },
};
</script>
