export const state = () => ({
  stack: [],
});

export const getters = {
  currentDialog(state) {
    return state.stack[state.stack.length - 1];
  },
};

export const mutations = {
  openDialog(state, content) {
    state.stack.push(content);
  },
  closeDialog(state) {
    const currentDialog = state.stack[state.stack.length - 1];
    if (currentDialog?.on?.close) currentDialog.on.close();

    state.stack.pop();
  },
  addDialogToOpenNext(state, content) {
    const currentDialogIndex = state.stack.length - 1;

    state.stack.splice(currentDialogIndex, 0, content);
  },
  updateCurrentDialog(state, content) {
    const currentDialogIndex = state.stack.length - 1;

    state.stack.splice(currentDialogIndex, 1, {
      ...state.stack[currentDialogIndex],
      ...content,
    });
  },
  closeAllDialogs(state) {
    state.stack = [];
  },
};

export default {
  state,
  getters,
  mutations,
  actions: {},
};
