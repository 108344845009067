export const state = () => ({
  globalParameters: [],
  fetchLoading: false,
});

export const getters = {
  globalParameters(state, { globalParameterLoading }) {
    return state.globalParameters.map(envVar => ({
      ...envVar,
      loading: globalParameterLoading(envVar.id),
    }));
  },
  globalParameterLoading(state) {
    return globalParameterId => state.loading[globalParameterId] || false;
  },
};

export const mutations = {
  setGlobalParameters(state, globalParameters) {
    state.globalParameters = globalParameters;
  },
  addGlobalParameter(state, globalParameter) {
    state.globalParameters = [...state.globalParameters, globalParameter];
  },
  removeGlobalParameter(state, globalParameterId) {
    state.globalParameters = state.globalParameters.filter(
      envVar => envVar.id !== globalParameterId,
    );
  },
  updateGlobalParameter(state, { globalParameterId, globalParameterData }) {
    state.globalParameters = state.globalParameters.map((envVar) => {
      if (envVar.id === globalParameterId) {
        return { ...envVar, ...globalParameterData };
      }

      return envVar;
    });
  },
  setFetchLoading(state, fetchLoading) {
    state.fetchLoading = fetchLoading;
  },
};

export const actions = {
  async fetchGlobalParameters({ commit, state }) {
    if (state.fetchLoading) return;

    commit('setFetchLoading', true);

    try {
      const globalParameters = await this.$apiClient.builder.globalParameters.list();
      commit('setGlobalParameters', globalParameters);
    } catch (error) {
      this.$errorRescue(this, error, 'fetchGlobalParameters', { onlyReturnErrorMsg: true });
    } finally {
      commit('setFetchLoading', false);
    }
  },
  async addGlobalParameter({ commit }, globalParameter) {
    const newGlobalParameter = await this.$apiClient.builder.globalParameters.create(globalParameter);
    commit('addGlobalParameter', newGlobalParameter);
  },
  async removeGlobalParameter({ commit }, globalParameterId) {
    await this.$apiClient.builder.globalParameters.delete(globalParameterId);
    commit('removeGlobalParameter', globalParameterId);
  },
  async updateGlobalParameter({ commit }, { id: globalParameterId, ...globalParameterData }) {
    await this.$apiClient.builder.globalParameters.update(globalParameterId, globalParameterData);
    commit('updateGlobalParameter', { globalParameterId, globalParameterData });
  },
};
