export const state = () => ({
  messages: [],
});

export const mutations = {
  addMessage(state, message) {
    state.messages.push(message);
  },
  removeMessage(state, id) {
    const index = state.messages.findIndex(message => message.id === id);
    const hasFoundMessage = index !== -1;

    if (hasFoundMessage) {
      state.messages.splice(index, 1);
    }
  },
};

export const actions = {
  showMessage({ commit }, message) {
    commit('addMessage', message);
  },
  hideMessage({ commit }, id) {
    commit('removeMessage', id);
  },
};
