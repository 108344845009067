<template>
  <v-app id="inspire">
    <StudioTopbar
      :transparent="false"
    />
    <v-main>
      <nuxt />
    </v-main>

    <NewVersionAlert />
    <Snackbar />
    <ThePaywall />
    <TheDialog />
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex';
import { workspace } from '~/mixins/workspace';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import NewVersionAlert from '~/components/NewVersionAlert';
import StudioTopbar from '~/components/topbars/studio-topbar';
import ThePaywall from '~/components/the-paywall';

export default {
  name: 'Studio',
  components: { TheDialog, Snackbar, NewVersionAlert, StudioTopbar, ThePaywall },
  mixins: [workspace({ builder: true })],
  middleware: ['auth', 'refresh-user', 'isadmin'],
  head() {
    return this.$buildHead();
  },
  mounted() {
    // https://github.com/vuetifyjs/vuetify/issues/14429#issuecomment-976936907
    this.$vuetify.application.left = 1;

    setTimeout(() => {
      this.$vuetify.application.left = 0;
    }, 200);

    this.setInStudio();
    this.setCurrentEnvironment('studio');
  },
  destroyed() {
    this.resetWorkspace();
  },
  methods: {
    ...mapMutations('environment', ['setCurrentEnvironment']),
    ...mapMutations('studio', ['setInStudio']),
    ...mapMutations('workspace', ['resetWorkspace']),
  },
};
</script>
<style lang="scss">
@supports not (height: 100dvh) {
 /**
  * All scrollable contents in the studio area are confined to specific elements,
  * so if `dvh` unit is not supported, it's presumably safe to disable scrolling
  * on the body and html elements to avoid mobile browsers address bar on top of
  * the page and having a double overflow.
  *
  * PSA: in `__generic.scss` is where we set the `html` height to `100dvh`
  */
  body, html {
    overflow: hidden;
  }
}

// TODO: Studio navigation sidebars styles should be confined in a common wrapper component used in this layout
.builder-navigation {
  background-color: white;
  overflow-y: hidden;
  height: 100%;
}

.builder-navigation--left {
  border-right: thin solid var(--z-main-background-contrast-color);
  border-top: thin solid var(--z-main-background-contrast-color);
}

.builder-navigation--right {
  border-left: thin solid var(--z-main-background-contrast-color);
  border-top: thin solid var(--z-main-background-contrast-color);
}

// TODO: These were written with the intention for inner studio components to
// use them, but should not have been done that way. Refactor when possible.
#builderArea {
  width: 100vw;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

#builderContent {
  background-color: var(--z-main-background-accent-color);
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  position: relative;
  border-top: thin solid rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
