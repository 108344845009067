const UNIT_CONVERSOR = {
  BYTE: 1,
  BYTES: 1,
  KB: 1024,
  MB: 1024 ** 2,
  GB: 1024 ** 3,
  TB: 1024 ** 4,
};

/**
  * Converts a string representation of a value with unit to bytes.
  *
  * @param {string} string - The string to convert.
  * @returns {number} The converted value in bytes.
  * @throws {Error} If the string format is invalid.
  *
  * @example
  * stringToBytes('1KB'); => 1024
  * stringToBytes('1MB'); => 1048576
  * stringToBytes('5GB'); => 5368709120
  * stringToBytes('1TB'); => 1099511627776
*/

export function stringToBytes(string) {
  const matches = string.match(/(\d+(?:\.\d+)?)\s*([a-zA-Z]+)s?/);

  if (!matches) throw new Error('Invalid string format');
  const [, value, unit] = matches;

  const finalValue = Number(value);
  const finalUnity = unit.toUpperCase().replace(/S$/, '');

  return parseFloat(finalValue) * UNIT_CONVERSOR[finalUnity];
}
