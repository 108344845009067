import { slugify } from '~/assets/javascript/utils/string/url';
import Fuse from 'fuse.js';
// https://www.fusejs.io/

const facetMatches = (facetValue, expectedFacetValue) => {
  if (Array.isArray(facetValue)) {
    return expectedFacetValue.some(expectedValue => facetValue.includes(expectedValue));
  }

  return expectedFacetValue.includes(facetValue);
};

const itemFacetsMatch = (item, expectedFacets) => Object
  .entries(expectedFacets)
  .every(([key, expectedValue]) => facetMatches(item[key], expectedValue));

const countFacets = ({
  facets,
  facetKeys,
  facetsOptions,
  searchTermItems,
}) => {
  const result = Object.entries(facets).reduce((acc, [facet, selectedFacets]) => {
    acc[facet] = selectedFacets.reduce((acc2, selectedFacet) => {
      acc2[selectedFacet] = 0;
      return acc2;
    }, {});

    return acc;
  }, {});

  searchTermItems.forEach((item) => {
    Object.entries(facetsOptions).forEach(([key, value]) => {
      value.forEach((internalValue) => {
        const facetsTest = { ...facets, [key]: [internalValue] };
        const matches = itemFacetsMatch(item, facetsTest);

        if (!matches) return;

        result[key] ||= {};
        result[key][internalValue] ||= 0;
        result[key][internalValue] += 1;
      });
    });
  });

  return Object
    .entries(result)
    .map(([key, value]) => ({
      key,
      value: Object.entries(value).map(([facet, count]) => ({ facet, count })).sort((a, b) => a.facet.localeCompare(b.facet)),
    }))
    .sort((a, b) => facetKeys.indexOf(a.key) - facetKeys.indexOf(b.key));
};

export class LocalSearchStrategy {
  constructor({
    facetKeys = [],
    items,
    searchableKeys = ['name', 'description'],
  }) {
    this.facetsOptions = facetKeys.reduce((acc, key) => {
      acc[key] = [...new Set(items.map(item => item[key]).flat())];
      return acc;
    }, {});

    this.items = items;
    this.facetKeys = facetKeys;
    this.searchableKeys = searchableKeys;

    this.fuse = new Fuse(items, {
      keys: searchableKeys,
      shouldSort: true,
      ignoreLocation: true,
    });
  }

  search(searchTerm, facets = {}) {
    const query = slugify((searchTerm || '').toLowerCase());

    const searchTermItems = query === '' ? this.items : this.fuse.search(query).map(({ item }) => item);

    const result = searchTermItems.filter(item => itemFacetsMatch(item, facets));

    const facetsCount = countFacets({
      facets,
      items: result,
      facetKeys: this.facetKeys,
      facetsOptions: this.facetsOptions,
      searchTermItems,
    });

    return {
      result,
      facets: facetsCount,
      count: result.length,
    };
  }
}
