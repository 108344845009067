import { isEmpty, isObject } from 'lodash';
import * as errors from './errors';

export default class BaseAction {
  constructor(context) {
    this.$zazosCable = context.$zazosCable;
    this.$store = context.$store;
    this.$waitUntil = context.$waitUntil;
    this.$route = context.$route;
    this.$router = context.$router;
    this.localePath = context.localePath;
    this.$apiClient = context.$apiClient;
    this.$rollbar = context.$rollbar;
    this.$i18n = context.$i18n;
  }

  get validate() { // centralizes validation methods
    return {
      requireValues: values => this.#requireValues(values),
      requireFilledValues: values => this.#requireFilledValues(values),
    };
  }

  get name() {
    return this.throwError('BaseMemberActionError', 'Getter "name" must be implemented in child class');
  }

  throwError = (errorClassName, ...args) => {
    const ErrorClass = errors[errorClassName];

    throw new ErrorClass(this, ...args);
  };

  execute = () => this.throwError('BaseMemberActionError', 'Method "execute" must be implemented in child class');

  // private

  #requireValues = (requiredValuesMapping) => {
    if (!isObject(requiredValuesMapping)) this.throwError('BaseMemberActionError', 'required filled values should be a mapping');

    Object.entries(requiredValuesMapping).forEach(([propName, value]) => {
      if (value === undefined) this.throwError('MissingRequiredValueError', propName);
    });
  };

  #requireFilledValues = (requiredValuesMapping) => {
    if (!isObject(requiredValuesMapping)) this.throwError('BaseMemberActionError', 'required filled values should be a mapping');

    Object.entries(requiredValuesMapping).forEach(([propName, value]) => {
      if (value === undefined) this.throwError('MissingRequiredValueError', propName);
      if (isEmpty(value)) this.throwError('EmptyValueError', propName);
    });
  };
}
