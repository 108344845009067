<i18n lang="yaml">
pt:
  apply: 'Candidate-se'
  login: 'Entrar'
  enter: 'Entrar'
en:
  apply: 'Apply'
  login: 'Login'
  enter: 'Enter'
</i18n>

<template>
  <TheTopbar class="public-topbar">
    <template #start-content>
      <deck-tabs
        :value="currentPageIndex"
        size="large"
        kind="fit"
      >
        <PublicTopbarPageButton
          v-for="(item, i) in pages"
          :key="i"
          :item="item"
          :active-color="primaryColor"
          :tab="i"
        />
      </deck-tabs>
    </template>

    <template #end-content>
      <deck-button
        is-ready
        :text="$auth.loggedIn ? $t('enter') : $t('login')"
        :kind="$auth.loggedIn ? 'secondary' : 'ghost'"
        :to="localePath({
          name: 't-tenantSlug-login',
          params: {
            tenantSlug: $route.params.tenantSlug,
          },
        })"
      />
      <deck-button
        v-if="showApplicationButton"
        is-ready
        :text="$t('apply')"
        :to="localePath({
          name: 't-tenantSlug-sign-up',
          params: {
            tenantSlug: $route.params.tenantSlug,
          },
        })"
      />
    </template>

    <template #menu-content>
      <deck-button
        is-ready
        :text="$auth.loggedIn ? $t('enter') : $t('login')"
        :kind="$auth.loggedIn ? 'secondary' : 'ghost'"
        :to="localePath({
          name: 't-tenantSlug-login',
          params: {
            tenantSlug: $route.params.tenantSlug,
          },
        })"
      />
      <deck-button
        v-if="showApplicationButton"
        is-ready
        :text="$t('apply')"
        :to="localePath({
          name: 't-tenantSlug-sign-up',
          params: {
            tenantSlug: $route.params.tenantSlug,
          },
        })"
      />
      <LinkList
        class="public-topbar__pages-menu"
        :selected-id="$route.params.viewId"
        :items="pages"
        nav
      />
    </template>
  </TheTopbar>
</template>

<script>
import TheTopbar from '~/components/topbars/the-topbar';
import { mapGetters, mapState } from 'vuex';
import DeckButton from '~/deck/button';
import LinkList from '~/components/LinkList';
import DeckTabs from '~/deck/tabs';
import ROUTES from '~/assets/javascript/constants/routes';
import PublicTopbarPageButton from './_page-button';

export default {
  name: 'PublicTopbar',
  components: {
    TheTopbar,
    PublicTopbarPageButton,
    DeckButton,
    DeckTabs,
    LinkList,
  },
  computed: {
    ...mapGetters('workspace', ['primaryColor', 'viewsOrder']),
    ...mapState('viewsMenu', ['views']),
    ...mapState('workspace', ['workspace']),
    pages() {
      if (this.views.length === 0 || this.viewsOrder.length === 0) return [];
      const generateItem = items => items.map((item) => {
        if (item.items) {
          const children = generateItem(item.items);

          if (!children) return null;

          return {
            id: `${item.group_name}-${item.items[0]?.id}`,
            title: item.group_name,
            active: items.some(i => i.active),
            items: children,
          };
        }

        const view = this.views.find(({ id }) => id === item.id);

        if (!view) return null;

        return {
          id: view.id,
          title: view.page_name,
          to: this.localePath({
            name: ROUTES.public.view.routeName,
            params: { tenantSlug: this.$route.params.tenantSlug, viewId: view.id },
          }),
          active: view.id === this.$route.params.viewId,
        };
      }).filter(Boolean);

      return generateItem(this.viewsOrder);
    },

    currentPageIndex() {
      const findRoute = item => this.$route.path?.includes(item.to) || (item.items?.length > 0 && item.items.some(findRoute));
      const index = this.pages.findIndex(findRoute);
      return index !== -1 ? index : 0;
    },

    showApplicationButton() {
      return !this.$auth.loggedIn && this.workspace.signup_enabled;
    },
  },
};
</script>

<style lang="scss">
.public-topbar .v-toolbar__content {
  margin-inline: auto;
  padding-inline: var(--z-the-landscape-inline-spacing); // v-app-bar override
  grid-template-columns: minmax(0, auto) minmax(0, auto) minmax(min-content, 1fr);
}

.public-topbar__pages-menu {
  margin-top: -8px; // compensate LinkList padding
  margin-inline: -8px; // compensate LinkList padding
  align-self: stretch;
}
</style>
