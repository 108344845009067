import { buildBaseInfoComponent } from './_base';

export const buildTextInfoComponent = ({
  content,
  id,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    content,
    type: 'text',
  };

  if (id) component.id = id;

  return component;
};
