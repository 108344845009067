// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/zazos-bolt.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".store-footer{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:calc(50% + 16px) 24px;background-size:100px;padding-bottom:100px;padding-top:100px}.store-footer__section{align-items:center;display:flex;flex-wrap:wrap;gap:48px;justify-content:space-around}@media(min-width:840px){.store-footer__section--brand{justify-content:space-between}}.store-footer__socials{display:flex;gap:48px}.store-footer__item{font-size:14px}.store-footer__item--text{font-weight:500;margin-bottom:0!important;text-align:center;width:100%}@media(min-width:840px){.store-footer__item--text{margin-right:auto;text-align:inherit;width:auto}}.store-footer__item--link{color:#606060!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
