var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',_vm._g({directives:[{name:"test-id",rawName:"v-test-id",value:('list-item-activator-item'),expression:"'list-item-activator-item'"}],staticClass:"list-item",class:{
    'list-item--draggable': _vm.draggable,
    'list-item--active': _vm.active
  },attrs:{"exact":_vm.exactPath,"exact-path":_vm.exactPath,"aria-label":_vm.item.title,"to":_vm.item.to || null,"disabled":_vm.disabled,"value":_vm.item.id || null},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},_vm.$listeners),[(_vm.draggable)?_c('deck-icon',{directives:[{name:"test-class",rawName:"v-test-class",value:('list-item-drag-icon'),expression:"'list-item-drag-icon'"}],staticClass:"list-item__drag-icon",attrs:{"name":"grip-dots-vertical"}}):_vm._e(),_vm._v(" "),_vm._t("item-content",null,{"item":_vm.item,"index":_vm.index}),_vm._v(" "),(!_vm.itemContentSlot)?_c('v-list-item-content',{staticClass:"list-item__content"},[(_vm.item.icon)?_c('deck-icon',{staticClass:"list-item__content-icon",attrs:{"color":"var(--z-color-label)","size":"small","fixed-width":"","name":_vm.item.icon,"disabled":_vm.disabled}}):_vm._e(),_vm._v(" "),_c('v-list-item-title',{class:{
        'grey--text': _vm.item.inactive,
        [_vm.itemTitleTypography]: true
      },attrs:{"title":_vm.item.title || _vm.item.text}},[_vm._v("\n      "+_vm._s(_vm.item.title || _vm.item.text)+"\n    ")])],1):_vm._e(),_vm._v(" "),(!_vm.hideOptionsMenu && !_vm.itemActionsSlot && _vm.item.actions && _vm.item.actions.length > 0)?_c('OptionsMenu',{attrs:{"show-menu-activator":_vm.fixedOptionsMenu || _vm.hover,"item":_vm.item,"left":_vm.left},on:{"menuActivatorClick":function($event){return _vm.$emit('menuActivatorClick')}},scopedSlots:_vm._u([{key:"prependItems",fn:function(){return [_vm._t("optionsMenuPrependItems")]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.activeActions.length > 0)?_c('div',{staticClass:"d-flex align-center g-1 ml-1"},[_vm._l((_vm.activeActions),function(activeAction){return [(activeAction.activeIcon)?_c('deck-chip',{key:activeAction.activeIcon,staticClass:"flex-none",attrs:{"icon":activeAction.activeIcon,"text":activeAction.activeIconTooltip,"color":activeAction.activeIconColor,"tooltip-props":{
          disabled: !activeAction.activeIconTooltip,
          kind: activeAction.activeIconTooltipKind,
        },"size":"x-small"}}):_vm._e()]})],2):_vm._e(),_vm._v(" "),_vm._t("item-actions",null,{"item":_vm.item,"index":_vm.index})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }