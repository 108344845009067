import VuexPersist from 'vuex-persist';
import { get, set } from 'lodash';

export const LOCAL_STORAGE_KEY = 'vuexPersistV2';

const cacheableKeys = (storeName) => {
  // eslint-disable-next-line import/no-dynamic-require
  const { cacheableStateKeys } = require(`~/store/${storeName}`);

  if (!cacheableStateKeys) return [];

  return cacheableStateKeys.map(key => `${storeName}.${key}`);
};

const storesToPersist = [
  'import',
  'view',
  'viewOptions',
  'viewsMenu',
  'workspace',
  'apps',
];

const SAFELIST = storesToPersist.map(storeName => cacheableKeys(storeName)).flat();

export default ({ store }) => {
  new VuexPersist({
    key: LOCAL_STORAGE_KEY, // The key to store the state on in the storage provider.
    modules: storesToPersist,
    storage: {
      ...window.localStorage,
      // Keep this function defined to dont break vue persistence getItem
      getItem(key) {
        return window.localStorage.getItem(key);
      },
      setItem(key, value) {
        try {
          const parsedValue = JSON.parse(value);
          const finalValue = SAFELIST.reduce((state, path) => {
            set(state, path, get(parsedValue, path));
            return state;
          }, {});

          window.localStorage.setItem(key, JSON.stringify(finalValue));
        } catch {
          window.localStorage.removeItem(key);
        }
      },
    }, // or window.sessionStorage or localForage
  }).plugin(store);
};
