import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _70fe6060 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6916f02a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _7949518f = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _aa46e1e4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))

const _1c69e430 = () => interopDefault(import('../pages/set-workspace.vue' /* webpackChunkName: "pages/set-workspace" */))
const _1b41d96c = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _768ef8bc = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _7cf394da = () => interopDefault(import('../pages/store/apps/_appName.vue' /* webpackChunkName: "pages/store/apps/_appName" */))
const _6e51e48c = () => interopDefault(import('../pages/t/_tenantSlug/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/index" */))
const _5c1a4095 = () => interopDefault(import('../pages/t/_tenantSlug/email-validation/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/email-validation/index" */))
const _6f04d2d8 = () => interopDefault(import('../pages/t/_tenantSlug/login/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/login/index" */))
const _26354ad0 = () => interopDefault(import('../pages/t/_tenantSlug/m/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/index" */))
const _a8d91a4a = () => interopDefault(import('../pages/t/_tenantSlug/p/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/index" */))
const _6e37d621 = () => interopDefault(import('../pages/t/_tenantSlug/reset-password.vue' /* webpackChunkName: "pages/t/_tenantSlug/reset-password" */))
const _3b988d56 = () => interopDefault(import('../pages/t/_tenantSlug/sign-up/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sign-up/index" */))
const _77337821 = () => interopDefault(import('../pages/t/_tenantSlug/studio/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/index" */))
const _3e85d11c = () => interopDefault(import('../pages/t/_tenantSlug/m/account-configuration.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/account-configuration" */))
const _50ef95f8 = () => interopDefault(import('../pages/t/_tenantSlug/sso/saml/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sso/saml/index" */))
const _77456eee = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/index" */))
const _c4cd3fb8 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings" */))
const _213e3ba7 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/index" */))
const _3e64ca07 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/app-builder/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/app-builder/index" */))
const _4c0db8b6 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/authentication/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/authentication/index" */))
const _2b427b68 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/global-parameters-manager/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/global-parameters-manager/index" */))
const _d7f9b946 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/index" */))
const _f247be0a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/index" */))
const _a3cfed92 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/system-entities/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/system-entities/index" */))
const _b919a750 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/index" */))
const _c8bb1124 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/index" */))
const _6a1202a0 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/index" */))
const _cb4d5b88 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/new" */))
const _b4674df6 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/_id" */))
const _f2b90554 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_id" */))
const _2eca9cac = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index" */))
const _36d0c460 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index" */))
const _1f8354de = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_active-members-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_active-members-card/index" */))
const _7acf1793 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index" */))
const _3ee26376 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id" */))
const _e7900d0e = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/index" */))
const _61fb7c7b = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/history.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/history" */))
const _425d3a4e = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/index" */))
const _5f9efb84 = () => interopDefault(import('../pages/t/_tenantSlug/studio/pages/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/pages/_id" */))
const _501f992e = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/import.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/import" */))
const _36c04b70 = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/index" */))
const _4d9eff8b = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/index" */))
const _c8e4210e = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/new" */))
const _a033a472 = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/_recordId/index" */))
const _22bef2ec = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/r/_recordId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _70fe6060,
    name: "index___en"
  }, {
    path: "/login",
    component: _6916f02a,
    name: "login___pt"
  }, {
    path: "/magic-link",
    component: _7949518f,
    name: "magic-link___pt"
  }, {
    path: "/reset-password",
    component: _aa46e1e4,
    name: "reset-password___pt"
  }, {
    path: "/reset-password",
    redirect: {"name":"set-tenant___pt"}
  }, {
    path: "/set-workspace",
    component: _1c69e430,
    name: "set-workspace___pt"
  }, {
    path: "/sign-up",
    component: _1b41d96c,
    name: "sign-up___pt"
  }, {
    path: "/store",
    component: _768ef8bc,
    name: "store___pt"
  }, {
    path: "/builder/views",
    redirect: {"name":"studio-pages-id___pt"}
  }, {
    path: "/en/login",
    component: _6916f02a,
    name: "login___en"
  }, {
    path: "/en/magic-link",
    component: _7949518f,
    name: "magic-link___en"
  }, {
    path: "/en/reset-password",
    component: _aa46e1e4,
    name: "reset-password___en"
  }, {
    path: "/en/reset-password",
    redirect: {"name":"set-tenant___en"}
  }, {
    path: "/en/set-workspace",
    component: _1c69e430,
    name: "set-workspace___en"
  }, {
    path: "/en/sign-up",
    component: _1b41d96c,
    name: "sign-up___en"
  }, {
    path: "/en/store",
    component: _768ef8bc,
    name: "store___en"
  }, {
    path: "/en/builder/views",
    redirect: {"name":"studio-pages-id___en"}
  }, {
    path: "/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___pt"}
  }, {
    path: "/en/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___en"}
  }, {
    path: "/",
    component: _70fe6060,
    name: "index___pt"
  }, {
    path: "/en/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___en"}
  }, {
    path: "/en/store/apps/:appName?",
    component: _7cf394da,
    name: "store-apps-appName___en"
  }, {
    path: "/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___pt"}
  }, {
    path: "/en/t/:tenantSlug",
    component: _6e51e48c,
    name: "t-tenantSlug___en"
  }, {
    path: "/store/apps/:appName?",
    component: _7cf394da,
    name: "store-apps-appName___pt"
  }, {
    path: "/en/t/:tenantSlug?/email-validation",
    component: _5c1a4095,
    name: "t-tenantSlug-email-validation___en"
  }, {
    path: "/en/t/:tenantSlug?/login",
    component: _6f04d2d8,
    name: "t-tenantSlug-login___en"
  }, {
    path: "/en/t/:tenantSlug?/m",
    component: _26354ad0,
    name: "t-tenantSlug-m___en"
  }, {
    path: "/en/t/:tenantSlug?/p",
    component: _a8d91a4a,
    name: "t-tenantSlug-p___en"
  }, {
    path: "/en/t/:tenantSlug?/reset-password",
    component: _6e37d621,
    name: "t-tenantSlug-reset-password___en"
  }, {
    path: "/en/t/:tenantSlug?/sign-up",
    component: _3b988d56,
    name: "t-tenantSlug-sign-up___en"
  }, {
    path: "/en/t/:tenantSlug?/studio",
    component: _77337821,
    name: "t-tenantSlug-studio___en"
  }, {
    path: "/en/t/:tenantSlug?/m/account-configuration",
    component: _3e85d11c,
    name: "t-tenantSlug-m-account-configuration___en"
  }, {
    path: "/en/t/:tenantSlug?/sso/saml",
    component: _50ef95f8,
    name: "t-tenantSlug-sso-saml___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data",
    component: _77456eee,
    name: "t-tenantSlug-studio-data___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/settings",
    component: _c4cd3fb8,
    children: [{
      path: "",
      component: _213e3ba7,
      name: "t-tenantSlug-studio-settings___en"
    }, {
      path: "app-builder",
      component: _3e64ca07,
      name: "t-tenantSlug-studio-settings-app-builder___en"
    }, {
      path: "authentication",
      component: _4c0db8b6,
      name: "t-tenantSlug-studio-settings-authentication___en"
    }, {
      path: "global-parameters-manager",
      component: _2b427b68,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___en"
    }, {
      path: "integrations",
      component: _d7f9b946,
      name: "t-tenantSlug-studio-settings-integrations___en"
    }, {
      path: "internal",
      component: _f247be0a,
      name: "t-tenantSlug-studio-settings-internal___en"
    }, {
      path: "system-entities",
      component: _a3cfed92,
      name: "t-tenantSlug-studio-settings-system-entities___en"
    }, {
      path: "tokens",
      component: _b919a750,
      name: "t-tenantSlug-studio-settings-tokens___en"
    }, {
      path: "user-groups",
      component: _c8bb1124,
      name: "t-tenantSlug-studio-settings-user-groups___en"
    }, {
      path: "users",
      component: _6a1202a0,
      name: "t-tenantSlug-studio-settings-users___en"
    }, {
      path: "user-groups/new",
      component: _cb4d5b88,
      name: "t-tenantSlug-studio-settings-user-groups-new___en"
    }, {
      path: "integrations/:id",
      component: _b4674df6,
      name: "t-tenantSlug-studio-settings-integrations-id___en"
    }, {
      path: "user-groups/:id?",
      component: _f2b90554,
      name: "t-tenantSlug-studio-settings-user-groups-id___en"
    }, {
      path: "user-groups/:user-group-form",
      component: _2eca9cac,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___en"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _36d0c460,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___en"
    }, {
      path: "users/:active-members-card",
      component: _1f8354de,
      name: "t-tenantSlug-studio-settings-users-active-members-card___en"
    }, {
      path: "users/:users-data-table-card",
      component: _7acf1793,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/automations/:id?",
    component: _3ee26376,
    children: [{
      path: "",
      component: _e7900d0e,
      name: "t-tenantSlug-studio-automations-id___en"
    }, {
      path: "history",
      component: _61fb7c7b,
      name: "t-tenantSlug-studio-automations-id-history___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id",
    component: _425d3a4e,
    name: "t-tenantSlug-studio-data-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/pages/:id?",
    component: _5f9efb84,
    name: "t-tenantSlug-studio-pages-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id/import",
    component: _501f992e,
    name: "t-tenantSlug-studio-data-id-import___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId",
    component: _36c04b70,
    name: "t-tenantSlug-m-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId",
    component: _4d9eff8b,
    name: "t-tenantSlug-p-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/new",
    component: _c8e4210e,
    name: "t-tenantSlug-m-viewId-r-new___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _a033a472,
    name: "t-tenantSlug-m-viewId-r-recordId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _22bef2ec,
    name: "t-tenantSlug-p-viewId-r-recordId___en"
  }, {
    path: "/t/:tenantSlug",
    component: _6e51e48c,
    name: "t-tenantSlug___pt"
  }, {
    path: "/t/:tenantSlug?/email-validation",
    component: _5c1a4095,
    name: "t-tenantSlug-email-validation___pt"
  }, {
    path: "/t/:tenantSlug?/login",
    component: _6f04d2d8,
    name: "t-tenantSlug-login___pt"
  }, {
    path: "/t/:tenantSlug?/m",
    component: _26354ad0,
    name: "t-tenantSlug-m___pt"
  }, {
    path: "/t/:tenantSlug?/p",
    component: _a8d91a4a,
    name: "t-tenantSlug-p___pt"
  }, {
    path: "/t/:tenantSlug?/reset-password",
    component: _6e37d621,
    name: "t-tenantSlug-reset-password___pt"
  }, {
    path: "/t/:tenantSlug?/sign-up",
    component: _3b988d56,
    name: "t-tenantSlug-sign-up___pt"
  }, {
    path: "/t/:tenantSlug?/studio",
    component: _77337821,
    name: "t-tenantSlug-studio___pt"
  }, {
    path: "/t/:tenantSlug?/m/account-configuration",
    component: _3e85d11c,
    name: "t-tenantSlug-m-account-configuration___pt"
  }, {
    path: "/t/:tenantSlug?/sso/saml",
    component: _50ef95f8,
    name: "t-tenantSlug-sso-saml___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data",
    component: _77456eee,
    name: "t-tenantSlug-studio-data___pt"
  }, {
    path: "/t/:tenantSlug?/studio/settings",
    component: _c4cd3fb8,
    children: [{
      path: "",
      component: _213e3ba7,
      name: "t-tenantSlug-studio-settings___pt"
    }, {
      path: "app-builder",
      component: _3e64ca07,
      name: "t-tenantSlug-studio-settings-app-builder___pt"
    }, {
      path: "authentication",
      component: _4c0db8b6,
      name: "t-tenantSlug-studio-settings-authentication___pt"
    }, {
      path: "global-parameters-manager",
      component: _2b427b68,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___pt"
    }, {
      path: "integrations",
      component: _d7f9b946,
      name: "t-tenantSlug-studio-settings-integrations___pt"
    }, {
      path: "internal",
      component: _f247be0a,
      name: "t-tenantSlug-studio-settings-internal___pt"
    }, {
      path: "system-entities",
      component: _a3cfed92,
      name: "t-tenantSlug-studio-settings-system-entities___pt"
    }, {
      path: "tokens",
      component: _b919a750,
      name: "t-tenantSlug-studio-settings-tokens___pt"
    }, {
      path: "user-groups",
      component: _c8bb1124,
      name: "t-tenantSlug-studio-settings-user-groups___pt"
    }, {
      path: "users",
      component: _6a1202a0,
      name: "t-tenantSlug-studio-settings-users___pt"
    }, {
      path: "user-groups/new",
      component: _cb4d5b88,
      name: "t-tenantSlug-studio-settings-user-groups-new___pt"
    }, {
      path: "integrations/:id",
      component: _b4674df6,
      name: "t-tenantSlug-studio-settings-integrations-id___pt"
    }, {
      path: "user-groups/:id?",
      component: _f2b90554,
      name: "t-tenantSlug-studio-settings-user-groups-id___pt"
    }, {
      path: "user-groups/:user-group-form",
      component: _2eca9cac,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___pt"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _36d0c460,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___pt"
    }, {
      path: "users/:active-members-card",
      component: _1f8354de,
      name: "t-tenantSlug-studio-settings-users-active-members-card___pt"
    }, {
      path: "users/:users-data-table-card",
      component: _7acf1793,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/automations/:id?",
    component: _3ee26376,
    children: [{
      path: "",
      component: _e7900d0e,
      name: "t-tenantSlug-studio-automations-id___pt"
    }, {
      path: "history",
      component: _61fb7c7b,
      name: "t-tenantSlug-studio-automations-id-history___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/data/:id",
    component: _425d3a4e,
    name: "t-tenantSlug-studio-data-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/pages/:id?",
    component: _5f9efb84,
    name: "t-tenantSlug-studio-pages-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data/:id/import",
    component: _501f992e,
    name: "t-tenantSlug-studio-data-id-import___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId",
    component: _36c04b70,
    name: "t-tenantSlug-m-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId",
    component: _4d9eff8b,
    name: "t-tenantSlug-p-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/new",
    component: _c8e4210e,
    name: "t-tenantSlug-m-viewId-r-new___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _a033a472,
    name: "t-tenantSlug-m-viewId-r-recordId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _22bef2ec,
    name: "t-tenantSlug-p-viewId-r-recordId___pt"
  }, {
    path: "/:locale(en)?/builder/:after(.*)",
    redirect: (to) => ({ path: `${to.params.locale}/studio/${to.params.after}` })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
