import { merge, truncate } from 'lodash';

/**
 * @typedef {Object} HeadOptions
 * @property {(string|{value: string, truncate?: boolean})} [title] - The title of the page. If an object is provided, the `value` property is used as the title and the `truncate` property is used to set an SEO-friendly text limit.
 * @property {(string|{value: string, truncate?: boolean})} [description] - The description of the page. If an object is provided, the `value` property is used as the description and the `truncate` property is used to set an SEO-friendly text limit.
 * @property {string[]} [keywords] - An array of keywords for the page.
 * @property {Object} [imageInfo] - An object containing information about the image to use for social sharing.
 * @property {string} [imageInfo.url] - The URL of the image. This must be an absolute URL.
 * @property {string} [imageInfo.type] - The MIME type of the image.
 * @property {string} [imageInfo.alt] - The alternative text for the image.
 * @property {number} [imageInfo.width] - The width of the image in pixels.
 * @property {number} [imageInfo.height] - The height of the image in pixels.
 * @property {string} [robots] - The value of the `robots` meta tag.
 * @property {string} [url] - The canonical URL of the page for SEO purposes.
 * @property {string} [titleTemplate] - The template for the page title in case you want to override the default one.
 * @property {string} [googlebot] - The value of the `googlebot` meta tag.
 * @property {Object.<string, *>} [other] - Additional properties to include or override in the head (created using the rest operator).
 * @see {@link https://vue-meta.nuxtjs.org/api/#metainfo-properties|vue-meta documentation}
 */

const MAX_TITLE_LENGTH = 60;
const MAX_DESCRIPTION_LENGTH = 155;

/**
 * Truncates the text to a specified length and adds an ellipsis to the end.
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @returns {string} The truncated text with ellipsis.
 */
function truncateText(text, maxLength) {
  return truncate(text, { length: maxLength, omission: '...' });
}

/**
 * Injects a `buildHead` function into the Nuxt context that generates the head object for the current page.
 * @param {Object} context - The Nuxt context object.
 * @param {Function} inject - The inject function provided by Nuxt.
 * @returns {void}
 */
export default (context, inject) => {
  /**
   * Generates the head object for the current page.
   * @this VueComponent
   * @param {HeadOptions} options - The options for generating the head object.
   * @returns {Object} The head object for the current page.
   */
  function buildHead(options = {}) {
    /**
     * Generates the head object for the current page or layout. Remember this is not available in for components.
     * @param {HeadOptions} options - The options for generating the head object.
     * @returns {Object} The head object for the current page or layout.
     */
    const workspaceLogo = context.store.state.workspace.workspace.logo_image?.download_url?.logo;
    const workspaceName = context.store.state.workspace.workspace.name;
    const titlePrepend = workspaceName ? ` - ${workspaceName} ` : ' ';
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    const {
      title,
      description,
      keywords,
      imageInfo = {
        url: workspaceLogo,
        type: 'avif', // Default type of our hosted Workspace logos
        alt: workspaceName,
        width: null,
        height: null,
      },
      robots,
      url,
      titleTemplate = `%s${titlePrepend}⚡️Zazos`,
      googlebot = robots,
      ...rest
    } = options;

    const head = {
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      link: [
        ...i18nHead.link,
      ],
      titleTemplate,
      meta: [
        ...i18nHead.meta,
      ],
    };

    if (title) {
      const titleValue = title.truncate ? truncateText(title.value, MAX_TITLE_LENGTH) : title.value || title;

      head.title = titleValue;
      head.meta.push({ hid: 'og:title', property: 'og:title', content: titleValue });
    }

    if (description) {
      const descriptionValue = description.truncate ? truncateText(description.value, MAX_DESCRIPTION_LENGTH) : description.value || description;

      head.meta.push({ hid: 'og:description', property: 'og:description', name: 'og:description', content: descriptionValue });
      head.meta.push({ hid: 'description', property: 'description', name: 'description', content: descriptionValue });
    }

    if (keywords) {
      head.meta.push({ hid: 'keywords', property: 'keywords', name: 'keywords', content: keywords });
    }

    if (robots) {
      head.meta.push({ hid: 'robots', name: 'robots', content: robots });
    }

    if (googlebot) {
      head.meta.push({ hid: 'googlebot', name: 'googlebot', content: googlebot });
    }

    if (imageInfo.url) {
      head.meta.push({ hid: 'og:image', property: 'og:image', content: imageInfo.url });
    }

    if (imageInfo.type) {
      head.meta.push({ hid: 'og:image:type', property: 'og:image:type', content: `image/${imageInfo.type}` });
    }

    if (imageInfo.width) {
      head.meta.push({ hid: 'og:image:width', property: 'og:image:width', content: imageInfo.width.toString() });
    }

    if (imageInfo.height) {
      head.meta.push({ hid: 'og:image:height', property: 'og:image:height', content: imageInfo.height.toString() });
    }

    if (url) {
      head.meta.push({ hid: 'og:url', property: 'og:url', content: url });
    }

    // Merge any additional head properties. Note that `rest` will also override
    // values from existing properties if they share the same key.
    merge(head, rest);

    return head;
  }

  inject('buildHead', buildHead);
};
