import { calcAPCA } from 'apca-w3';

export const TRANSLUCENT_WHITE = '#FFFFFFD9'; // TODO: Use tokens when available
export const TRANSLUCENT_BLACK = '#000000A3'; // TODO: Use tokens when available

export const getAccessibleColor = (backgroundColorHex) => {
  const whiteContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_WHITE, backgroundColorHex)).toFixed(1));
  const blackContrastDifference = parseFloat(Math.abs(calcAPCA(TRANSLUCENT_BLACK, backgroundColorHex)).toFixed(1));

  return whiteContrastDifference >= blackContrastDifference ? TRANSLUCENT_WHITE : TRANSLUCENT_BLACK;
};
