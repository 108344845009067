import { sanitizeFilename } from '~/assets/javascript/utils/string';

export function openDownloadCsvDialog(name, content) {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = sanitizeFilename(name, 'csv');
  link.click();
}

export function csvDownload(name, content) {
  const Papa = require('papaparse');
  const csv = Papa.unparse(content);
  openDownloadCsvDialog(name, csv);
}
