const minLength = {
  condition: (password, _email) => {
    if (!password) return false;

    return (password?.length || 0) >= 10;
  },
  errorMessageKey: 'password.minLength',
};

const differentCase = {
  condition: (password, _email) => {
    const hasLowerCase = (/[a-z]/).test(password);
    const hasUpperCase = (/[A-Z]/).test(password);
    return hasLowerCase && hasUpperCase;
  },
  errorMessageKey: 'password.differentCase',
};

const numeric = {
  condition: (password, _email) => (/[0-9]/).test(password),
  errorMessageKey: 'password.numeric',
};

const special = {
  condition: (password, _email) => (/[^A-Za-z0-9]/).test(password),
  errorMessageKey: 'password.special',
};

const noUsername = {
  condition: (password, email) => {
    if (!email) return true;

    const username = email.split('@')[0].toLowerCase();
    const lowerCasePassword = password?.toLowerCase();

    return !lowerCasePassword?.includes(username);
  },
  errorMessageKey: 'password.noUsername',
};

export const passwordRules = [
  minLength,
  differentCase,
  numeric,
  special,
  noUsername,
];

const emailFormat = {
  condition: (email) => {
    if (!email) return false;

    return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(email);
  },
  errorMessageKey: 'email.invalid',
};

export const emailRules = [
  emailFormat,
];
