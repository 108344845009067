import { getFieldDisplayName, getFieldOutputType } from '~/assets/javascript/utils/table/field';
import { floatifyNumber, integerifyNumberFromString } from '~/assets/javascript/utils/number';
import { calculateFieldNameWithGroup } from '~/assets/javascript/utils/fields';
import { DECIMAL_SEPARATOR_MAPPING } from '~/assets/javascript/constants';
import { isEmpty } from 'lodash';

// vega-lite data example:
// {
//   "data": {
//     "values": [
//       {"a": "A","b": 28}, {"a": "B","b": 55}, {"a": "C","b": 43},
//       {"a": "D","b": 91}, {"a": "E","b": 81}, {"a": "F","b": 53},
//       {"a": "G","b": 19}, {"a": "H","b": 87}, {"a": "I","b": 52}
//     ],
//     "format": {
//       "parse": { ... }
//     }
//   }
// }

// Records data example:
// {
//   '123': 'A',
//   '456': 28,
// }
// Fields data example:
// [{
//   id: '123',
//   name: 'a',
//   type: 'Text',
// }, {
//   id: '456',
//   name: 'b',
//   type: 'Number',
// }]

export const generateVegaDataFromRecords = (records, fields, view, locale) => {
  const vegaData = { data: { values: [], format: { parse: {} } } };

  fields.forEach((field) => {
    if (getFieldOutputType(field) !== 'Date') return;

    const fieldFormatOption = view.fields_format_options[field.id] || '%d/%m/%Y';

    if (!fieldFormatOption) return;

    const fieldName = calculateFieldNameWithGroup(field);
    vegaData.data.format.parse[fieldName] = `utc:${fieldFormatOption}`;
  });

  records.forEach((record) => {
    const vegaValue = {
      id: record.id,
    };

    fields.forEach((field) => {
      const fieldName = calculateFieldNameWithGroup(field);

      if (field.type === 'Number') {
        vegaValue[fieldName] = floatifyNumber(record[field.id]?.value || 0, field.options.number_options.precision);
      } else if (field.type === 'Lookup' && getFieldOutputType(field) === 'Number') {
        const displayNames = getFieldDisplayName(record[field.id], field) || [];
        const { precision } = field.options.lookup_field_options.number_options;

        vegaValue[fieldName] = displayNames.map((displayName) => {
          const numberAsInteger = integerifyNumberFromString(displayName, DECIMAL_SEPARATOR_MAPPING[locale], precision);
          return floatifyNumber(numberAsInteger, precision);
        });
      } else if (['Select', 'MultipleSelect'].includes(field.type) && !isEmpty(field.options.select_internal_value)) {
        vegaValue[fieldName] = record[field.id]?.map(selection => selection.select_option_value) || [];
      } else {
        vegaValue[fieldName] = getFieldDisplayName(record[field.id], field) || '';
      }
    });

    vegaData.data.values.push(vegaValue);
  });

  return vegaData;
};

// vega-lite config example:
// {
//   "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
//   "mark": "bar",
//   "encoding": {
//     "x": {"field": "a", "type": "nominal"},
//     "y": {"aggregate": "average", "field": "b", "type": "quantitative"}
//   }
// }

export const generateReplaceMapFromFieldIdsToNames = fields => fields.reduce((acc, field) => {
  acc[`"field":"${field.id}"`] = `"field":"${calculateFieldNameWithGroup(field)}"`;
  acc[`datum.${field.id}`] = `datum.${calculateFieldNameWithGroup(field)}`;
  acc[`datum["${field.id}"]`] = `datum["${calculateFieldNameWithGroup(field)}"]`;
  acc[`datum['${field.id}']`] = `datum['${calculateFieldNameWithGroup(field)}']`;
  return acc;
}, {});
