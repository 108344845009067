import LoginCallback from '../callback';

export class Module {
  constructor(nuxt) {
    this.$vueApp = nuxt.app;
    this.$api = nuxt.$api;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.router = nuxt.app.router;
    this.callbackHandler = new LoginCallback(nuxt);
    this.$errorRescue = nuxt.$errorRescue;
    this.localePath = nuxt.app.localePath;
  }

  get route() {
    return this.router.currentRoute;
  }

  get tenantSlug() {
    return this.route.params.tenantSlug;
  }

  async validateProvider({ provider, tenantSlug = this.tenantSlug, redirectTo }) {
    return this.$recaptcha.fetchToken(async (token) => {
      const url = await this.$api.$get(`authentication/saml/${tenantSlug}/validate`, {
        params: {
          provider,
          recaptcha_token: token,
          redirect_to: this.localePath(
            this.callbackHandler.redirectRoute({ redirectTo, withQuery: true }),
          ),
        },
      });

      window.location = url;
    });
  }

  async loginWithProvider({ provider, tenantSlug = this.tenantSlug, redirectTo }) {
    return this.$recaptcha.fetchToken(async (token) => {
      const url = await this.$api.$get(`authentication/saml/${tenantSlug}`, {
        params: {
          provider,
          recaptcha_token: token,
          redirect_to: this.localePath(
            this.callbackHandler.redirectRoute({ redirectTo, withQuery: true }),
          ),
        },
      });

      window.location = url;
    });
  }

  async handleCallback({ token, redirectTo, errors }) {
    if (token) {
      const { token: exchangedToken } = await this.$api.$post(`authentication/saml/${this.tenantSlug}/exchange_auth_code`, { token });
      await this.$auth.setUserToken(exchangedToken);
      await this.$auth.fetchUserOnce();
      delete this.route.query.token;
    } else {
      this.$errorRescue(
        this.$vueApp,
        {
          response: {
            data: {
              errors: errors || ['credentials.invalid_credentials'],
            },
          },
        },
        'invalidToken',
        { silenceRollbar: true },
      );
    }

    return this.callbackHandler.handle({ withQuery: true, redirectTo });
  }
}

export const SetupSaml = (nuxt) => {
  nuxt.$auth.samlModule = new Module(nuxt);
};
