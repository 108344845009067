import LoginCallback from '../callback';

export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.router = nuxt.app.router;
    this.callbackHandler = new LoginCallback(nuxt);
  }

  get route() {
    return this.router.currentRoute;
  }

  async login({ token, withCallback = true }) {
    await this.$auth.loginWith('magicLink', { data: { token } });

    delete this.route.query.token;

    if (withCallback) {
      return this.callbackHandler.handle({ withQuery: true });
    }

    return null;
  }

  async send({ email }) {
    await this.$recaptcha.fetchToken(async token => this.$api
      .post('authentication/magic_link/send', {
        email,
        tenant_slug: this.route.params.tenantSlug,
        recaptcha_token: token,
      }));
  }
}

export function SetupMagicLink(nuxt) {
  nuxt.$auth.magicLinkModule = new Module(nuxt);
}
