import { buildBaseInfoComponent } from './_base';

export const buildImageInfoComponent = ({
  blobUrl,
  coverImageId,
  filename,
  id,
  mimeType,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    cover_image_id: coverImageId,
    filename,
    mime_type: mimeType,
    blob_url: blobUrl,
    type: 'image',
  };

  if (id) component.id = id;

  return component;
};

export const buildImageInfoComponentFromImage = image => buildImageInfoComponent({
  blobUrl: image.blob_url,
  coverImageId: image.cover_image_id,
  filename: image.filename,
  mimeType: image.mime_type,
});
