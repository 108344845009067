import { calculateFieldIdMapping } from '~/assets/javascript/utils';

export const state = () => ({
  fields: [],
});

export const mutations = {
  setFields(state, fields) {
    state.fields = fields;
  },
};

export const actions = {};

export const getters = {
  fieldIdMapping({ fields }, _getters, rootState) {
    return calculateFieldIdMapping(fields, rootState['views/locale']);
  },
};
