<i18n lang="yaml">
pt:
  # Template interpolation plurals workaroud https://github.com/kazupon/vue-i18n/issues/471#issuecomment-878303520
  remainingTime: 'Seu {trialPeriod} encerrará {countPrefix} {count}. Contrate um plano.'
  countPrefix: 'em'
  days: 'hoje | 1 dia | {n} dias'
  trialPeriod: 'período de teste'
  hide: 'Esconder'
  contactUs: 'Falar conosco'
en:
  remainingTime: 'Your {trialPeriod} will end {countPrefix} {count}. Purchase a plan.'
  countPrefix: 'in'
  days: 'today | 1 day | {n} days'
  trialPeriod: 'trial period'
  hide: 'Hide'
  contactUs: 'Contact us'
</i18n>

<template>
  <div
    v-test-id="'the-paywall-bar'"
    class="the-paywall-bar elevation-1 py-2"
    :class="{
      'the-paywall-bar--hide': isHidden,
    }"
  >
    <div class="the-paywall-bar__main-content">
      <i18n tag="p" path="remainingTime" class="mb-0">
        <template #trialPeriod>
          <strong>{{ $t('trialPeriod') }}</strong>
        </template>
        <template #countPrefix>
          {{ workspace.trial_remaining_days > 0 ? $t('countPrefix') : '' }}
        </template>
        <template #count>
          <strong>{{ $tc('days', workspace.trial_remaining_days, { n: workspace.trial_remaining_days }) }}</strong>
        </template>
      </i18n>

      <v-btn
        class="the-paywall-bar__cta"
        color="#FD8C41"
        :elevation="0"
        @click="onCtaClick"
      >
        {{ $t('contactUs') }}
      </v-btn>
    </div>

    <v-icon
      class="the-paywall-bar__close-icon"
      :aria-label="$t('hide')"
      @click="hideBar"
    >
      fa-xmark fa-regular
    </v-icon>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ThePaywallBar',
  data() {
    return {
      isHidden: true,
    };
  },
  computed: {
    ...mapState('workspace', ['workspace']),
  },
  mounted() {
    setTimeout(() => {
      this.isHidden = JSON.parse(sessionStorage.getItem('hidePaywallBar') || 'false');
    }, 2000);
  },
  methods: {
    hideBar() {
      this.isHidden = true;
      sessionStorage.setItem('hidePaywallBar', 'true');
    },
    onCtaClick() {
      this.$emit('ctaClick');
      this.hideBar();
    },
  },
};
</script>

<style lang="scss">
.the-paywall-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 56px;
  width: calc(100% - 16px);
  max-width: 1200px;
  margin: 8px auto;
  border-radius: 8px;
  padding-inline: 16px;
  background-color: #003255;
  color: white;
  transition: transform 0.3s ease-in-out;
  z-index: 201;
}

.the-paywall-bar--hide {
  transform: translateY(calc(100% + 32px));
}

// this class is also used by intercom automation:
// https://app.intercom.com/a/apps/ci6rseap/operator/custom-bots/button-custom-bot/35203259
.the-paywall-bar__main-content {
  display: flex;
  align-items: center;
}

.the-paywall-bar__cta {
  margin-left: 16px;
  color: white !important;

  ::after {
    background-image: url("~/static/zazos-bolt.svg");
    background-size: contain;
    content: "";
    height: 40px;
    left: calc(100% + 32px);
    position: absolute;
    bottom: 8px;
    width: 96px;
    pointer-events: none;
  }
}

.the-paywall-bar__close-icon {
  position: absolute !important; // v-icon override
  right: 16px;
  color: white !important; // v-icon override
}
</style>
