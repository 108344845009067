import { MIME_TYPE_ICON_MAPPING } from '~/assets/javascript/constants';

export const fileIcon = mimeType => MIME_TYPE_ICON_MAPPING[mimeType?.split('/')?.[0]] || MIME_TYPE_ICON_MAPPING.default;

export const userIcon = (user) => {
  if (user.already_in_use) return 'fa-triangle-exclamation fa-regular';
  if (!user.can_log_in) return 'fa-user-lock fa-regular';
  if (user.role === 'admin') return 'fa-user-gear fa-regular';
  if (user.role === 'guest') return 'fa-id-card-clip fa-regular';

  return 'fa-user fa-regular';
};
