import ROUTES from '~/assets/javascript/constants/routes';

export default async function middleware({ redirect, query, localeRoute, params, store, $auth: { user: { workspace: { slug: tenantSlug } } } }) {
  if (query.id) {
    redirect(localeRoute(
      { name: ROUTES.studio.workflows.routeName, params: { tenantSlug, id: query.id } },
    ));

    return;
  }

  await store.dispatch('workspace/loadWorkspace', { builder: true });

  if (params.id) {
    store.commit('workflows/setStepsVisibility', false);
    store.dispatch('workspace/setCategoryByWorkflowId', params.id);
    store.dispatch('workflows/loadWorkflows');

    store
      .dispatch('workflows/loadCurrentWorkflow', params.id)
      .catch((error) => {
        if (error?.response?.status !== 404) throw error;

        const firstWorkflowId = store.getters['workspace/firstWorkflowId'];

        if (firstWorkflowId) {
          redirect(localeRoute(
            {
              name: ROUTES.studio.workflows.routeName,
              params: { tenantSlug, id: firstWorkflowId },
            },
          ));
        }
      });
  } else {
    const firstWorkflowId = store.getters['workspace/firstWorkflowId'];

    if (firstWorkflowId) {
      redirect(localeRoute(
        {
          name: ROUTES.studio.workflows.routeName,
          params: { tenantSlug, id: firstWorkflowId },
        },
      ));
    } else {
      store.dispatch('workflows/loadWorkflows');
    }
  }
}
