// ~/store/viewOptions.js
import { omit, sortBy } from 'lodash';

export const cacheableStateKeys = [
  'sortBy',
  'sortDesc',
  'filter',
  'columnWidths',
];

export const state = () => ({
  sortBy: {},
  sortDesc: {},
  filter: {},
  columnWidths: {},
  sheetsCache: [],
  isPreviewMode: false,
});

const getSheetIds = (resourceOrder, acc = []) => {
  resourceOrder?.forEach((item) => {
    if (item.id) {
      acc.push(item.id);
    } else {
      getSheetIds(item.items, acc);
    }
  });

  return acc;
};

export const mutations = {
  setFilter(state, { viewId, columnId, filter }) {
    state.filter = {
      ...state.filter,
      [viewId]: {
        ...state.filter[viewId],
        [columnId]: filter,
      },
    };
  },
  clearFilter(state, { viewId, columnId }) {
    const currentViewFilter = state.filter[viewId];
    const newViewFilter = currentViewFilter
      ? omit(currentViewFilter, [columnId])
      : {};

    state.filter = {
      ...state.filter,
      [viewId]: newViewFilter,
    };
  },
  saveSorting(state, { viewId, sortBy, sortDesc }) {
    state.sortBy = {
      ...state.sortBy,
      [viewId]: sortBy,
    };

    state.sortDesc = {
      ...state.sortDesc,
      [viewId]: sortDesc,
    };
  },
  saveWidth(state, { viewId, columnId, width }) {
    state.columnWidths = {
      ...state.columnWidths,
      [viewId]: {
        ...state.columnWidths[viewId],
        [columnId]: width,
      },
    };
  },
  updateSheetsCache(state, payload) {
    state.sheetsCache = payload;
  },
  setIsPreviewMode(state, payload) {
    state.isPreviewMode = payload;
  },
};

export const actions = {
  async fetchSheetsCache({ commit, rootGetters }) {
    const sheets = await this.$api.$get('/sheets');
    const sheetsOrder = getSheetIds(rootGetters['workspace/sheetsOrder']) || [];
    const sortedSheets = sortBy(sheets, ({ id }) => sheetsOrder.indexOf(id));

    commit('updateSheetsCache', sortedSheets);
  },
};
