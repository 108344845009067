import { buildBaseInfoComponent } from '~/assets/javascript/utils/page-state-components/info-components/_base';

export const buildChartInfoComponent = ({
  columns = 1,
  content,
  id,
  label,
  labelStyle = 'small',
  helperText,
  showHelperTextTooltip = false,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    content,
    label,
    label_style: labelStyle,
    helper_text: helperText,
    show_helper_text_tooltip: showHelperTextTooltip,
    type: 'chart',
  };

  if (id) component.id = id;

  return component;
};
