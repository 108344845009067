export const buildSXLSXheetName = (name) => {
  // There are several sheetName rules that can break XLSX exporter. We should try to accomodate the view name to those rules, not let it break.
  const DEFAULT_SHEET_NAME = 'Sheet';

  // Rule: Sheet name cannot be blank
  if (!name) return DEFAULT_SHEET_NAME;

  // Replace all Unicode whitespace characters with regular spaces
  name = name.replace(/\s+/g, ' ');

  // Rule: Sheet name cannot start or end with apostrophe (')
  name = name.replace(/^'|'$/g, '');

  // Remove all whitespaces and capitalize - this is not a rule, just to make it easier to fit in 31 chars
  name = name.replace(/\s/g, '-');
  const words = name.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
  name = words.join('');

  // Remove emojis - also not a rule
  name = name.replaceAll('⚙️', ''); // common zazos gear emoji
  name = name.replace(/\p{Emoji}+/gu, '');

  // Rule: Sheet name cannot contain : \ / ? * [ ]
  name = name.replace(/[\\/:?*[\]]/g, '');

  // Rule: Sheet name cannot exceed 31 chars
  if (name.length > 31) {
    // Trim and clip to 31 characters
    name = name.trim().slice(0, 31);
  }

  // Rule: Sheet name cannot be 'History'
  if (name === 'History') return DEFAULT_SHEET_NAME;

  // Rule: Sheet name cannot be blank
  if (name === '') return DEFAULT_SHEET_NAME;

  return name;
};
