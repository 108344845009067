import { buildBaseInfoComponent } from './_base';

export const buildFieldInfoComponent = ({
  columns = 1,
  exhibitionMode,
  fieldId,
  fieldType,
  id,
  label,
  labelStyle = 'small',
  helperText,
  showHelperTextTooltip = false,
  required = false,
  uneditable = false,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    ...(fieldType === 'Link' && { exhibition_mode: exhibitionMode }),
    field_id: fieldId,
    field_type: fieldType,
    label,
    label_style: labelStyle,
    type: 'field',
    helper_text: helperText,
    show_helper_text_tooltip: showHelperTextTooltip,
    required,
    uneditable,
  };

  if (id) component.id = id;

  return component;
};

export const buildFieldInfoComponentFromField = (field, overrides = {}) => buildFieldInfoComponent({
  fieldId: field.id,
  fieldType: field.type,
  label: field.name,
  ...overrides,
});
