// ~/store/viewsMenu.js
// import Vue from 'vue'

import { enrichViewData } from '~/assets/javascript/utils';

export const state = () => ({
  views: [],
  miniSidebar: window.localStorage.getItem('viewsMenu.miniSidebar') || false,
  search: '',
});

export const cacheableStateKeys = [
  'miniSidebar',
];

export const mutations = {
  updateViews(state, { views }) {
    state.views = views;
  },
  setMiniSidebar(state, data) {
    state.miniSidebar = data;
  },
  updateString(state, payload) {
    state[payload.field] = payload.data;
  },
  resetFilterToolbarSearch(state) {
    state.search = '';
  },
};

export const actions = {
  async loadViews({ commit, rootGetters }, { tenantSlug = null, isPublic = false }) {
    const path = isPublic ? `/public/workspaces/${tenantSlug}/views` : '/views';
    const views = await this.$api.$get(path);

    const categories = rootGetters['workspace/categoryHierarchy'];

    const enrichedViews = enrichViewData(views, categories);

    commit('updateViews', { views: enrichedViews });
  },
};
