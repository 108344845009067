import { pick } from 'lodash';

export const RECENT_NAVIGATION_MAX = 8;

export const getRecentNavigation = (namespace, resource) => {
  const recentNavigation = window.localStorage.getItem(`recentNavigation.${namespace}.${resource}`);
  return recentNavigation ? JSON.parse(recentNavigation) : [];
};

export const setRecentNavigation = (namespace, resource, newRecentNavigation) => {
  window.localStorage.setItem(`recentNavigation.${namespace}.${resource}`, JSON.stringify(newRecentNavigation));
};

export const addToRecentNavigation = (route) => { // adds to recent navigation grouped by namespace and resource
  const cleanRoute = pick(route, ['name', 'params', 'query', 'fullPath', 'hash', 'meta']);

  let namespace; // studio | public | member
  let resource; // sheets | workflows | views
  let routeId;

  if (route.name.includes('studio')) {
    namespace = 'studio';

    if (route.name.includes('pages')) {
      resource = 'views';
      routeId = route.params.id;
    } else if (route.name.includes('automations')) {
      resource = 'workflows';
      routeId = route.params.id;
    } else if (route.name.includes('data')) {
      resource = 'sheets';
      routeId = route.params.id;
    } else {
      return;
    }
  } else if (route.name.includes('t-tenantSlug-p')) {
    namespace = 'public';

    if (route.name.includes('viewId')) {
      resource = 'views';
      routeId = route.params.viewId;
    } else {
      return;
    }
  } else if (route.name.includes('t-tenantSlug-m')) {
    namespace = 'member';

    if (route.name.includes('viewId')) {
      resource = 'views';
      routeId = route.params.viewId;
    } else {
      return;
    }
  }

  if (!routeId) return;

  let recentNavigation = getRecentNavigation(namespace, resource);

  const ids = recentNavigation.map(({ params }) => params.viewId || params.id);

  if (ids.includes(routeId)) {
    // remove duplicate from history list
    recentNavigation = recentNavigation.filter(
      (obj) => {
        const objId = obj.params.viewId || obj.params.id;

        return objId !== routeId;
      },
    );
  }

  recentNavigation = [cleanRoute, ...recentNavigation];

  if (recentNavigation.length > RECENT_NAVIGATION_MAX) {
    recentNavigation = recentNavigation.slice(0, RECENT_NAVIGATION_MAX);
  }

  setRecentNavigation(namespace, resource, recentNavigation);
};
