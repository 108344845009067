<i18n lang="yaml">
pt:
  searchPlaceholder: 'Encontrar páginas'
en:
  searchPlaceholder: 'Find pages'
</i18n>

<template>
  <TheTopbar outlined :home="localePath({ name: 't-tenantSlug-m', params: { tenantSlug: $route.params.tenantSlug } })">
    <template #center-content>
      <TheTopbarSearch :placeholder="$t('searchPlaceholder')" :searchable-data="searchableData" />
    </template>
    <template #end-content>
      <MemberTopbarGroupSwitch />
    </template>
  </TheTopbar>
</template>

<script>
import TheTopbar from '~/components/topbars/the-topbar';
import TheTopbarSearch from '~/components/topbars/the-topbar/_search';
import MemberTopbarGroupSwitch from '~/components/topbars/member-topbar/_group-switch';
import { mapState } from 'vuex';
import { SearchableItemBuilder, ViewItemBuilder } from '~/assets/javascript/utils';

export default {
  name: 'MemberTopbar',
  components: {
    TheTopbar,
    TheTopbarSearch,
    MemberTopbarGroupSwitch,
  },
  computed: {
    ...mapState('viewsMenu', ['views']),
    searchableViewBuilder() {
      return new ViewItemBuilder({
        localeRoute: this.localeRoute,
        $route: this.$route,
        currentEnvironment: 'member',
        $i18n: this.$i18n,
      });
    },
    searchableItemBuilder() {
      return new SearchableItemBuilder(this.searchableViewBuilder);
    },
    searchableData() {
      return this.searchableItemBuilder?.buildItems(this.views);
    },
  },
};
</script>
