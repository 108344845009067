import Vue from 'vue';

export default class ZazosCable {
  constructor(host, $auth) {
    this.host = host;
    this.vueActionCable = Vue.prototype.$cable;
    this.$auth = $auth;
  }

  get cable() {
    // eslint-disable-next-line no-underscore-dangle
    return this.vueActionCable._cable;
  }

  get connected() {
    return this.cable?.connection?.isOpen() || false;
  }

  get userToken() {
    return (this.$auth.strategy.token.get() || '').split(' ')[1];
  }

  subscribe(...args) {
    if (!this.connected) this.connect();

    this.vueActionCable.subscribe(...args);
  }

  // actioncable-vue cant unsubscribe from all channels, so we need to do it manually
  unsubscribe(channelName) {
    if (!this.connected) this.connect();

    this.cable.subscriptions.subscriptions.forEach((subscription) => {
      if (JSON.parse(subscription.identifier).channel === channelName) {
        subscription.unsubscribe();
      }
    });

    this.vueActionCable.unsubscribe(channelName);
  }

  connect() {
    this.vueActionCable.connection.connect(this.host, this.userToken);
  }
}
