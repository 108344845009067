async function fetchToken(fn) {
  try {
    const token = await this.$recaptcha.getResponse();
    const result = await fn(token);
    await this.$recaptcha.reset();
    return result;
  } catch (error) {
    await this.$recaptcha.reset();
    throw error;
  }
}

export default (context) => {
  const { $recaptcha } = context;

  $recaptcha.fetchToken = fetchToken.bind(context);
};
