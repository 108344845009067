<template>
  <v-app id="inspire" class="layout-store">
    <StudioTopbar
      v-if="hasStudioAccess"
      transparent
      hide-search
    />
    <StoreTopbar
      v-else
      class="layout-store__topbar"
    />
    <v-main>
      <nuxt />
    </v-main>
    <StoreFooter
      v-if="!hasStudioAccess"
      class="layout-store__footer"
    />

    <NewVersionAlert />
    <Snackbar />
    <TheDialog />
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex';
import { workspace } from '~/mixins/workspace';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import NewVersionAlert from '~/components/NewVersionAlert';
import StudioTopbar from '~/components/topbars/studio-topbar';
import StoreTopbar from '~/components/topbars/store-topbar';
import StoreFooter from '~/components/footers/store-footer';

export default {
  name: 'Store',
  components: {
    TheDialog,
    Snackbar,
    NewVersionAlert,
    StudioTopbar,
    StoreTopbar,
    StoreFooter,
  },
  mixins: [workspace()],
  computed: {
    hasStudioAccess() {
      return this.$auth.loggedIn && this.$auth.user.role === 'admin';
    },
  },
  mounted() {
    // https://github.com/vuetifyjs/vuetify/issues/14429#issuecomment-976936907
    this.$vuetify.application.left = 1;
    this.setCurrentEnvironment('appStore');

    setTimeout(() => {
      this.$vuetify.application.left = 0;
    }, 200);

    if (this.hasStudioAccess) {
      this.setInStudio();
    }
  },
  destroyed() {
    this.resetWorkspace();
  },
  methods: {
    ...mapMutations('environment', ['setCurrentEnvironment']),
    ...mapMutations('studio', ['setInStudio']),
    ...mapMutations('workspace', ['resetWorkspace']),
  },
};
</script>
<style lang="scss">
@import '~/assets/styles/tools/zazos-background';

.layout-store {
  @include zazos-background($important: true); // v-app override
  --max-width: 1160px;
  padding-inline: 16px;
}

.layout-store__topbar {
  width: var(--max-width);
  max-width: 100%;
  margin-inline: auto;
}

.layout-store__footer {
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
}
</style>
