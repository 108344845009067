<i18n lang="yaml">
pt:
  data: 'Dados'
  home: 'Loja de Apps'
  settings: 'Ajustes'
  views: 'Páginas'
  workflows: 'Automações'
en:
  data: 'Data'
  home: 'App Store'
  settings: 'Settings'
  views: 'Pages'
  workflows: 'Automations'
</i18n>

<template>
  <deck-button-group
    v-if="$auth.loggedIn && $auth.user.role === 'admin'"
    class="studio-topbar__pages-menu"
    :value="currentValue"
    :buttons="builderItems"
    buttons-kind="ghost"
    color="controls"
    kind="radio"
    mandatory
  />
</template>

<script>
import { mapGetters } from 'vuex';
import DeckButtonGroup from '~/deck/button-group';

export default {
  name: 'StudioTopbarPagesMenu',
  components: {
    DeckButtonGroup,
  },
  props: {
    transparent: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('workspace', ['tenantSlug']),
    builderItems() {
      const items = [
        { value: 't-tenantSlug-studio-data', icon: 'database', text: this.$t('data') },
        { value: 't-tenantSlug-studio-pages-id', icon: 'browser', text: this.$t('views') },
        { value: 't-tenantSlug-studio-automations-id', icon: 'bolt', text: this.$t('workflows') },
        { value: 't-tenantSlug-studio-settings', icon: 'gear', text: this.$t('settings') },
      ];

      return items.map(item => ({
        ...item,
        to: this.localePath({ name: item.value, params: { tenantSlug: this.tenantSlug } }),
        tooltipProps: {
          position: 'bottom',
          instant: true,
        },
      }));
    },
    currentValue() {
      return this.builderItems.find(item => this.$route.name.includes(item.value))?.value;
    },
  },
};
</script>

<style lang="scss">
.studio-topbar__pages-menu .v-slide-group__content {
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
