<i18n lang="yaml">
pt:
  nothingHereYet: 'Nada aqui ainda.'
en:
  nothingHereYet: 'Nothing here yet.'
</i18n>

<template>
  <div
    class="the-topbar-search"
    :style="cssProps"
  >
    <v-card
      ref="searchCard"
      v-hotkey="keymap"
      v-click-outside="closeExpandedSearch"
      class="the-topbar-search__card"
      :class="{ 'the-topbar-search__card--expanded': expanded }"
    >
      <TheTopbarSearchTextInput
        :expanded="expanded"
        :search-term="searchTerm"
        :placeholder="placeholder"
        @click="!expanded ? toggle() : resetSelection()"
        @focus="!expanded ? toggle() : resetSelection()"
        @key-pressed="onTextInputKeyPressed"
        @close="closeExpandedSearch"
        @input="search"
      />
      <v-expand-transition>
        <div v-if="expanded" class="the-topbar-search__drawer">
          <div class="the-topbar-search-drawer__items">
            <TheTopbarSearchResult v-if="searchTerm !== ''" :result="result" @item-clicked="toggle" />
            <TheTopbarSearchRecent @item-clicked="toggle" />
          </div>
          <TheTopbarSearchFooter class="the-topbar-search-drawer__footer" />
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { everyItemOfArrayShouldHave } from 'vue-prop-validation-helper';
import { isMac, LocalSearchStrategy, SearchEngine } from '~/assets/javascript/utils';
import TheTopbarSearchTextInput from './_text-input';
import TheTopbarSearchRecent from './_recent';
import TheTopbarSearchResult from './_result';
import TheTopbarSearchFooter from './_footer';

export default {
  name: 'TheTopbarSearch',
  components: {
    TheTopbarSearchTextInput,
    TheTopbarSearchRecent,
    TheTopbarSearchResult,
    TheTopbarSearchFooter,
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          // here I check that click was outside the el and his children
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event.target);
          }
        };

        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  props: {
    searchableData: {
      type: Array,
      default: () => ([]),
      validator: everyItemOfArrayShouldHave(['id', 'type', 'title', 'subtitle', 'label', 'to']),
    },
    placeholder: { type: String, default: null },
  },
  data() {
    return {
      selectedElementIndex: null,
      keyboardSelectableElements: [],
      expanded: false,
      offsetXSearchCard: 0,
      offsetYSearchCard: 0,
      searchTerm: '',
    };
  },
  computed: {
    searchStrategy() {
      return new LocalSearchStrategy({
        items: this.searchableData,
        searchableKeys: [
          { name: 'title', weight: 10 },
          { name: 'subtitle', weight: 3 },
        ],
      });
    },
    searchResult() {
      const searchEngine = new SearchEngine(this.searchStrategy);
      return searchEngine.search(this.searchTerm);
    },
    result() {
      if (this._.isEmpty(this.searchTerm)) return {};
      // Group the items by their pluralized 'type' property. Ex: [view1, view2, workflow1, sheet1] turns into { views: [ view1, view2 ], workflows: [ workflow1 ], sheets: [ sheet1 ]}
      return this.searchResult.result.reduce((acc, item) => {
        const { type } = item;
        const pluralizedType = `${type}s`;

        // If the pluralized 'type' key doesn't exist in the accumulator, create an array for it
        if (!acc[pluralizedType]) {
          acc[pluralizedType] = [];
        }

        // Add the item to the corresponding array
        acc[pluralizedType].push(item);

        return acc;
      }, {});
    },
    keymap() {
      return this.expanded ? {
        esc: this.closeExpandedSearch,
        [isMac() ? 'command+k' : 'ctrl+k']: this.toggle,
        up: this.activateElementAbove,
        down: this.activateElementBelow,
        tab: this.activateElementBelow,
        'shift+tab': this.activateElementAbove,
      } : {
        [isMac() ? 'command+k' : 'ctrl+k']: this.toggle,
      };
    },
    cssProps() {
      return {
        '--offset-x-search-card': `-${this.offsetXSearchCard}px`,
        '--offset-y-search-card': `-${this.offsetYSearchCard}px`,
      };
    },
  },
  watch: {
    expanded(value) {
      this.getOffsetsSearchCard();

      if (value) {
        this.resetSelection();
      } else {
        this.search('');
      }
    },
    selectedElementIndex(value) {
      this.$nextTick(() => {
        if (this.selectedElementIndex >= 0) {
          this.setKeyboardSelectableElements();
          this.keyboardSelectableElements[value]?.focus();
        }
      });
    },
    result() {
      this.resetSelection();
    },
  },
  mounted() {
    this.$el.addEventListener('keydown', this.onKeydown);
  },
  destroyed() {
    this.$el.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    search(searchTerm) {
      this.searchTerm = searchTerm;
    },
    toggle() {
      this.expanded = !this.expanded;
    },
    setKeyboardSelectableElements() {
      this.keyboardSelectableElements = this.getKeyboardSelectableElements();
    },
    resetSelection() {
      this.$nextTick(() => {
        this.selectedElementIndex = null;
        this.setKeyboardSelectableElements();
      });
    },
    closeExpandedSearch() {
      this.search('');
      this.expanded = false;
    },
    onKeydown($event) {
      const bypassKeys = ['Tab', 'ArrowDown', 'ArrowUp', 'Escape', 'Enter']; // these are mapped through v-hotkey

      if (bypassKeys.includes($event.key)) return;

      if ($event.metaKey) return;

      this.$el.querySelector('input').focus();
    },
    onTextInputKeyPressed($event) { // this is necessary because v-hotkey doesn't work when input is focused
      switch ($event.key) {
        case 'ArrowDown':
          this.activateElementBelow();
          break;
        case 'ArrowUp':
          this.activateElementAbove();
          break;
        case 'Tab':
          if ($event.shiftKey) this.activateElementAbove();
          else this.activateElementBelow();
          $event.preventDefault();
          break;
        case 'k':
          this.toggle();
          $event.preventDefault();
          break;
        case 'Escape':
          this.closeExpandedSearch();
          break;
        default:
          break;
      }

      $event.stopPropagation();
    },
    activateElementBelow() {
      if (!this.expanded) return;
      if (this.selectedElementIndex === null) this.selectedElementIndex = 0;
      else this.selectedElementIndex = (this.selectedElementIndex + 1) % this.keyboardSelectableElements.length;
    },
    activateElementAbove() {
      if (!this.expanded) return;
      if (this.selectedElementIndex === null) this.selectedElementIndex = this.keyboardSelectableElements.length - 1;
      else this.selectedElementIndex = (this.selectedElementIndex - 1 + this.keyboardSelectableElements.length) % this.keyboardSelectableElements.length;
    },
    getKeyboardSelectableElements() {
      return [...this.$el.querySelectorAll('.v-list-item')];
    },
    getOffsetsSearchCard() {
      const { left, top } = this.$refs.searchCard.$el.getBoundingClientRect();
      this.offsetXSearchCard = left;
      this.offsetYSearchCard = top;
    },
  },
};
</script>

<style lang="scss">
.the-topbar-search {
  --search-height: 30px;
  --search-border-width: var(--z-input-border-width);
  --search-transition: 0.5s cubic-bezier(0.5,0,0.1,1);

  position: relative;
  height: var(--search-height);
  width: 640px;

  // Having the min-width the same value as its height will keep an 1:1 aspect
  // ratio when the available space is too small. So it behaves like an icon button
  min-width: var(--search-height);

  @media (min-width: 600px) {
    --search-height: 40px;
  }

  .expand-transition-leave-active,
  .expand-transition-enter-active {
    transition: var(--search-transition) !important;
  }
}

// hides the footer on mobile
.the-topbar-search-drawer__footer {
  position: sticky;

  @media (max-width: 840px) {
    display: none;
  }
}

.the-topbar-search__card {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  max-width: unset !important;
  box-shadow: none !important; // v-card override
  transition: var(--search-transition) !important; // v-card override
  border: 0 !important; // v-card override
  outline: var(--search-border-width) solid var(--z-main-background-contrast-color) !important;
  border-radius: $border-radius-root !important;
  z-index: 1;

  &:hover {
    outline-color: var(--z-color-gray) !important;
  }
}

.the-topbar-search__card--expanded {
  border-radius: 12px !important;

  &, &:hover {
    outline-color: var(--z-elevation-color) !important;
  }

  @media (max-width: 840px) {
    --search-height: 56px;

    border-radius: 0 !important;
    width: 100vw;
    margin-left: var(--offset-x-search-card);
    margin-top: var(--offset-y-search-card);
  }
}

.the-topbar-search-drawer__items {
  height: calc(100vh - var(--search-height));
  border-top: 1px solid var(--z-color-mist-light-1);
  overflow-y: auto;

  @media (min-width: 840px) {
    height: unset;
    max-height: 500px;
  }

  & .v-list {
    border-radius: 4px !important;
  }

}
</style>
