import axiosBetterStacktrace from 'axios-better-stacktrace';
import apiClient from '../assets/javascript/api';

export default ({ $axios }, inject) => {
  const externalApi = $axios.create({});
  axiosBetterStacktrace($axios);
  axiosBetterStacktrace(externalApi);

  inject('api', $axios);
  inject('apiClient', apiClient($axios));
  inject('externalApi', externalApi);
};
