<template>
  <v-list-item
    :id="`searchable-item-${id}`"
    :to="to"
    active-class="remove-opacity"
    class="searchable-item px-2"
    :aria-label="label"
    @click="$event => $emit('click', $event)"
    @keydown.enter.prevent="goToRoute"
  >
    <v-list-item-content class="searchable-item__wrapper">
      <v-icon v-test-id="'type-icon'" size="18" class="searchable-item__icon">
        {{ typeIcon }}
      </v-icon>
      <span class="searchable-item__title">
        <div>
          {{ title }}
        </div>
        <div v-if="subtitle" class="searchable-item__subtitle">
          <v-icon v-if="icon" size="14">
            {{ icon }}
          </v-icon>
          <div class="ml-1">
            {{ subtitle }}
          </div>
        </div>
      </span>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'SearchableItem',
  props: {
    id: { type: String, default: null },
    icon: { type: String, default: null },
    label: { type: String, required: true },
    subtitle: { type: String, default: null },
    to: { type: [String, Object], required: true },
    title: { type: String, required: true },
    type: { type: String, required: true, validator: value => ['view', 'sheet', 'workflow'].includes(value) },
  },
  computed: {
    typeIcon() {
      return {
        view: 'fa-browser fa-regular',
        sheet: 'fa-database fa-regular',
        workflow: 'fa-bolt fa-regular',
      }[this.type];
    },
  },
  methods: {
    goToRoute() {
      this.$router.push(this.to);
    },
  },
};
</script>

<style lang="scss">
.remove-opacity::before {
  opacity: 0 !important;
}

.searchable-item__wrapper {
  display: grid !important;
  justify-items: start;
  grid-template-columns: 22px 1fr;
  padding: 0 !important;
  height: 30px;
}

.searchable-item {
  padding: 0 !important;
  margin: 0px 4px;
  min-height: 36px !important;

  &:focus,
  &:hover {
    color: var(--v-primary-base) !important;
  }

  &.v-list-item {
    color: #424242 !important;
  }

  &::before {
    background-color: #FFFFFF;
  }

  &:focus,
  &:hover {
    &::before {
      background-color: var(--v-primary-base) !important;
      border-radius: 2px;
      opacity: 0.1 !important;
    }
  }
}

.searchable-item__icon {
  color: rgba(0, 0, 0, 0.54) !important;
  margin-bottom: 0 !important;
}

.searchable-item-tag {
  padding: 3px !important;
  margin: 2px !important;
}

.searchable-item__tag-text {
  font-size: 8px;
}

.searchable-item__title {
  font-size: 13px;
  font-weight: 600;
  margin-right: 2px;
  display: flex;

  & .v-slide-group__content {
    padding: 0 !important;
  }

}

.searchable-item__subtitle {
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #a0a0a0;
}
</style>

