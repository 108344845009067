<i18n lang="yaml">
pt:
  arrowKeys: 'Navegar'
  enterKey: 'Abrir'
en:
  arrowKeys: 'Select'
  enterKey: 'Open'
</i18n>

<template>
  <div class="search-footer">
    <deck-kbd command="up down" :label="$t('arrowKeys')" />
    <deck-kbd command="enter" :label="$t('enterKey')" />
  </div>
</template>

<script>
import DeckKbd from '~/deck/kbd';

export default {
  name: 'SearchFooter',
  components: {
    DeckKbd,
  },
};
</script>

<style lang="scss">
.search-footer {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
