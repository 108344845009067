import { flattenDeep, uniq } from 'lodash';
import RecordList from '~/assets/javascript/modules/record/list';
import RecordListOptions from '~/assets/javascript/modules/record/list/options';
import { calculateFieldFilterOptions } from '~/assets/javascript/utils';

const filterByListStorageKey = 'recordList.filterByList';
export const state = () => ({
  filterByList: JSON.parse(localStorage.getItem(filterByListStorageKey)) || {},
});

export const getters = {
  currentFilterByValue: ({ filterByList }, { currentView, recordToolboxOptions: { filterByFieldId } }) => {
    const currentFilterBy = filterByList[currentView.id];

    if (!currentFilterBy || currentFilterBy.fieldId !== filterByFieldId) return null;

    return currentFilterBy.value;
  },
  i18n:
    (_state, _getters) => globalThis.$nuxt?.$i18n,
  currentView:
    (_state, _getters, { view: viewState, studio: studioState, builderView: builderViewState }) => (
      studioState.inStudio ? builderViewState.view : viewState.view
    ),
  draftRecords:
    (_state, getters, { records: recordsState }) => getters
      .currentView
      ?.recordIds
      ?.map(id => recordsState.draftRecordsById[id])
      ?.filter(Boolean) || [],
  draftRecordsToView:
    (_state, _getters, { records: recordsState }) => view => view
      ?.recordIds
      ?.map(id => recordsState.draftRecordsById[id])
      ?.filter(Boolean) || [],
  recordToolbox:
    (_state, getters) => new RecordList(
      getters.currentView,
      getters.draftRecords,
      getters.recordToolboxOptions,
      getters.i18n?.t('global.emptyLabel'),
      getters.currentFilterByValue,
    ),
  recordToolboxOptions:
    (_state, getters) => new RecordListOptions(
      getters.currentView,
      getters.i18n?.locale,
    ),
  filterByFieldId: (_state, getters) => getters.recordToolboxOptions.filterByFieldId,
  filterByOptions: (_state, getters) => {
    const filterByOptionsRaw = getters.draftRecords.map(record => calculateFieldFilterOptions(
      record[getters.filterByFieldId],
      getters.recordToolboxOptions.fieldIdMapping[getters.filterByFieldId],
      getters.i18n?.t('global.emptyLabel'),
    ));

    return uniq(flattenDeep(filterByOptionsRaw)) || [];
  },
};

export const mutations = {
  setFilterByValue(state, { viewId, fieldId, value }) {
    state.filterByList = {
      ...state.filterByList,
      [viewId]: {
        fieldId,
        value,
      },
    };

    localStorage.setItem(filterByListStorageKey, JSON.stringify(state.filterByList));
  },
};

export const actions = {
  setFilterByValue({ commit, getters }, value) {
    return commit('setFilterByValue', {
      viewId: getters.currentView.id,
      fieldId: getters.recordToolboxOptions.filterByFieldId,
      value,
    });
  },
};
