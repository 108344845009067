<i18n lang="yaml">
pt:
  alert: "Agora você pode criar uma senha para sua conta."
  configurePassword: "Configurar senha"
en:
  alert: "Now you can create a password for your account."
  configurePassword: "Configure password"
</i18n>

<template>
  <v-snackbar
    :value="visible"
    timeout="-1"
    content-class="text-caption"
    min-height="28"
    :height="28"
    min-width="auto"
    elevation="0"
  >
    {{ $t('alert') }}

    <a :href="localePath({ name: 't-tenantSlug-m-account-configuration', params: { tenantSlug: $route.params.tenantSlug, } })" class="white--text">
      {{ $t('configurePassword') }}
    </a>

    <template #action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        x-small
        :aria-label="$t('global.close')"
        @click="close"
      >
        <v-icon x-small>
          fa-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CreatePasswordAlert',
  data() {
    return {
      closed: false,
    };
  },
  computed: {
    visible() {
      return !this.closed && this.$auth.user.show_password_alert;
    },
  },
  methods: {
    close() {
      this.closed = true;
    },
  },
};

</script>
