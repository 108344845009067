import { cloneDeep, isObject, isString } from 'lodash';

function getMessageTranslationKeys(error) {
  // Para evitar qualquer surpresa, hoje eu coloco esse try/catch aqui e ABS
  // Mas talvez o melhor aqui seja tratar de forma mais ativa
  try {
    // Extract response errors to keys:
    //
    // Response errors:
    // [
    //   username: ['already_in_use'],
    //   '': ['corote_flavour_invalid'],
    //   options: [{ expression: ['invalid_expression'] }],
    // ]
    //
    // Keys result:
    // [
    //   'errors.username.already_in_use',
    //   'errors.corote_flavour_invalid',
    //   'errors.options.expression.invalid_expression',
    // ]
    const responseDataErrors = error.response.data.errors;
    const errorKeys = [];

    const extractErrorKeys = (errors, baseKeys) => {
      if (Array.isArray(errors)) {
        errors.forEach(v => extractErrorKeys(v, baseKeys));
      } else if (isObject(errors)) {
        Object.entries(errors)
          .forEach(([key, value]) => extractErrorKeys(value, [...baseKeys, key]));
      } else {
        const filteredBaseKeys = baseKeys.filter(key => key !== '');

        if (filteredBaseKeys.length > 0) {
          errorKeys.push(`${filteredBaseKeys.join('.')}.${errors}`);
        } else {
          errorKeys.push(errors);
        }
      }
    };

    extractErrorKeys(responseDataErrors, ['errors']);

    return errorKeys;
  } catch {
    return [];
  }
}

function transformErrorsToString(obj, path = '') {
  if (typeof obj !== 'object') {
    return `${path}.${obj}`;
  }

  const results = [];

  if (Array.isArray(obj)) {
    obj.forEach((value, idx) => {
      const newPath = `${path}.${idx}`;
      results.push(transformErrorsToString(value, newPath));
    });
  } else {
    Object.keys(obj).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      results.push(transformErrorsToString(obj[key], newPath));
    });
  }

  return results.join('\n');
}

export default ({ $rollbar, $notifier }, inject) => {
  inject('errorRescue', (self, error, context, options = {}) => {
    let $t;

    if (self.$t) { // Vue instance
      $t = self.$t.bind(self);
    } else if (self.i18n) { // Nuxt app instance
      $t = self.i18n.t.bind(self.i18n);
    } else if (self.$i18n) { // Store instance
      $t = self.$i18n.t.bind(self.$i18n);
    }

    const messageTranslationKeys = getMessageTranslationKeys(error);
    const errorKey = messageTranslationKeys.find(key => $t && ($t(key) !== key));
    let errorMsg;

    if (errorKey) {
      errorMsg = $t(errorKey);
    } else if (error.response?.data) {
      errorMsg = transformErrorsToString(error.response.data);
    } else if (error.message?.toLowerCase() === 'network error') {
      errorMsg = $t('errors.network_error');
    } else {
      errorMsg = error.message;
    }

    if (!options.silenceRollbar) {
      const response = cloneDeep(error.response);

      if (isString(response?.config?.data)) {
        let data;

        try {
          // It prevents problems with Rollbar serialization, that cannot scrub sensitive data
          data = JSON.parse(response.config.data);
        } catch {
          data = response.config.data;
        }

        response.config.data = data;
      }

      if (error.response && errorKey !== undefined) {
        $rollbar.warning(context, error, response);
      } else if (error.response) {
        $rollbar.error(context, error, response);
      }
    }

    if (!options.onlyReturnErrorMsg) {
      $notifier.showMessage({
        content: errorMsg,
        color: 'warning',
      });
    }

    // eslint-disable-next-line no-console
    console.error(errorMsg);

    return errorMsg;
  });
};
