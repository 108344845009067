
/**
 * Sanitizes a string by removing all non-numeric characters except the specified separator and negative sign
 * and replaces the separator with a dot.
 *
 * @param {string} valueAsString - The string value to be sanitized.
 * @param {string} separator - The separator character to be preserved.
 * @returns {string} The sanitized string.
 */
function sanitizeString(valueAsString, separator) {
  return valueAsString
    .replace(new RegExp(`[^-0-9${separator}]`, 'g'), '')
    .replace(separator, '.');
}

export function precisionPow(precision) {
  return Math.round(10 ** precision);
}

export function floatifyNumber(valueAsInteger, precision) {
  return valueAsInteger / precisionPow(precision);
}

export function integerifyNumber(valueAsFloat, precision) {
  return Math.round(valueAsFloat * precisionPow(precision));
}

export function integerifyNumberFromString(valueAsString, separator, precision) {
  const floatValue = parseFloat(sanitizeString(valueAsString, separator));
  return integerifyNumber(floatValue, precision);
}

export function formatWithThousandSeparator(valueAsString, thousandSeparator) {
  return valueAsString.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}
