import { omit, pickBy, isEmpty, cloneDeep } from 'lodash';
import Vue from 'vue';
import { enrichWorkflowData } from '~/assets/javascript/utils';
import { formatWorkflow } from '~/assets/javascript/utils/workflow';

export const state = () => ({
  loadingWorkflows: false,
  loadingCurrentWorkflow: false,
  loadingWorkflowSteps: false,
  reloadingCurrentWorkflow: false,
  workflows: [],
  workflow: {},
  stepIdLoading: null,
  stepErrors: {},
  stepsVisibility: {},
  workflowStepsEntityDependencies: {},
});

export const getters = {
  currentWorkflow: state => state.workflow,
  currentTrigger: (_, { currentWorkflow }) => currentWorkflow.trigger || {},
  triggerHasDynamicSchema: (_, { currentTrigger }) => ['Webhook', 'WorkflowRequest'].includes(currentTrigger.trigger_type),
  triggerHasRecordAssociated: (_, { triggerHasDynamicSchema }) => !triggerHasDynamicSchema,
  currentSheet: (_, { currentTrigger }, rootState) => {
    const sheets = rootState['viewOptions/sheetsCache'] || [];
    if (currentTrigger.sheet_id) return sheets.find(({ id }) => id === currentTrigger.sheet_id);

    return sheets.find(
      sheet => sheet.views.find(({ id }) => id === currentTrigger.view_id),
    );
  },
  currentViews: (_, { currentTrigger }) => currentTrigger.views || [],
  availableFields: (_, { currentTrigger }) => currentTrigger?.fields || [],
  workflowStepsMap: (_, { currentWorkflow }) => {
    const stepsMap = {};

    const collectSteps = (steps) => {
      steps.forEach((step) => {
        stepsMap[step.id] = step;

        if (step.sorted_steps) {
          collectSteps(step.sorted_steps);
        }
      });
    };

    if (currentWorkflow.sorted_steps) {
      collectSteps(currentWorkflow.sorted_steps);
    }

    return stepsMap;
  },
  isArchived: ({ workflow }) => Boolean(workflow.archived_at),
};

export const mutations = {
  assign(state, payload) {
    Object.assign(state, payload);
  },
  setWorkflows(state, workflows) {
    state.workflows = workflows;
  },
  setLoadingCurrentWorkflow(state, loading) {
    state.loadingCurrentWorkflow = loading;
  },
  setLoadingWorkflowSteps(state, loading) {
    state.loadingWorkflowSteps = loading;
  },
  setReloadingCurrentWorkflow(state, reloading) {
    state.reloadingCurrentWorkflow = reloading;
  },
  setCurrentWorkflowWithoutSteps(state, workflow) {
    state.workflow = workflow;
  },
  setCurrentWorkflow(state, workflow) {
    state.workflow = formatWorkflow(workflow, globalThis.$nuxt?.$i18n?.locale);

    Vue.set(state, 'stepErrors', {});
  },
  setStepsVisibility(state, visible) {
    if (visible) {
      const visibility = Object.keys(state.workflow.stepsById).reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      state.stepsVisibility = visibility;
    } else {
      state.stepsVisibility = {};
    }
  },
  setStepVisibility(state, { stepId, visible }) {
    if (visible) {
      Vue.set(state.stepsVisibility, stepId, visible);
    } else {
      Vue.delete(state.stepsVisibility, stepId);
    }
  },
  setLoadingStepId(state, id) {
    state.stepIdLoading = id;
  },
  setStepErrors(state, { stepId, errors }) {
    Vue.set(state.stepErrors, stepId, errors);
  },
  updateLocalStepMapping(state, { workflowId, stepId, key, value, mapping }) {
    if (workflowId !== state.workflow.id) return;

    const step = state.workflow.stepsById[stepId];

    if (mapping) {
      Vue.set(step, 'mapping', mapping);
    } else {
      Vue.set(step.mapping, key, value);
    }
  },
  updateTriggerViews(state, { workflowTriggerId, views }) {
    const workflow = state.workflows.find(({ trigger }) => trigger?.id === workflowTriggerId);

    if (workflow) {
      workflow.trigger.views = views;
    }

    if (state.workflow.trigger?.id === workflowTriggerId) {
      state.workflow.trigger.views = views;
    }
  },
  setWorkflowStepsEntityDependencies(state, dependencies) {
    state.workflowStepsEntityDependencies = dependencies;
  },
};

export const actions = {
  reloadCurrentWorkflow({ commit, getters }) {
    const currentWorkflowId = getters.currentWorkflow.id;
    if (!currentWorkflowId) return null;
    commit('setReloadingCurrentWorkflow', true);
    return this.$apiClient.workflows.get(currentWorkflowId).then((workflow) => {
      commit('setCurrentWorkflow', workflow);
      commit('setReloadingCurrentWorkflow', false);
    });
  },
  loadCurrentWorkflow({ commit }, id) {
    if (id) {
      let isLoadingSteps = true;
      commit('setLoadingCurrentWorkflow', true);
      commit('setLoadingWorkflowSteps', true);
      const promises = [];

      promises.push(this.$apiClient.workflows.get(id).then((workflow) => {
        isLoadingSteps = false;
        commit('setLoadingCurrentWorkflow', false);
        commit('setLoadingWorkflowSteps', false);
        commit('setCurrentWorkflow', workflow);
      }));

      promises.push(this.$apiClient.workflows.get(id, { without_steps: true }).then((workflow) => {
        // If the steps are already loaded, we don't want to update the state because it already has a more complete data
        if (!isLoadingSteps) return;

        commit('setLoadingCurrentWorkflow', false);
        commit('setCurrentWorkflowWithoutSteps', workflow);
      }));

      return Promise.all(promises).catch((error) => {
        commit('setLoadingCurrentWorkflow', false);
        throw error;
      });
    }

    return commit('setCurrentWorkflow', {});
  },
  async loadWorkflows({ commit, rootGetters, state }) {
    if (state.loadingWorkflows) return;

    commit('assign', { loadingWorkflows: true, stepIdLoading: null });

    try {
      const workflows = await this.$apiClient.workflows.list();

      const categories = rootGetters['workspace/categoryHierarchy'];
      const enrichedWorkflows = enrichWorkflowData(workflows, categories);
      commit('assign', { workflows: enrichedWorkflows });
    } catch (error) {
      this.$errorRescue(this, error, 'loadWorkflows');
    } finally {
      commit('assign', { loadingWorkflows: false });
    }
  },
  async updateStep({ commit, getters }, { stepId, payload }) {
    const workflowId = getters.currentWorkflow.id;

    const saveMethod = () => {
      commit('setLoadingStepId', stepId);
      commit('setStepErrors', { stepId, errors: [] });

      return this.$api.$patch(`/workflow_steps/${stepId}`, payload);
    };

    const onSave = () => {
      commit('setLoadingStepId', null);
    };

    const onSaveError = (error) => {
      const errors = error.response?.data?.errors;

      if (errors) commit('setStepErrors', { stepId, errors });

      commit('setLoadingStepId', null);

      this.$errorRescue(this, error, 'updateStep', { onlyReturnErrorMsg: true });
    };

    const loadMethod = () => {
      const currentWorkflowId = getters.currentWorkflow.id;

      if (!currentWorkflowId || workflowId !== currentWorkflowId) return Promise.resolve();

      commit('setReloadingCurrentWorkflow', true);

      return this.$apiClient.workflows.get(getters.currentWorkflow.id);
    };

    const onLoad = (workflow) => {
      if (!workflow || getters.currentWorkflow.id !== workflow.id) return;

      commit('setCurrentWorkflow', workflow);
      commit('setReloadingCurrentWorkflow', false);
    };

    return this.$asyncDataManager.save({
      loadMethod,
      onLoad,
      onSave,
      onSaveError,
      loadId: `workflow__${workflowId}`,
      saveId: `workflow-step__${stepId}`,
      saveMethod,
    });
  },
  async updateStepMapping({ state, commit, dispatch }, { stepId, payload }) {
    const step = state.workflow.stepsById[stepId];
    const mapping = payload;

    Object.keys(mapping).forEach((mappingKey) => {
      const schema = step.action_schema[mappingKey];

      if (isEmpty(mapping[mappingKey])) {
        delete mapping[mappingKey];
      }

      if (schema.display_if) {
        const { key, accessor, value } = schema.display_if;
        const mappingItem = mapping[key] || {};

        if (
          (accessor === 'value' && mappingItem.value !== value)
          || (accessor === 'type' && mappingItem.type !== value)
        ) {
          delete mapping[mappingKey];
        }
      }
    });

    commit('updateLocalStepMapping', { workflowId: step.workflow_id, stepId, mapping });

    return dispatch('updateStep', { stepId, payload: { mapping: payload } });
  },
  removeStepMapping({ state, dispatch }, { stepId, keys }) {
    const step = state.workflow.stepsById[stepId];

    const newMapping = omit(step.mapping, keys);

    return dispatch('updateStepMapping', { stepId, payload: newMapping });
  },
  incrementStepMapping({ state, dispatch }, { stepId, payload }) {
    const step = state.workflow.stepsById[stepId];

    const newMapping = pickBy(
      {
        ...step.mapping,
        ...payload,
      },
      (_, key) => key in step.action_schema,
    );

    return dispatch('updateStepMapping', {
      stepId,
      payload: newMapping,
    });
  },
  async createWorkflow({ commit }, { name, triggerType, appCategoryName }) {
    const workflow = await this.$apiClient.workflows.create({
      name,
      trigger_type: triggerType,
      app_category_name: appCategoryName,
    });

    commit('setWorkflows', [...(state.workflows || []), workflow]);

    return workflow;
  },
  async removeWorkflow({ commit, state }, { workflowId }) {
    await this.$api.delete(`/workflows/${workflowId}`);

    if (state.workflow.id === workflowId) {
      commit('setCurrentWorkflow', {});
    }

    commit('assign', { workflows: [...state.workflows.filter(({ id }) => id !== workflowId)] });
  },
  async updateWorkflow({ commit, state: { workflows, workflow } }, { workflowId, ...workflowData }) {
    await this.$apiClient.workflows.update(workflowId, workflowData);

    const workflowToUpdate = workflowId === workflow.id
      ? workflow
      : workflows.find(({ id }) => id === workflowId);
    const updatedWorkflow = { ...workflowToUpdate, ...workflowData };

    // update updated workflow in workflows array
    const updatedWorkflows = workflows.map((workflowItem) => {
      if (workflowItem.id === workflowId) {
        return updatedWorkflow;
      }

      return workflowItem;
    });

    // update current workflow if it's the same as updated
    if (workflowId === workflow.id) { commit('setCurrentWorkflow', updatedWorkflow); }

    commit('assign', { workflows: [...updatedWorkflows] });
  },
  async updateTrigger({ dispatch }, { sheetId, workflowTriggerId, paramsMap, sync, options, schema, outputSchema }) {
    try {
      const updateTriggerTrackPayloadMap = {
        sheet: { newState: sheetId, property: 'sheet_id' },
        paramsMap: { property: 'params_map' },
        sync: { newState: sync, property: 'sync' },
        options: { newState: options, property: 'options' },
        schema: { newState: schema, property: 'schema' },
        outputSchema: { newState: outputSchema, property: 'output_schema' },
        default: {},
      };
      const key = (sheetId && 'sheet')
        || (paramsMap && 'params_map')
        || (sync && 'sync')
        || (options && 'options')
        || (schema && 'schema')
        || (outputSchema && 'outputSchema')
        || 'default';

      window.analytics.track('updateTrigger', updateTriggerTrackPayloadMap[key]);

      const trigger = await this.$apiClient.workflowTriggers.update(
        workflowTriggerId,
        { sheet_id: sheetId, params_map: paramsMap, sync, options, schema, output_schema: outputSchema },
      );

      await dispatch('reloadCurrentWorkflow');
      return trigger;
    } catch (error) {
      this.$errorRescue(this, error, 'updateTrigger');

      return null;
    }
  },
  async removeWorkflowStep({ dispatch, commit }, stepId) {
    commit('assign', { loadingWorkflows: true });

    try {
      await this.$api.delete(`/workflow_steps/${stepId}`);
      await dispatch('reloadCurrentWorkflow');
    } catch (err) {
      commit('assign', { loadingWorkflows: false });
      throw err;
    }
  },
  async updateCurrentTriggerViews({ commit, dispatch, getters: { currentTrigger } }, { views }) {
    const currentTriggerViews = currentTrigger.views;
    let newTriggerViews = cloneDeep(currentTriggerViews);
    // We need to delete all workflow_trigger_views that are not in the views array but are in the currentTriggerViews array
    // and create all workflow_trigger_views that are in the views array but are not in the currentTriggerViews array

    const triggerViewsToDelete = currentTriggerViews.filter(({ id }) => !views.find(({ id: viewId }) => viewId === id));
    const triggerViewsToCreate = views.filter(({ id }) => !currentTriggerViews.find(({ id: viewId }) => viewId === id));

    const deletePromises = triggerViewsToDelete.map(({ id }) => this.$apiClient.builder.workflowTriggerViews.delete(currentTrigger.id, id));
    const createPromises = triggerViewsToCreate.map(({ id }) => this.$apiClient.builder.workflowTriggerViews.create(currentTrigger.id, id));

    const failedRequestsReasons = [];

    const results = await Promise.allSettled([...deletePromises, ...createPromises]);

    results.forEach((result, index) => {
      const view = index < triggerViewsToDelete.length ? triggerViewsToDelete[index] : triggerViewsToCreate[index];

      if (result.status === 'fulfilled') {
        if (index < triggerViewsToDelete.length) {
          newTriggerViews = newTriggerViews.filter(({ id }) => id !== view.id);
        } else {
          newTriggerViews.push(view);
        }
      } else {
        failedRequestsReasons.push(result.reason);
      }
    });

    commit('updateTriggerViews', { workflowTriggerId: currentTrigger.id, views: newTriggerViews });
    dispatch('reloadCurrentWorkflow');

    if (failedRequestsReasons.length > 0) {
      throw failedRequestsReasons[0];
    }
  },
  async loadWorkflowStepsEntityDependencies({ getters, commit }) {
    const stepsMap = getters.workflowStepsMap;

    const dependencies = {};

    try {
      await Promise.all(
        Object.keys(stepsMap).map(stepId => this.$apiClient.builder.entityDependencies.listBySourceIdAndType(
          stepId,
          'WorkflowStep',
        ).then((response) => {
          dependencies[stepId] = response;
        })),
      );
    } catch (error) {
      this.$errorRescue(this, error, 'loadWorkflowStepsEntityDependencies', { onlyReturnErrorMsg: true });
    }

    commit('setWorkflowStepsEntityDependencies', dependencies);
  },
};
