<template>
  <v-app id="inspire" class="member-layout">
    <MemberTopbar />
    <Sidebar />

    <v-main class="member-layout__wrapper">
      <nuxt />
    </v-main>

    <CreatePasswordAlert />
    <NewVersionAlert />
    <Snackbar />
    <ThePaywall />
    <TheDialog />
  </v-app>
</template>

<script>
import { mapMutations } from 'vuex';
import { userNotificationChannelMixin } from '~/mixins/userNotificationChannelMixin';
import { workspace } from '~/mixins/workspace';
import MemberTopbar from '~/components/topbars/member-topbar';
import Sidebar from '~/components/Sidebar';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import CreatePasswordAlert from '~/components/workspace/CreatePasswordAlert';
import NewVersionAlert from '~/components/NewVersionAlert';
import ThePaywall from '~/components/the-paywall';

export default {
  name: 'Member',
  components: {
    ThePaywall,
    Sidebar,
    Snackbar,
    TheDialog,
    CreatePasswordAlert,
    NewVersionAlert,
    MemberTopbar,
  },
  mixins: [workspace(), userNotificationChannelMixin],
  middleware: ['auth', 'refresh-user'],
  head() {
    return this.$buildHead();
  },
  mounted() {
    this.setOutsideStudio();
    this.setCurrentEnvironment('member');
    const utmsObject = this._.pickBy(this.$route.query, (_value, key) => this._.startsWith(key, 'utm_'));

    if (!this._.isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
  destroyed() {
    this.resetWorkspace();
  },
  methods: {
    ...mapMutations('environment', ['setCurrentEnvironment']),
    ...mapMutations('studio', ['setOutsideStudio']),
    ...mapMutations('workspace', ['resetWorkspace']),
  },
};
</script>
<style lang="scss">
@supports not (height: 100dvh) {
  /**
  * All scrollable contents in the member area are confined to specific elements,
  * so if `dvh` unit is not supported, it's presumably safe to disable scrolling
  * on the body and html elements to avoid mobile browsers address bar on top of
  * the page and having a double overflow.
  *
  * PSA: in `__generic.scss` is where we set the `html` height to `100dvh`
  */
  body, html {
    overflow: hidden;
  }
}
</style>
