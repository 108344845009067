import Vue from 'vue';
import ActionCableVue from 'actioncable-vue';
import ZazosCable from '~/assets/javascript/modules/cable/zazos-cable';

Vue.use(ActionCableVue, {
  debug: process.env.ROLLBAR_ENV !== 'production',
  debugLevel: 'all',
  connectImmediately: false,
});

export default ({ $auth, env: { actionCableHost } }, inject) => {
  inject('zazosCable', new ZazosCable(actionCableHost, $auth));
};
