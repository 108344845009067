export default function generateUUID() {
  try {
    try {
      // get crypto from DOM
      return crypto.randomUUID();
    } catch {
      // get crypto from nodejs
      return require('crypto').randomUUID();
    }
  } catch {
    // fallback to Math.random
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }
}
