export const MENTION_TYPES = {
  DYNAMIC_DATE: 'DynamicDate',
  DYNAMIC_LIST: 'DynamicList',
  FIELD: 'Field',
  GLOBAL_PARAM: 'GlobalParameter',
  INTEGRATION_DATA: 'IntegrationData',
  PARAM: 'Param',
  RECORD_ATTRIBUTE: 'RecordAttribute',
  STEP_RESULT: 'StepResult',
  TENANT_NAME: 'TenantName',
  TENANT_RESET_PASSWORD_URL: 'TenantResetPasswordUrl',
  TENANT_URL: 'TenantUrl',
  TRIGGER_SCHEMA_RECORD: 'TriggerSchemaRecord',
  TRIGGER_SCHEMA: 'TriggerSchema',
  VIEW: 'View',
};

export const ADDITIONAL_MENTION_TYPES = {
  SLACK: 'Slack',
};
