import { productFruits } from 'product-fruits';

// docs: https://help.productfruits.com/en/article/installation-via-npm-package
// overview da API JS: https://help.productfruits.com/en/article/javascript-api-overview

export default ({ app, $auth, env: { productFruitsWorkspaceCode } }) => {
  if (!$auth?.loggedIn || !$auth?.user?.role === 'admin' || !productFruitsWorkspaceCode || window.productFruitsIsReady) return;

  productFruits.init(
    productFruitsWorkspaceCode,
    app.i18n.localeProperties.code,
    {
      id: $auth.user.id,
      username: $auth.user.username,
      email: $auth.user.username,
      role: $auth.user.role,
      props: $auth.user,
    },
  );

  app.router.afterEach(() => {
    productFruits.safeExec($productFruits => $productFruits.push(['pageChanged'])); // productFruits should be informed that the route has changed
  });
};
