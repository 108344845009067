<template>
  <div class="switch-menu">
    <v-menu
      :left="menuPosition === 'left'"
      :right="menuPosition === 'right'"
      offset-overflow
      offset-y
      attach
      :disabled="disabled"
    >
      <template #activator="{ on }">
        <deck-button
          v-if="!disabled"
          :text="selectedItem?.title"
          :icon-prepend="selectedItem?.icon"
          :tooltip-props="{
            text: subheader,
            position: 'bottom',
          }"
          :icon-append="$vuetify.breakpoint.smAndDown ? null : 'chevron-down'"
          icon-kind="regular"
          :icon="$vuetify.breakpoint.smAndDown ? selectedItem?.icon : null"
          color="controls"
          kind="ghost"
          is-ready
          v-on="on"
        />
      </template>

      <v-list subheader dense>
        <v-subheader class="pl-4">
          {{ subheader }}
        </v-subheader>
        <template v-for="item in items">
          <v-divider
            v-if="item.divider"
            :key="`${item.key}-divider`"
            class="mb-2"
          />

          <v-list-item
            :key="item.key"
            :aria-label="item.title"
            :to="item.to"
            v-on="item.key !== selectedItem?.key && item.on"
          >
            <v-icon
              size="18"
              class="mr-2"
            >
              {{ item.icon }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>

            <v-icon
              v-if="item.key === selectedItem?.key"
              size="14"
              color="grey darken-1"
              class="ml-2"
            >
              fa-regular fa-check
            </v-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DeckButton from '~/deck/button';

export default {
  name: 'TheTopbarSwitchMenu',
  components: {
    DeckButton,
  },
  props: {
    items: { type: Array, default: () => [], validator: value => value.every(item => item.key && item.title) },
    selectedItem: { type: Object, default: () => ({}) },
    subheader: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    menuPosition: { type: String, default: 'left' },
  },
};
</script>
