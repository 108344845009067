export class Module {
  constructor(nuxt) {
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.$store = nuxt.store;
    this.localePath = nuxt.app.localePath;
    this.router = nuxt.app.router;
  }

  get route() {
    return this.router.currentRoute;
  }

  async create({ companyName, username, password, marketingEmailApproval, termsApproval, appsToInstall }) {
    const { slug } = await this.$recaptcha.fetchToken(recaptchaToken => this.$store.dispatch('workspace/signUp', {
      name: companyName,
      username,
      password,
      recaptchaToken,
      marketingEmailApproval,
      termsApproval,
      appsToInstall,
    }));

    await this.$auth.credentialsModule.login({
      username,
      password,
      tenantSlug: slug,
    });
  }
}

export const WorkspaceSetup = (nuxt) => {
  nuxt.$auth.workspaceModule = new Module(nuxt);
};
