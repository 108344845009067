import animateScrollTo from 'animated-scroll-to';

export const $scrollTo = ({ top = null, left = null, elementToReach = null, scrollerElement = window }) => {
  // docs: https://github.com/Stanko/animated-scroll-to
  const options = {
    maxDuration: 100,
    speed: 50,
    easing: t => 1 - (--t) * t * t * t,
    elementToScroll: scrollerElement || window,
  };

  if (elementToReach) {
    return animateScrollTo(elementToReach, options);
  }

  return animateScrollTo([left, top], options);
};

export default (_, inject) => {
  inject('scrollTo', params => $scrollTo(params));
};
