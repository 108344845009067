<template>
  <v-tabs
    v-bind="$attrs"
    :value="activeTab"
    :height="size === 'small' ? '48px' : '64px'"
    :grow="kind !== 'fit'"
    :style="cssProps"
    :class="classes"
    :slider-size="$options.SLIDER_HEIGHT"
    :center-active="centerActive"
    class="deck-tabs"
    prev-icon="fa-chevron-left fa-regular"
    next-icon="fa-chevron-right fa-regular"
    :show-arrows="!disableArrows"
    v-on="$listeners"
    @change="onTabChange"
  >
    <!-- @slot For `deck-tabs-tab` components. -->
    <slot />
  </v-tabs>
</template>

<script>
const SLIDER_HEIGHT = 2;

/**
 * A component that renders a tabbed navigation. It must be used in conjunction with `deck-tabs-tab` components as its children or grandchildren.
 */
export default {
  SLIDER_HEIGHT,
  name: 'DeckTabs',
  provide() {
    return {
      deckTabs: {
        register: this.register,
        unregister: this.unregister,
        size: this.size,
        kind: this.kind,
      },
    };
  },
  props: {
    /**
     * The index or named value of the active tab.
     * @type {string | number}
     */
    value: {
      type: [String, Number],
      default: '',
    },

    /**
     * The size of the tabs.
     * @type {'small' | 'large' | string}
     * @default 'small'
     */
    size: {
      type: String,
      default: 'small',
    },

    /**
     * The width behavior of the tabs.
     * @type {'grow' | 'fit' | 'equal' | string}
     * @default 'grow'
     */
    kind: {
      type: String,
      default: 'grow',
    },

    /**
     * Determines whether the active tab is centered when overflow is present.
     * @type {boolean}
     * @default false
     */
    centerActive: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines whether the tabs should not be scrollable with side arrows when overflow is present.
     * @type {boolean}
     * @default false
     */
    disableArrows: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines whether the tabs should have a bottom border the same height of the slider.
     * @type {boolean}
     * @default false
     */
    withBottomBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: this.value,
      tabs: [],
    };
  },
  computed: {
    cssProps() {
      return {
        '--deck-tabs-nav-size': this.size === 'small' ? '12px' : '16px',
        '--deck-tabs-slider-height': `${this.$options.SLIDER_HEIGHT}px`,
      };
    },

    classes() {
      return {
        'deck-tabs--disable-arrows': this.disableArrows,
        'deck-tabs--with-bottom-border': this.withBottomBorder,
      };
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.activeTab = newValue;
    },
    activeTab(value) {
      /**
       * Reflects the tabs value. Bound to v-model.
       * @event input
       */
      this.$emit('input', value);
    },
  },
  methods: {
    /**
     * Registers a `deck-tabs-tab` component.
     * @param {VueComponent} tab
     */
    register(tab) {
      this.tabs.push(tab);
    },

    /**
     * Unregisters a `deck-tabs-tab` component.
     * @param {VueComponent} tab
     */
    unregister(tab) {
      // eslint-disable-next-line no-underscore-dangle
      if (this._isDestroyed) return;

      this.tabs.splice(this.tabs.indexOf(tab), 1);
    },

    async onTabChange(selectedTab) {
      this.activeTab = selectedTab;

      await this.$nextTick();

      this.activeTab = this.value;
    },
  },
};
</script>
<style lang="scss">
.deck-tabs {
  min-width: 0;

  &:where(.v-tabs) {
    background-color: transparent;
    flex: unset;
  }

  .v-tabs-bar {
    background-color: inherit !important;
  }

  :is(.v-slide-group__prev, .v-slide-group__next) {
    flex: 0 1 40px;
    min-width: 40px;

    &:is(:focus, :hover) .v-icon:not(.v-icon--disabled) {
      color: rgba(0, 0, 0, 0.87);
    }

    .v-icon {
      font-size: var(--deck-tabs-nav-size);

      &.v-icon--disabled {
        opacity: 0;
      }
    }
  }
}

.deck-tabs--disable-arrows {
  .v-slide-group__prev, .v-slide-group__next {
    display: none !important;
  }
}

.deck-tabs--with-bottom-border .v-slide-group__content {
  box-shadow: inset 0 -1px 0 0 var(--z-main-background-contrast-color);
}
</style>
