/* eslint-disable class-methods-use-this */
import ROUTES from '~/assets/javascript/constants/routes';
import AbstractBuilder from '../AbstractBuilder';

export class ViewItemBuilder extends AbstractBuilder {
  constructor({ localeRoute, $route, currentEnvironment, $i18n }) {
    super({
      localeRoute,
      $route,
      currentEnvironment,
      $i18n,
      i18nMessages: {
        en: { label: 'Search result - Page {name}' },
        pt: { label: 'Resultado da busca - Página {name}' },
      },
      type: 'view',
    });
  }

  itemTitle = view => view.page_name;

  itemRoute = view => (this.currentEnvironment ? ({
    name: ROUTES[this.currentEnvironment].view.routeName,
    params: { tenantSlug: this.$route.params.tenantSlug, [ROUTES[this.currentEnvironment].view.viewId]: view.id },
  }) : ({}));
}
