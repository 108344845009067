<template>
  <div
    class="deck-progress"
    :class="classes"
    :style="cssProps"
  >
    <span
      v-if="showPercentage"
      class="deck-progress__percentage"
    >
      <span class="deck-progress__fallback-value">{{ formattedValue }}%</span>
    </span>
    <!--
      triggered when value changes
      @event change
    -->
    <v-progress-linear
      :value="progressValue"
      :buffer-value="hideBackground ? 0 : 100"
      :indeterminate="indeterminate"
      :color="color"
      class="deck-progress__bar"
      rounded
      v-bind="$attrs"
      v-on="$listeners"
      @change="$emit('change', value)"
    />
  </div>
</template>

<script>
import { clamp } from 'lodash';

const DEFAULT_HEIGHT = '12px';
const HEIGHT_AS_LOADER = '4px'; // Used as default height for loader if not explicitly set

export default {
  name: 'DeckProgress',

  props: {
    /**
     * The value of the progress.
     * Will automatically clamp values between 0 and 100.
     * @type {number}
     * @default 100
     */
    value: {
      type: Number,
      default: 100,
    },

    /**
     * The color of the progress.
     * @type {'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' | string}
     * @default 'primary'
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * The height of the progress. Must be a valid CSS dimension.
     * Will render with 12px by default, but at a smaller height when behaving
     * as a loader if not explicitly set.
     * @type {string}
     * @default '12px'
     */
    height: {
      type: [String],
      default: undefined,
    },

    /**
     * Positions the progress absolute and move to top. Override default
     * absolute by setting `fixed`.
     * @type {boolean}
     * @default false
     */
    top: {
      type: Boolean,
      default: false,
    },

    /**
     * Positions the progress absolute and move to bottom.
     * @type {boolean}
     * @default false
     */
    bottom: {
      type: Boolean,
      default: false,
    },

    /**
     * Forces `position: absolute` in the progress.
     * @type {boolean}
     * @default false
     */
    absolute: {
      type: Boolean,
      default: false,
    },

    /**
     * Forces `position: fixed` in the progress.
     * @type {boolean}
     * @default false
     */
    fixed: {
      type: Boolean,
      default: false,
    },

    /**
     * Adds a translucent background to the progress defined by the `color`.
     * @type {boolean}
     * @default true
     */
    hideBackground: {
      type: Boolean,
      default: false,
    },

    /**
     * Hide the percentage value.
     * It will be hidden by default when the progress is `indeterminate`,
     * `fixed`, `absolute`, `top`, `bottom` or the value is NaN.
     * @type {boolean}
     * @default false
     */
    hidePercentage: {
      type: Boolean,
      default: false,
    },

    /**
     * Gives an indeterminate loading style to the progress.
     * @type {boolean}
     * @default false
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },

    /**
     * The duration of the transition when changing the value or on mount.
     * Customizing this is useful when using as a loader with known duration.
     * Use 0 to disable the transition.
     * @type {number}
     * @default 500
     */
    transitionDuration: {
      type: Number,
      default: 500,
    },

    /**
     * The border radius of the progress in px.
     * When applied together with `bottom` or `top` will apply only to the
     * respective corners.
     * Useful to avoid needing to explicitly set `overflow: hidden` in the
     * parent to clip the corners in rounded parent elements.
     * Pair it with `insetOffset` with the same value of the parent border if
     * present to avoid the progress overlapping the border.
     * @type {number | string}
     * @default 0
     */
    borderRadius: {
      type: [Number, String],
      default: 0,
    },

    /**
     * An outer offset of all sides of the progress in px.
     * Useful to avoid the progress overlapping the parent border when using as
     * a loader with `top` or `bottom`. Only affected if it's a positive number.
     * @type {number | string}
     * @default 0
     */
    insetOffset: {
      type: [Number, String],
      default: 0,
    },
  },

  data() {
    return {
      hasMounted: false,
    };
  },
  computed: {
    formattedValue() {
      return clamp(Math.floor(this.value), 0, 100);
    },
    progressValue() {
      return this.transitionDuration >= 0 && !this.hasMounted ? 0 : this.formattedValue;
    },

    renderWithoutDefaultBorderRadius() {
      return this.computedBorderRadius > 0
      || this.fixed
      || this.absolute
      || this.top
      || this.bottom;
    },

    renderAsLoader() {
      return this.indeterminate || this.renderWithoutDefaultBorderRadius;
    },

    showPercentage() {
      return !this.hidePercentage
      && !Number.isNaN(this.value)
      && !this.renderAsLoader;
    },

    computedHeight() {
      if (this.renderAsLoader && this.height === undefined) {
        return HEIGHT_AS_LOADER;
      }

      return this.height || DEFAULT_HEIGHT;
    },
    computedBorderRadius() {
      return parseInt(this.borderRadius, 10);
    },

    computedInsetOffset() {
      return parseInt(this.insetOffset, 10);
    },

    cssProps() {
      return {
        '--deck-progress-height': this.computedHeight,
        '--deck-progress-reference-value': this.progressValue,
        '--deck-progress-transition-duration': `${this.transitionDuration}ms`,
        '--deck-progress-border-radius': `${this.borderRadius}px`,
        '--deck-progress-initial-border-radius': this.renderWithoutDefaultBorderRadius ? '0px' : '12px',
        '--deck-progress-inset-offset': `${this.insetOffset}px`,
        ...this.renderAsLoader && { '--deck-progress-transition-timing-function': 'linear' }, // linear when behaving as loaders is more predictable
      };
    },

    classes() {
      return {
        'deck-progress--absolute': this.top || this.bottom || this.absolute,
        'deck-progress--fixed': this.fixed,
        'deck-progress--top': this.top,
        'deck-progress--bottom': this.bottom,
      };
    },
  },

  mounted() {
    // setTimeout instead of nextTick so the update happens after the DOM is
    // rendered in order to trigger the CSS transition when changing 0 to `value`.
    setTimeout(() => {
      this.hasMounted = true;
    }, 0);
  },
};
</script>

<style lang="scss">
@supports (background: paint(something)){
  // Using Houdini API will allow us to interpolate (animate) the counter value by defining it as proper integer
  @property --deck-progress-value {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }
}

.deck-progress {
  --deck-progress-inner-radius: calc(var(--deck-progress-border-radius) - var(--deck-progress-inset-offset));
  --deck-progress-transition:
    var(--deck-progress-transition-duration)
    var(--deck-progress-transition-timing-function, cubic-bezier(0.25, 0.8, 0.5, 1))
  ;
  display: flex;
  align-items: center;
  width: 100%;
  border: var(--deck-progress-inset-offset) solid transparent;
}

.deck-progress__percentage {
  // We pass by reference so @property definition is not overridden
  --deck-progress-value: var(--deck-progress-reference-value);

  counter-set: value var(--deck-progress-value);
  position: relative;
  display: grid;
  flex: 0 0 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 0.85;
  margin-right: 2px;
  transition: --deck-progress-value var(--deck-progress-transition);

  &::before {
    content: '100%'; // And actual "100%" string to define the min-width of the element
    visibility: hidden;
  }

  &::after {
    content: counter(value) '%';
    position: absolute;

    // Hide the animatable counter when the browser doesn't support Houdini API and show the static span below instead
    @supports not (background: paint(something)){
      visibility: hidden;
    }
  }
}

.deck-progress__fallback-value {
  position: absolute;

  // Hide this span when the browser DOES support Houdini API
  @supports (background: paint(something)){
    visibility: hidden;
  }
}

.deck-progress__bar {
  flex: 1 1 0;
  height: max(var(--deck-progress-border-radius) * 2, var(--deck-progress-height)) !important;
  transition: var(--deck-progress-transition) !important;
  border-radius: var(--deck-progress-inner-radius);
  pointer-events: none;

  .v-progress-linear__background {
    // This is the element also used as the buffer value which is unnecessarily animated with width
    // We override it to both avoid the animation and make sure the background has full width when the progress is indeterminate
    display: unset !important;
    width: 100% !important;
    height: var(--deck-progress-height) !important;
    left: 0 !important;
  }

  .v-progress-linear__determinate {
    // Avoid vuetify default property used for transition and use transform for better performance/smoothness
    transform: scaleX(calc(var(--deck-progress-reference-value) / 100));
    // Since we are using transform to manually set the bar size
    // we need to set the width to 100% to fix the size calculation
    width: 100% !important;
    transform-origin: 0 center;
  }

  .v-progress-linear__determinate, .v-progress-linear__indeterminate {
    height: var(--deck-progress-height) !important
  }

  &.v-progress-linear--rounded {
    border-radius: var(--deck-progress-initial-border-radius);
  }
}

.deck-progress--absolute {
  position: absolute;
}

.deck-progress--fixed {
  position: fixed;
}

.deck-progress--top {
  top: 0;
  left: 0;

  .v-progress-linear__determinate {
    top: 0;
  }

  .v-progress-linear__indeterminate :is(.short, .long), .v-progress-linear__background {
    bottom: unset;
  }

  .deck-progress__bar {
    border-radius: var(--deck-progress-inner-radius) var(--deck-progress-inner-radius) 0 0;
  }
}

.deck-progress--bottom {
  bottom: 0;
  left: 0;

  .v-progress-linear__determinate {
    bottom: 0;
  }

  .v-progress-linear__indeterminate :is(.short, .long), .v-progress-linear__background {
    top: unset;
  }

  .deck-progress__bar {
    border-radius: 0 0 var(--deck-progress-inner-radius) var(--deck-progress-inner-radius);
  }
}
</style>
