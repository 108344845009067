import { generateUuid } from '~/assets/javascript/utils';

export const state = () => ({
  workflowExecutionsMap: {},
});

export const getters = {
  findWorkflowExecutionByRequestId: state => requestId => state.workflowExecutionsMap[requestId],
};

export const mutations = {
  add(state, item) {
    state.workflowExecutionsMap[item.workflowExecutionRequestId] = item;
  },
  remove(state, id) {
    delete state.workflowExecutionsMap[id];
  },
  updateWorkflowExecutionPath(state, { requestId, path }) {
    state.workflowExecutionsMap[requestId].path = path;
  },
};

export const actions = {
  async create({ commit }, { params, path }) {
    const workflowExecutionRequestId = generateUuid();

    commit('add', {
      ...params,
      workflowExecutionRequestId,
      path,
    });

    params.workflow_execution_request_id = workflowExecutionRequestId;

    try {
      await this.$apiClient.workflows.executions.create(params);
    } catch (error) {
      commit('remove', workflowExecutionRequestId);
      throw error;
    }
  },
};
