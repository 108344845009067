<template>
  <component
    :is="computedTag"
    ref="trimmer"
    class="deck-trimmer"
    :class="classes"
    :style="cssProps"
    @mouseover="onMouseOver"
  >
    <slot>
      {{ text }}
    </slot>

    <deck-tooltip
      v-if="shouldRenderTooltip"
      :text="text"
      :activator="trimmerRef"
      open-on-mount
    >
      <template v-if="$slots.default" #content>
        <slot />
      </template>
    </deck-tooltip>
  </component>
</template>

<script>
import { Fragment } from 'vue-fragment';

/**
 * A pattern component that truncates text to a certain number of lines and
 * shows a tooltip with the full text when hovered. You can define flexible
 * strategies through its props to handle truncation, tooltip display and even
 * container rendering.
 *
 * **Note:** It is not a silver bullet for all truncation needs in terms of set
 * and forget. It is better suited for wrapping around single text elements or
 * just sticking to the `text`prop. It may work when wrapping around multiple
 * elements or complex components, but there are no guarantees due to complex
 * inner layouts and styles, especially with some flex-grow / flex-shrink /
 * flex-basis combinations.
 * @see https://css-tricks.com/flexbox-truncated-text/
 * Also, might make the tooltip behave weirdly (Eg. when wrapping around
 * interactive components).
 *
 * **Tip:** If you are dealing with components slots that already employ a
 * hard-coded `deck-trimmer`, but it breaks because you need to explicility set
 * a new `deck-trimmer` in a specific single inner element, make sure you can
 * somehow disable/override the parent `deck-trimmer` first for better results.
 *
 * @component
 */
export default {
  name: 'DeckTrimmer',
  components: {
    Fragment,
    DeckTooltip: () => import('~/deck/tooltip'),
  },
  props: {
    /**
     * The HTML tag to use for the component. 'span' by default if `lineClamp` > 0 through computed property.
     * If `lineClamp` === 0, and there is no explicitly set `tag` prop, the component will not render any wrapper tag.
     * @type {string}
     * @default undefined
     */
    tag: {
      type: String,
      default: undefined,
    },

    /**
     * The text to display if wish to avoid using the default slot.
     * @type {string}
     * @default ''
     */
    text: {
      type: String,
      default: '',
    },

    /**
     * The number of lines to display before truncating the text. Use 0 to not truncate.
     * @type {number, string}
     * @default 1
     */
    lineClamp: {
      type: Number,
      default: 1,
    },

    /**
     * Whether to disable the tooltip that shows when hovering over a truncated text.
     * @type {boolean}
     * @default false
     */
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trimmerRef: null,
      shouldRenderTooltip: false,
    };
  },
  computed: {
    classes() {
      return {
        'deck-trimmer--clamped': this.lineClamp > 1,
        'deck-trimmer--truncated': this.lineClamp === 1,
      };
    },
    cssProps() {
      return {
        '--deck-trimmer-line-clamp': this.lineClamp,
      };
    },
    computedTag() {
      if (this.lineClamp === 0 && !this.tag) return 'Fragment';

      return this.tag || 'span';
    },
  },
  methods: {
    isTruncated(element) {
      if (this.lineClamp === 0) return false;

      if (this.lineClamp === 1) {
        return element.scrollWidth > element.clientWidth;
      }

      return element.scrollHeight > element.clientHeight;
    },
    onMouseOver() {
      if (this.disableTooltip) return;

      this.$nextTick(() => {
        this.shouldRenderTooltip = this.isTruncated(this.$refs.trimmer);

        if (this.shouldRenderTooltip) {
          this.trimmerRef = this.$refs.trimmer;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.deck-trimmer--clamped {
  max-width: stretch;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--deck-trimmer-line-clamp);
  overflow: hidden !important;
  text-wrap: wrap !important;
  word-break: break-word !important;
  hyphens: auto; // Using line-clamp won't add ellipsis in the middle of the last word. So this is the best way to somewhat break long single/last words.

  @supports (text-wrap: pretty) {
    text-wrap: pretty !important;
  }
}

.deck-trimmer--truncated {
  max-width: stretch;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}
</style>
