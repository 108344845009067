import { isEmpty } from 'lodash';
import { viewBelongsToScope, viewsInScope } from '~/assets/javascript/utils';

const LANGUAGE_LOCALE_MAP = {
  pt: 'pt-BR',
  en: 'en',
};

const getDefaultScope = () => ({
  key: 'everyone',
  title: globalThis.$nuxt?.$i18n?.t('workspace.scopes.everyone'),
  icon: 'fa-regular fa-user-group-simple',
});

const isIdPresentInResourceOrder = (resourceOrder, id) => {
  if (resourceOrder.items) {
    return resourceOrder.items.some(item => isIdPresentInResourceOrder(item, id));
  }

  return resourceOrder.id === id;
};

function handleWorkspaceLoadError(error) {
  if (error.response?.status === 404) {
    this.$notifier.showMessage({
      content: globalThis.$nuxt?.$i18n?.t('global.workspaceNotFound'),
      color: 'error',
    });

    this.$router.push(this.localePath({ name: 'login' }));
  }

  throw error;
}

export const state = () => ({
  workspace: {},
  currentCategoryName: null,
  newVersion: false,
  currentScope: null,
});

export const cacheableStateKeys = [
  'workspace',
  'currentCategoryName',
  'newVersion',
  'currentScope',
];

export const getters = {
  /* Workspace plan */
  isTrial: state => state.workspace.plan === 'trial',
  isChurned: state => state.workspace.plan === 'churned',
  /* Workspace alerts */
  alerts({ workspace }, _, { auth = {} }) {
    const alerts = [];

    if (auth.user?.role === 'admin') {
      if (workspace.has_dummy_data) {
        alerts.push('dummyData');
      }

      if (workspace.not_configured_integrations?.length > 0) {
        alerts.push('notConfiguredIntegrations');
      }
    }

    return alerts;
  },
  /* Category Hierarchy */
  categoryHierarchy: state => [state.workspace?.category_hierarchy || []].flat(),
  categoryHierarchyWithoutSelected(state, { categoryHierarchy }) {
    return categoryHierarchy.filter(({ category_name: name }) => name !== state.currentCategoryName);
  },
  firstCategoryWithView(_, { categoryHierarchy }) {
    return categoryHierarchy.find(({ views }) => views && views.length > 0);
  },
  viewsOrder(state, { categoryHierarchy }) {
    const category = categoryHierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);

    return category?.views || [];
  },
  /* Scope */
  defaultScope: () => getDefaultScope(),
  availableScopes: (_, { defaultScope }, { viewsMenu: { views }, auth }) => {
    if (!auth?.user) return [];

    const userGroupScopes = auth.user.user_groups
      .filter(group => group.use_as_navigation_scope && views.some(view => viewBelongsToScope(view, { key: group.id }, auth.user)))
      .map(group => ({
        key: group.id,
        title: group.name,
        icon: `fa-regular fa-${group.icon}`,
      }));

    return [defaultScope].concat(userGroupScopes);
  },
  currentScope: (state, { availableScopes, defaultScope }) => ((availableScopes || []).map(scope => scope.key).includes(state.currentScope?.key) ? state.currentScope : defaultScope),
  viewsInCurrentScope: (_, { currentScope }, { viewsMenu: { views }, auth }) => viewsInScope(views, currentScope, auth.user),
  categoryHierarchyScoped(_, { categoryHierarchy, viewsInCurrentScope }) {
    const categoriesThatHaveViewsInThatScope = new Set();

    viewsInCurrentScope.forEach((view) => {
      if (!view.category) return;
      categoriesThatHaveViewsInThatScope.add(view.category.id);
    });

    return categoryHierarchy.filter(({ id }) => categoriesThatHaveViewsInThatScope.has(id)); // shows only categories that have views in that scope
  },
  viewsOrderScoped(state, { categoryHierarchyScoped }) {
    const category = categoryHierarchyScoped.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    return category ? category.views : [];
  },
  firstScopedViewId(_, { viewsOrderScoped, viewsInCurrentScope }) {
    const viewIdsInScope = viewsInCurrentScope.map(v => v.id);
    let firstView;

    const findFirstView = (viewsOrderScoped) => {
      if (firstView) return;

      if (viewsOrderScoped.items && viewsOrderScoped.items.length > 0) {
        viewsOrderScoped.items.forEach(findFirstView);
      } else if (viewIdsInScope.includes(viewsOrderScoped.id)) {
        firstView = viewsOrderScoped;
      }
    };

    viewsOrderScoped.forEach(findFirstView);

    return firstView?.id;
  },
  /* Workspace Workflows */
  workflowsOrder(state) {
    if (!state.workspace.category_hierarchy) return [];
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    return category ? category.workflows : [];
  },
  firstWorkflowId(_, { workflowsOrder = [] }) {
    let firstWorkflow;

    const findFirstWorkflowsOrder = (workflowsOrder) => {
      if (firstWorkflow) return;

      if (workflowsOrder.items && workflowsOrder.items.length > 0) {
        workflowsOrder.items.forEach(findFirstWorkflowsOrder);
      } else {
        firstWorkflow = workflowsOrder;
      }
    };

    workflowsOrder.forEach(findFirstWorkflowsOrder);

    return firstWorkflow?.id;
  },
  firstViewId(_, { viewsOrder }) {
    let firstView;

    const findFirstView = (viewsOrder) => {
      if (firstView) return;

      if (viewsOrder.items && viewsOrder.items.length > 0) {
        viewsOrder.items.forEach(findFirstView);
      } else {
        firstView = viewsOrder;
      }
    };

    viewsOrder.forEach(findFirstView);

    return firstView?.id;
  },
  sheetsOrder: state => state.workspace.sheets_order || [],
  timeZone: state => state.workspace.config?.time_zone || 'America/Sao_Paulo',
  locale: state => LANGUAGE_LOCALE_MAP[state.workspace.config?.language] || 'pt-BR',
  primaryColor: state => state.workspace.config?.primary_color || '#3F51B5',
  logoUrl: state => state.workspace.logo_image?.download_url?.logo,
  liveApp: state => state.workspace.live_app,
  hasDummyData: state => state.workspace.has_dummy_data,
  workspaceName: state => state.workspace.name,
  signupEnabled: state => state.workspace.signup_enabled,
  tenantSlug: state => state.workspace.slug,
};

export const mutations = {
  setWorkspace(state, workspace) {
    state.workspace = workspace;

    if (!state.currentCategoryName) {
      state.currentCategoryName = workspace.category_hierarchy?.[0]?.category_name;
    }
  },
  updateViewsOrder(state, viewsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.views = viewsOrder;
  },
  updateWorkflowsOrder(state, workflowsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.workflows = workflowsOrder;
  },
  updateSheetsOrder(state, sheetsOrder) {
    state.workspace = {
      ...state.workspace,
      sheets_order: sheetsOrder,
    };
  },
  updateWorkspacePrimaryColor(state, primaryColor) {
    state.workspace.config = {
      ...state.workspace.config,
      primary_color: primaryColor,
    };
  },
  resetWorkspace(state) {
    state.workspace = {};
  },
  setCurrentCategoryName(state, categoryName) {
    state.currentCategoryName = categoryName;
  },
  setNewVersion(state, newVersion) {
    state.newVersion = newVersion;
  },
  setCurrentScope(state, scope) {
    state.currentScope = scope;
  },
  setDefaultScope(state) {
    state.currentScope = getDefaultScope();
  },
};

export const actions = {
  firstViewIdFromViews({ getters: { viewsOrder } }, views) {
    let firstView;

    const findFirstView = (viewsOrder) => {
      if (firstView) return;

      if (viewsOrder.items && viewsOrder.items.length > 0) {
        viewsOrder.items.forEach(findFirstView);
      } else if (views.find(view => view.id === viewsOrder.id && !view.archived_at)) {
        firstView = viewsOrder;
      }
    };

    viewsOrder.forEach(findFirstView);

    return firstView?.id;
  },
  async loadWorkspace({ commit, state, dispatch }, params = {}) {
    const { waitLoad } = params;
    delete params.waitLoad;

    const promise = params.isPublic ? this.$api.$get(`public/workspaces/${params.tenantSlug}`) : this.$api.$get('workspace', { params });

    // If workspace is already loaded, reload it without awaiting
    if (!isEmpty(state.workspace) && !waitLoad) {
      promise.then((data) => {
        commit('setWorkspace', data);

        if (params.builder) {
          dispatch('workflows/loadWorkflows', {}, { root: true });
          dispatch('viewOptions/fetchSheetsCache', {}, { root: true });
          dispatch('builderView/loadViews', {}, { root: true });
        }
      }).catch(handleWorkspaceLoadError.bind(this));

      return null;
    }

    try {
      const data = await promise;

      if (params.builder) {
        await Promise.all([ // TODO: refactor these actions to centralize resources indexes in workspace
          dispatch('workflows/loadWorkflows', {}, { root: true }),
          dispatch('viewOptions/fetchSheetsCache', {}, { root: true }),
          dispatch('builderView/loadViews', {}, { root: true }),
        ]).catch(handleWorkspaceLoadError.bind(this));
      }

      return commit('setWorkspace', data);
    } catch (error) {
      return handleWorkspaceLoadError.call(this, error);
    }
  },
  async updateWorkspace(_, payload) {
    await this.$api.patch('/workspace', payload);
  },
  async updateViewsOrder({ commit, dispatch, state }, viewsOrder) {
    commit('updateViewsOrder', viewsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, views_order: viewsOrder });
  },
  async updateWorkflowsOrder({ commit, dispatch, state }, workflowsOrder) {
    commit('updateWorkflowsOrder', workflowsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, workflows_order: workflowsOrder });
  },
  async updateSheetsOrder({ commit, dispatch }, sheetsOrder) {
    commit('updateSheetsOrder', sheetsOrder);
    await dispatch('updateWorkspace', { sheets_order: sheetsOrder });
  },
  async updateWorkspacePrimaryColor({ commit, dispatch, state }, primaryColor) {
    commit('updateWorkspacePrimaryColor', primaryColor);
    await dispatch('updateWorkspace', { config: { ...state.workspace.config, primary_color: primaryColor } });
  },
  async signUp({ commit }, { username, password, name, recaptchaToken, marketingEmailApproval, termsApproval, appsToInstall = [] }) {
    const workspace = await this.$api.$post(
      '/workspace',
      {
        username,
        password,
        name,
        recaptcha_token: recaptchaToken,
        marketing_email_approval: marketingEmailApproval,
        terms_approval: termsApproval,
        apps_to_install: appsToInstall,
      },
      { timeout: 30000 },
    );
    commit('setWorkspace', workspace);
    window.analytics.track('TenantCreated', { username, currentPath: this.$router.currentRoute.path });
    return workspace;
  },
  setCategoryByWorkflowId({ commit, getters }, workflowId) {
    const categoryName = getters
      .categoryHierarchy
      .find(({ workflows }) => workflows?.some(workflowOrder => isIdPresentInResourceOrder(workflowOrder, workflowId)))?.category_name;

    if (categoryName) {
      commit('setCurrentCategoryName', categoryName);
    }
  },
  setCategoryByViewId({ commit, getters }, viewId) {
    const categoryName = getters
      .categoryHierarchy
      .find(({ views }) => views.some(viewsOrder => isIdPresentInResourceOrder(viewsOrder, viewId)))?.category_name;

    if (categoryName) {
      commit('setCurrentCategoryName', categoryName);
    }
  },
  updateCurrentScope({ commit, getters }, { scope }) {
    const scopeToSet = getters.availableScopes.find(s => s.key === scope.key) || getters.defaultScope;

    commit('setCurrentScope', scopeToSet);
  },
  async importWorkspace({ dispatch }, ymlContent) {
    await this.$api.$post('/workspace/import', { yml_content: ymlContent });
    return dispatch('loadWorkspace');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
