<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height class="justify-center">
        <nuxt />
      </v-container>
    </v-main>
    <Snackbar />
    <TheDialog />
  </v-app>
</template>
<script>

import { workspace } from '~/mixins/workspace';
import Snackbar from '~/components/Snackbar';
import TheDialog from '~/components/dialogs/the-dialog';
import { mapMutations } from 'vuex';

export default {
  name: 'Default',
  components: { TheDialog, Snackbar },
  mixins: [workspace()],
  head() {
    return this.$buildHead();
  },
  mounted() {
    const utmsObject = this._.pickBy(this.$route.query, (_value, key) => this._.startsWith(key, 'utm_'));

    if (!this._.isEmpty(utmsObject)) {
      window.analytics.track('referredFrom', utmsObject);
    }
  },
  destroyed() {
    this.resetWorkspace();
  },
  methods: {
    ...mapMutations('workspace', ['resetWorkspace']),
  },
};
</script>
