import { ENVIRONMENT_DEFINITIONS } from '~/store/environment';
import BaseAction from '../base-action';

export default class RedirectToView extends BaseAction {
  stateRouteMap = {
    index: 'routeWithId',
    single_item: 'recordRoute',
    new: 'newRecordRoute',
  };

  constructor(context) {
    super(context);
    this.$notifier = context.$notifier;
    this.$emit = context.$emit.bind(context);
  }

  // eslint-disable-next-line class-methods-use-this
  get name() {
    return 'RedirectToView';
  }

  buildRoute = ({ viewId, sourceRecordId, area = 'member', state = 'index', addSourceRecordReference = false }) => {
    const params = {};
    const query = {};
    let computedState = state;

    if (
      (state === 'single_item' && !sourceRecordId) // shouldn't try to go to single_item if no sourceRecordId is provided
      || area === 'studio' // studio only have 'routeWithId' route, which is the equivalent of a 'index' state
      || (area === 'public' && state === 'new') // public environment currently dont support record creation
    ) computedState = 'index';

    if (computedState === 'index') query.back = true;

    const includeRecordIdOnRouteParam = computedState === 'single_item';
    const includeRecordIdOnQueryParam = addSourceRecordReference && Boolean(sourceRecordId && ['member', 'public'].includes(area));

    if (includeRecordIdOnRouteParam) params.recordId = sourceRecordId;
    if (includeRecordIdOnQueryParam) query.sri = sourceRecordId;

    if (ENVIRONMENT_DEFINITIONS[area].mandatoryTenantSlug) params.tenantSlug = this.$route.params.tenantSlug;
    if (ENVIRONMENT_DEFINITIONS[area].routeIdParam) params[ENVIRONMENT_DEFINITIONS[area].routeIdParam] = viewId;

    const routeName = ENVIRONMENT_DEFINITIONS[area][this.stateRouteMap[computedState] || 'standardRoute'];

    return this.localePath({
      name: routeName,
      params,
      query,
    });
  };

  execute = async ({ viewId: viewIdParam, recordId, area = 'member', redirect_message: redirectMessage, state = 'index', add_source_record_reference: addSourceRecordReference, onBeforeRouteChange = (_path) => {} }) => {
    const viewId = viewIdParam || this.$route.params.viewId;

    if (redirectMessage) this.$notifier.showMessage({ content: redirectMessage, color: 'success' });
    const route = this.buildRoute({ viewId, sourceRecordId: recordId, area, state, addSourceRecordReference });

    onBeforeRouteChange(route);

    if (this.$router.currentRoute.fullPath === route) {
      await this.$emit('refetch');
    } else {
      await this.$router.push(route);
    }
  };
}
