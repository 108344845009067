import { sample } from 'lodash';
import { COLORS, PRIMARY_COLORS, COLOR_TONES } from '~/assets/javascript/constants';

export const colorSample = () => COLORS[sample(PRIMARY_COLORS)][sample(COLOR_TONES)];

const hex = num => (Number.isNaN(parseInt(num, 10)) ? '00' : num.toString(16));

/* Convert an RGB triplet to a hex string */
const convertToHex = rgb => `#${hex(rgb[0])}${hex(rgb[1])}${hex(rgb[2])}`;

/* Remove '#' in color hex string */
const trim = s => s.replace(/^#([A-Za-z0-9]{6})$/, '$1');

/* Convert a hex string to an RGB triplet */
const getChunk = (hex, index) => trim(hex).substring(index, index + 2);

const convertToRGB = hex => ([parseInt(getChunk(hex, 0), 16), parseInt(getChunk(hex, 2), 16), parseInt(getChunk(hex, 4), 16)]);

export const getHexPalette = (hexStart, hexEnd, steps = 10) => {
  const start = convertToRGB(hexStart);
  const end = convertToRGB(hexEnd);

  // Alpha blending amount
  let alpha = 0.0;

  const palette = [];

  for (let i = 0; i < steps; i++) {
    const c = [];
    alpha += (1.0 / steps);

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    palette.push(convertToHex(c));
  }

  return palette;
};

