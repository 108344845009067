export const ALLOWED_WORKFLOW_SCHEMA_PARAMETERS = [
  'boolean',
  'checklist',
  'cover_image',
  'date',
  'date_time',
  'email',
  'field_reference',
  'file',
  'markdown',
  'multiple_select',
  'number',
  'provider_integration',
  'record_reference',
  'select',
  'sheet_reference',
  'static_select',
  'text',
  'url',
  'user_reference',
  'view_reference',
];

// display_name is a given chainable for all schema parameters. It is treated as a special case in the UI
// so it's not here in the chainables list.
export const SCHEMA_PARAMETER_CHAINABLES = {
  required: {
    uiElement: 'control',
    icon: {
      name: 'asterisk',
      kind: 'regular',
    },
    locales: {
      pt: args => `Tornar parâmetro ${args?.[0] ? 'opcional' : 'obrigatório'}`,
      en: args => `Make parameter ${args?.[0] ? 'optional' : 'required'}`,
    },
    event: {
      name: 'chainable:required',
      handler: 'switch',
    },
  },
  allow_override: {
    uiElement: 'control',
    icon: {
      name: 'sync',
      kind: 'regular',
    },
    locales: {
      pt: args => `${args?.[0] ? 'Desabilitar' : 'Habilitar'} alterar parâmetro em tempo de execução`,
      en: args => `${args?.[0] ? 'Disable' : 'Enable'} parameter runtime override`,
    },
    event: {
      name: 'chainable:allow_override',
      handler: 'switch',
    },
  },
  set_hint: {
    uiElement: 'control',
    icon: {
      name: 'info-circle',
      kind: 'regular',
    },
    locales: {
      pt: _args => 'Configurar texto explicativo para o parâmetro',
      en: _args => 'Set hint text for the parameter',
    },
    event: {
      name: 'chainable:set_hint',
      handler: 'text-input',
    },
  },
  multiple: {
    uiElement: 'control',
    icon: {
      name: 'list',
      kind: 'regular',
    },
    locales: {
      pt: args => `${args?.[0] ? 'Desabilitar' : 'Habilitar'} múltiplos valores`,
      en: args => `${args?.[0] ? 'Disable' : 'Enable'} multiple values`,
    },
    event: {
      name: 'chainable:multiple',
      handler: 'switch',
    },
  },
  with_update_permission: {
    uiElement: 'control',
    icon: {
      name: 'filter',
      kind: 'regular',
    },
    locales: {
      pt: args => `Permitir ${args?.[0] ? 'todos os tipos de página' : 'apenas páginas com permissão de atualização'}`,
      en: args => `Allow ${args?.[0] ? 'all page types' : 'only pages with update permission'}`,
    },
    event: {
      name: 'chainable:with_update_permission',
      handler: 'switch',
    },
  },
  default: {
    uiElement: 'control',
    icon: {
      name: 'check',
      kind: 'regular',
    },
    locales: {
      pt: args => `Definir o valor inicial do parâmetro como ${args?.[0] ? 'FALSO' : 'VERDADEIRO'}`,
      en: args => `Set parameter default value to ${args?.[0] ? 'FALSE' : 'TRUE'}`,
    },
    event: {
      name: 'chainable:default',
      handler: 'switch',
    },
  },
  set_sheet: {
    uiElement: 'select',
    resource: 'sheet',
    icon: {
      name: 'table',
      kind: 'regular',
    },
    locales: {
      pt: _args => 'Selecionar a base de dados',
      en: _args => 'Select the sheet',
    },
    event: {
      name: 'chainable:set_sheet',
      handler: 'set-value',
    },
  },
  set_options: {
    uiElement: 'select-options-builder',
    icon: {
      name: 'diagram-cells',
      kind: 'regular',
    },
    locales: {
      pt: _args => 'Configurar opções do seletor',
      en: _args => 'Set select options',
    },
    event: {
      name: 'chainable:set_options',
      handler: 'set-value',
    },
  },
  set_subtypes: {
    uiElement: 'multi-select',
    resource: 'type',
    icon: {
      name: 'diagram-cells',
      kind: 'regular',
    },
    locales: {
      pt: _args => 'Selecionar os tipos permitidos',
      en: _args => 'Select allowed types',
    },
    event: {
      name: 'chainable:types',
      handler: 'set-value',
    },
  },
  types: {
    uiElement: 'multi-select',
    resource: 'type',
    icon: {
      name: 'diagram-cells',
      kind: 'regular',
    },
    locales: {
      pt: _args => 'Selecionar os tipos permitidos',
      en: _args => 'Select allowed types',
    },
    event: {
      name: 'chainable:types',
      handler: 'set-value',
    },
  },
};
