const PATHS = {
  uploadImage: '/builder/content_images/',
};

let api;
let externalApi;

const setAxios = ($api, $externalApi) => {
  if (!api) api = $api;
  if (!externalApi) externalApi = $externalApi;
};

const createImageFile = async (file) => {
  const params = {
    filename: file.name,
    mime_type: file.type,
  };

  const data = await api.$post(PATHS.uploadImage, params);

  return data;
};

const uploadFileToS3 = async (uploadUrl, file) => {
  const headers = { 'Content-Type': 'application/octet-stream' };
  await externalApi.$put(uploadUrl, file, { headers });
};

const makeImageAvailable = async (id) => {
  const data = await api.$patch(`${PATHS.uploadImage}${id}`, { available: true });

  return data;
};

export const uploadImageFile = async (file, { $externalApi, $api }) => {
  setAxios($api, $externalApi);

  const { id, upload_url: uploadUrl } = await createImageFile(file);
  await uploadFileToS3(uploadUrl, file);
  const fileData = makeImageAvailable(id);

  return fileData;
};
