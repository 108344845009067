import { generateUuid } from '~/assets/javascript/utils';

export default class ChartInfoComponentOptionBuilder {
  static build(infoComponent) {
    return new ChartInfoComponentOptionBuilder(infoComponent).build();
  }

  constructor(infoComponent) {
    this.chartInfoComponent = infoComponent;
  }

  build() {
    const infoComponentId = this.chartInfoComponent.id || generateUuid();

    const chartInfoComponentOption = {
      ...this.chartInfoComponent,
      id: infoComponentId,
      title: this.chartInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: 'fa-solid fa-chart-mixed',
    };

    return chartInfoComponentOption;
  }
}
