export * from './image-resize';
export * from './mention';
export * from './video';

const textToHtml = (text) => {
  const wrapper = document.createElement('div');
  wrapper.classList.add('temp-wrapper');
  wrapper.innerHTML = text;
  return wrapper;
};

const htmlToText = html => html.innerHTML;

const countParentsWithTag = (element, tagName) => {
  let count = 0;
  let currentElement = element.parentNode;

  while (currentElement !== null) {
    if (currentElement.tagName.toUpperCase() === tagName.toUpperCase()) {
      count++;
    }

    currentElement = currentElement.parentNode;
  }

  return count;
};

const unwrapNestedLists = (rootUl) => {
  const rootListItems = [...rootUl.children].filter(child => child.tagName === 'LI');

  rootListItems.forEach((li) => {
    const nestedListItems = [...li.querySelectorAll('li')].reverse();

    nestedListItems.forEach((nestedLi) => {
      rootUl.insertBefore(nestedLi, li.nextSibling);
    });
  });

  rootUl.querySelectorAll('ul, ol').forEach((ul) => {
    if (ul.children.length === 0) ul.remove();
  });
};

const addQuillIndentClasses = (element) => {
  const listItems = element.querySelectorAll('li');

  Array.from(listItems).forEach((listItem) => {
    const ulCount = countParentsWithTag(listItem, 'ul');
    const olCount = countParentsWithTag(listItem, 'ol');
    const depth = ulCount + olCount - 1;

    if (depth > 0) {
      listItem.classList.add(`ql-indent-${depth}`);
    }
  });
};

const parseBulletPoints = (text) => {
  // quill cannot render nested bullet points -> https://github.com/quilljs/quill/issues/979
  // so we need to manually parse the correct html from marked to something quill can understand as nested bullet points
  if (!window) return text;
  const element = textToHtml(text);
  const topLevelLists = element.querySelectorAll('.temp-wrapper > ul, .temp-wrapper > ol');

  Array.from(topLevelLists).forEach((list) => {
    addQuillIndentClasses(list);
    unwrapNestedLists(list);
  });

  return htmlToText(element);
};

export const parseHtmlToQuill = (html) => {
  let parsed = html
    // quill uses <s> instead of <del> for strikethrough
    .replaceAll('<del>', '<s>')
    .replaceAll('</del>', '</s>')
    // don't know why, but if there are line breaks in the middle of a list, quill will not render it. Also <p> tags inside of it.
    .replaceAll(/^<li><p>(.*)<\/p>$/gm, '<li>$1')
    .replaceAll('\n<li>', '<li>')
    .replaceAll('</li>\n', '</li>')
    .replaceAll('\n<li>', '<li>')
    .replaceAll('&quot;', '"')
    // quill uses <blockquote> instead of <blockquote><p> for blockquotes
    .replaceAll(/<blockquote>\s*?<p>/g, '<blockquote>')
    .replaceAll(/<\/p>\s*?<\/blockquote>/g, '</blockquote>')
    .replaceAll('&amp;lt;', '&lt;')
    .replaceAll('&amp;gt;', '&gt;')
    .replaceAll('<pre><code>', '<pre>')
    .replaceAll('</code></pre>', '</pre>')
    .replaceAll('<pre>', '<pre class="ql-syntax">')
    .replaceAll(/>\n+/g, '>')
    .replaceAll(/\n+</g, '<')
    .replaceAll(/>\s+</g, '><')
    .replaceAll('<li></li>', '<li><br></li>')
    .replaceAll(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;'); // quill is not able to render tabs, so we replace them with 4 spaces

  parsed = parseBulletPoints(parsed);

  return parsed;
};
