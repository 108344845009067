import { version as uuidVersion, validate as uuidValidate } from 'uuid';

export const generateUuid = () => {
  try {
    try {
      // get crypto from DOM
      return crypto.randomUUID();
    } catch {
      // get crypto from nodejs
      return require('crypto').randomUUID();
    }
  } catch {
    // fallback to Math.random
    const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }
};

export const isUuidV4 = uuid => uuidValidate(uuid) && uuidVersion(uuid) === 4;

export const uuidRegex = /([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/g;
