<template>
  <div>
    <v-app-bar
      :elevation="outlined ? 0 : elevation"
      :height="isPublicEnvironment ? '64px' : '56px'"
      short
      app
      flat
      fixed
      color="#FFFFFF"
      class="the-topbar"
      :class="{ 'the-topbar--outlined': outlined && !transparent, 'the-topbar--transparent': transparent }"
    >
      <div class="the-topbar__start">
        <TheTopbarLogo v-if="withLogo" :to="homeRoute" />
        <TheTopbarContextSwitch v-if="showContextSwitch" />
        <slot
          v-if="shouldShowContent"
          name="start-content"
        />
      </div>

      <div
        v-if="shouldShowContent && $scopedSlots['center-content']"
        class="the-topbar__center"
      >
        <slot name="center-content" />
      </div>

      <div class="the-topbar__end">
        <TheTopBarAlertCenter v-if="alerts.length > 0" />

        <slot
          v-if="shouldShowContent"
          name="end-content"
        />
        <TheTopbarProfileMenu v-if="showProfileMenu" />
        <deck-button
          v-if="shouldShowOnMobile"
          is-ready
          kind="ghost"
          color="controls"
          icon="bars"
          text="menu"
          @click="showMenu = true"
        />
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isPublicEnvironment && $vuetify.breakpoint.mdAndDown"
      v-model="showMenu"
      fixed
      right
      class="the-topbar__mobile-menu"
    >
      <slot name="menu-content" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheTopbar',
  components: {
    TheTopbarProfileMenu: () => import('./_profile-menu'),
    TheTopbarLogo: () => import('./_logo'),
    TheTopBarAlertCenter: () => import('./_alert-center'),
    TheTopbarContextSwitch: () => import('./_context-switch'),
    DeckButton: () => import('~/deck/button'),
  },
  props: {
    elevation: { type: Number, default: 0 },
    homeRoute: { type: Object, default: () => ({}) },
    outlined: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    withLogo: { type: Boolean, default: true },
  },
  data() {
    return {
      showMenu: false,
    };
  },

  computed: {
    ...mapGetters('environment', ['isPublicEnvironment']),
    ...mapGetters('workspace', ['alerts']),
    isAdmin() {
      return this.$auth?.user?.role === 'admin';
    },
    showContextSwitch() {
      return this.$auth.loggedIn && (this.isAdmin || !this.isPublicEnvironment);
    },
    showProfileMenu() {
      return !this.isPublicEnvironment && this.$auth.loggedIn;
    },
    shouldShowOnMobile() {
      return this.isPublicEnvironment && this.$vuetify.breakpoint.smAndDown;
    },

    shouldShowContent() {
      return !this.isPublicEnvironment || (this.isPublicEnvironment && this.$vuetify.breakpoint.mdAndUp);
    },
  },
};
</script>

<style lang="scss">
.the-topbar {
  z-index: 200 !important; // v-app-bar override
  left: 0 !important; // v-app-bar override

  .v-toolbar__content {
    display: grid;
    gap: 24px;
    padding-block: 0; // v-app-bar override
    padding-inline: 12px; // v-app-bar override
    // Center column at the "true center" of the topbar, independent of the content of the other columns
    // |start   |center|   end|
    grid-template-areas: "start center end";
    grid-template-columns: minmax(max-content, 1fr)  minmax(0, auto)  minmax(max-content, 1fr);
  }
}

.the-topbar--outlined.v-toolbar.v-toolbar {
  box-shadow: 0 1px 0 0 #F2F2F2 !important;
}

.the-topbar--transparent {
  background-color: transparent !important;
}

.the-topbar__start,
.the-topbar__center,
.the-topbar__end {
  display: flex;
  padding-block: 8px;
  height: inherit;
  align-items: center;
  gap: 12px;
}

.the-topbar__start {
  grid-area: start;
}

.the-topbar__center {
  grid-area: center;
}

.the-topbar__end {
  justify-self: flex-end;
  grid-area: end;
}

.the-topbar__general-actions {
  display: flex;
  gap: 4px;
}

.the-topbar__mobile-menu {
  max-width: calc(100% - 36px) !important; // Keep part of the overlay visible to allow for closing the menu // v-navigation-drawer override
  padding: 16px;
  z-index: 201 !important; // v-navigation-drawer override

  .v-navigation-drawer__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
