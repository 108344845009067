export default ({ app, $rollbar }) => {
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    try {
      window.analytics.track('switchLocale', {
        oldLocale,
        newLocale,
      });
    } catch (error) {
      $rollbar.error('Analytics', error);
    }
  };
};
