const middleware = {}

middleware['automations/middleware'] = require('../middleware/automations/middleware.js')
middleware['automations/middleware'] = middleware['automations/middleware'].default || middleware['automations/middleware']

middleware['isadmin'] = require('../middleware/isadmin.js')
middleware['isadmin'] = middleware['isadmin'].default || middleware['isadmin']

middleware['product-fruits'] = require('../middleware/product-fruits.js')
middleware['product-fruits'] = middleware['product-fruits'].default || middleware['product-fruits']

middleware['refresh-user'] = require('../middleware/refresh-user.js')
middleware['refresh-user'] = middleware['refresh-user'].default || middleware['refresh-user']

middleware['save-recent-navigation'] = require('../middleware/save-recent-navigation.js')
middleware['save-recent-navigation'] = middleware['save-recent-navigation'].default || middleware['save-recent-navigation']

middleware['slug-redirect'] = require('../middleware/slug-redirect.js')
middleware['slug-redirect'] = middleware['slug-redirect'].default || middleware['slug-redirect']

export default middleware
