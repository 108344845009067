import { filters } from './filters';

export const views = $axios => ({
  get: (viewId, params = {}) => $axios.$get(`/builder/views/${viewId}`, { params }),
  create: (data = {}) => $axios.$post('/builder/views', data),
  update: (viewId, data = {}) => $axios.$patch(`/builder/views/${viewId}`, data),
  publish: viewId => $axios.$post(`/builder/views/${viewId}/publish`),
  archive: viewId => $axios.$post(`/builder/views/${viewId}/archive`),
  unarchive: viewId => $axios.$delete(`/builder/views/${viewId}/archive`),
  filters: filters($axios),
});
