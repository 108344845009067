import { buildBaseInfoComponent } from './_base';

export const buildMarkdownInfoComponent = ({
  columns = 1,
  content,
  id,
  label,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    content,
    label,
    type: 'markdown',
  };

  if (id) component.id = id;

  return component;
};
