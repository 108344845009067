import { executions } from './executions';

export const workflows = $axios => ({
  create: (data = {}) => $axios.$post('/workflows', data),
  get: (workflowId, params = {}) => $axios.$get(`/workflows/${workflowId}`, { params }),
  list: (params = {}) => $axios.$get('/workflows', { params }),
  update: (workflowId, params) => $axios.$patch(`/workflows/${workflowId}`, params),
  archive: workflowId => $axios.$post(`/workflows/${workflowId}/archive`),
  unarchive: workflowId => $axios.$delete(`/workflows/${workflowId}/archive`),

  // nested resources
  executions: executions($axios),
});
