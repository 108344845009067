/* eslint-disable import/no-cycle */
import { generateUuid } from '~/assets/javascript/utils';
import InfoComponentOptionBuilder from '.';

export default class GroupInfoComponentOptionBuilder {
  static build(infoComponent, mainContent) {
    return new GroupInfoComponentOptionBuilder(infoComponent, mainContent).build();
  }

  constructor(infoComponent, mainContent) {
    this.groupInfoComponent = infoComponent;
    this.mainContent = mainContent;
  }

  build() {
    const groupComponentId = this.groupInfoComponent.id || generateUuid();

    const items = Array.isArray(this.groupInfoComponent.items)
      ? this.groupInfoComponent.items.map(item => InfoComponentOptionBuilder.build(item, this.mainContent))
      : null;

    const groupInfoComponentOption = {
      ...this.groupInfoComponent,
      id: groupComponentId,
      title: this.groupInfoComponent.label,
      items,
      icon: 'fa-regular fa-square-dashed',
    };

    return groupInfoComponentOption;
  }
}
/* eslint-enable import/no-cycle */
