import { buildBaseInfoComponent } from './_base';

export const buildGroupInfoComponent = ({
  columns = 1,
  id,
  items,
  label,
  labelStyle = 'small',
  helperText,
  showHelperTextTooltip = false,
}) => {
  const component = {
    ...buildBaseInfoComponent(),
    columns,
    items,
    label,
    label_style: labelStyle,
    helper_text: helperText,
    show_helper_text_tooltip: showHelperTextTooltip,
    type: 'group',
  };

  if (id) component.id = id;

  return component;
};
