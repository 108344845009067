
/**
 * Translates the keys of an object based on a key map.
 *
 * For example, if you have an object like this:
 * { view_id: "123" }
 *
 * And a key map like this:
 * { view_id: "viewId" }
 *
 * The result will be:
 * { viewId: "123" }
 *
 * It is useful for translating keys from backend stored objects to frontend used objects.
 *
 * @param {Object} obj - The object whose keys will be translated.
 * @param {Object} keyMap - An object with keys that are the old keys and values that are the new keys.
 * @returns {Object} - A new object with the translated keys.
 */
export const translateObjectKeys = (obj, keyMap) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    const newKey = keyMap[key] || key;
    result[newKey] = obj[key];
  });

  return result;
};

/**
 * Translates the values of an object based on a specific search map.
 * If a value is not found in the value map, it is left as is.
 *
 * For example, if you have an object like this:
 * { view_id: "123" }
 *
 * And a value map like this:
 * {
 *   view_id: {
 *     list: [{ id: "123", page_name: 'My view' }],
 *     searchParam: "id",
 *     newKey: "view"
 *   }
 * }
 *
 * The result will be:
 * { view: { id: "123", page_name: 'My view' } }
 *
 * It is useful for translating values from single id values stored in the backend to full objects.
 *
 * @param {Object} obj - The object whose values will be translated.
 * @param {Object} keySearchMap - An object with keys that are the old values and values that are objects with a list, a searchParam and a newKey value.
 * @returns {Object} - A new object with the translated values.
 */

export const translateObjectValues = (obj, keySearchMap) => {
  const result = {};

  Object.entries(obj).forEach(([key, value]) => {
    const newValue = keySearchMap[key]?.list?.find(item => item[keySearchMap[key]?.searchParam] === value) || value;
    const newKey = keySearchMap[key]?.newKey || key;
    result[newKey] = newValue;
  });

  return result;
};
