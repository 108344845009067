import LoginCallback from '../callback';

export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.router = nuxt.app.router;
    this.callbackHandler = new LoginCallback(nuxt);
  }

  get route() {
    return this.router.currentRoute;
  }

  get tenantSlug() {
    return this.route.params.tenantSlug;
  }

  async login({ username, password, redirectTo, tenantSlug = this.tenantSlug }) {
    return this.$recaptcha.fetchToken(async (token) => {
      await this.$auth.loginWith('local', {
        data: {
          tenant_slug: tenantSlug,
          username,
          password,
          recaptcha_token: token,
        },
      });

      return this.callbackHandler.handle({ redirectTo });
    });
  }

  async register({ username, password, loginAfter = false }) {
    const response = await this.$recaptcha.fetchToken(async token => this
      .$api.post(`/workspace/${this.tenantSlug}/users`, {
        username,
        password,
        recaptcha_token: token,
      }));

    if (loginAfter) {
      return this.login({ username, password });
    }

    return response;
  }
}

export const SetupCredentials = (nuxt) => {
  nuxt.$auth.credentialsModule = new Module(nuxt);
};
