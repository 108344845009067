/* eslint-disable class-methods-use-this */
import VueI18n from 'vue-i18n';

export default class AbstractBuilder {
  constructor({ localeRoute, $route, currentEnvironment, $i18n, i18nMessages, type }) {
    this.localeRoute = localeRoute;
    this.$route = $route;
    this.currentEnvironment = currentEnvironment;

    const i18n = new VueI18n({
      locale: $i18n.locale,
      messages: i18nMessages,
    });

    this.$t = i18n.t.bind(i18n);
    this.type = type;
  }

  buildItem = (item) => {
    // we dont want things throwing error in the topbar search, so if something is wrong, it just returns null
    if (!item || item.archived_at) return null;

    return {
      id: item.id,
      title: this.itemTitle(item),
      subtitle: this.itemSubtitle(item),
      label: this.$t('label', { name: this.itemTitle(item) }),
      to: this.localeRoute(this.itemRoute(item)),
      icon: this.itemIcon(item),
      type: this.type,
    };
  };

  // item builders

  itemTitle = item => item.name;

  itemSubtitle = item => (item.category ? [item.category?.category_name, ...item.groupsPath].join(' / ') : null);

  itemIcon = item => item.category?.category_icon;

  itemRoute = (_item) => { throw new Error('itemRoute must be implemented by subclasses'); };
}
