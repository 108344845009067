import { mapActions, mapGetters } from 'vuex';
import setupAmpSRSegmentWrapper from '@amplitude/segment-session-replay-wrapper';

export const workspace = (params = {}) => ({
  created() {
    this.workspaceMixin__loadWorkspaceData();
    this.workspaceMixin__identifyUser();
    this.workspaceMixin__setupAmplitudeSessionReplay();
  },
  computed: {
    ...mapGetters('workspace', ['primaryColor']),
  },
  watch: {
    primaryColor() {
      this.workspaceMixin__updatePrimaryColor();
    },
  },
  async mounted() {
    await this.$nextTick();
    this.workspaceMixin__updatePrimaryColor();

    this.fetchFeatures();

    Object.keys(this.$route.query).filter(key => key.startsWith('feature_')).forEach((key) => {
      const flag = key.replace('feature_', '');
      const value = this.$route.query[key] === 'true';
      this.toggleFeature({ flag, value });
    });
  },
  methods: {
    ...mapActions('workspace', ['loadWorkspace']),
    ...mapActions('features', ['fetchFeatures', 'toggleFeature']),
    workspaceMixin__updatePrimaryColor() {
      this.$vuetify.theme.themes.light.primary = this.primaryColor;
    },
    workspaceMixin__setupAmplitudeSessionReplay() {
      if (!this.$auth.user) return;
      if (this.$auth.user.username?.endsWith('@zazos.com')) return;
      if (this.$env.deployEnv === 'staging') return;

      setupAmpSRSegmentWrapper({
        segmentInstance: window.analytics,
        amplitudeApiKey: this.$env.amplitudeApiKey,
        sessionReplayOptions: {
          sampleRate: 1,
        },
      });
    },
    async workspaceMixin__identifyUser() {
      if (!this.$auth.user) return;

      this.$segmentIdentifyUser();
    },
    async workspaceMixin__loadWorkspaceData() {
      if (!this.$auth.user && !params.isPublic) return;

      if (params.isPublic) {
        params.tenantSlug = this.$route.params?.tenantSlug;
      }

      try {
        await this.loadWorkspace(params);
      } catch (error) {
        this.$rollbar.error('Workspace load', error);
      }
    },
  },
});
