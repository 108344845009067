import Vue from 'vue';

function testClass(el, { value }) {
  el.classList.add(`test-class-${value}`);
}

function testId(el, { value }) {
  el.dataset.testid = `${value}`;
}

Vue.directive('test-class', process.env.NODE_ENV === 'test' ? testClass : {});

Vue.directive('test-id', process.env.NODE_ENV === 'test' ? testId : {});
